import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-mdashboard',
  templateUrl: './mdashboard.component.html',
  styleUrls: ['./mdashboard.component.scss']
})

export class MdashboardComponent implements OnInit {
  username ='';
  member_name = '';
  member_email = '';
  member_image = '';
  server_url = '';
  member_records = '';
  table = '';
  query = '';
  s3url = '';
  
  constructor(private router: Router,public httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService) { }

  ngOnInit() { 	
    this.s3url = this.tablenames.s3url;
    //console.log(this.userService.isLoggedInM());
    this.server_url = this.tablenames.server_url;
    let member_id = (localStorage.getItem('member_id'));

    //console.log(JSON.parse(localStorage.getItem('member_records')));
    
    this.httpService.checkUserExists(member_id).subscribe((resp: any) => {
      //console.log('CHECK EXISTSSSSSSSSSSSS');
      if(resp.result!=''  && typeof resp.result[0]['member_id']!='undefined'){
        //console.log('INSIDE');
        //console.log(resp);
      }else{
        //console.log('LOGOUT PLZ');
        this.httpService.logoutUser();
        location.reload();
      }
      
      //alert(333);  
    }, (errorResponse: HttpErrorResponse) => {
          
          let a = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(a) ;
    });

    this.table = this.tablenames.member;

    this.query = 'SELECT * FROM '+this.table+' WHERE 1=1 AND member_id = '+member_id; 
    this.httpService.commonFetch(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.member_records = resp.result[0];
      this.username = resp.result[0].member_first_name+' '+resp.result[0].member_last_name;

      this.member_name = this.username;

      this.member_image = resp.result[0].member_image
      this.member_email = resp.result[0].member_email;
      
    }, (errorResponse: HttpErrorResponse) => {
          
          let a = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(a) ;
    });
  	
    
  }

}
