import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";



//S3 upload
import { S3UploaderService } from 'ngx-s3-uploader';

import * as AWS from 'aws-sdk';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  
	contact = {
	    contact_first_name:'',
	    contact_last_name:'',
	    contact_company_name:'',
	    contact_phone:'',
	    contact_postal:'',
	    contact_country:'',
	    contact_support:'',
	    contact_message:'',
	    contact_email:''
	};
	country_records ='';
	query ='';
  constructor(private route: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService,private ngxService: NgxUiLoaderService,private fb: FormBuilder,private s3UploaderService: S3UploaderService) { }

  	isEmail(email) {
    	var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    	return regex.test(email);
  	}

  	contactSubmit(){

  		var first_name = (this.contact.contact_first_name).trim();
  		var last_name = (this.contact.contact_last_name).trim();
  		var contact_email = (this.contact.contact_email).trim();
  		var contact_phone = (this.contact.contact_phone).trim();
  		var postal = (this.contact.contact_postal).trim();
  		var support = (this.contact.contact_support).trim();
  		var message = (this.contact.contact_message).trim();


  		var fn = (first_name).length;
  		var ln = (last_name).length;
  		var email = (contact_email).length;
  		var phone = (contact_phone).length;
  		var contact_postal = (postal).length;
  		var contact_support = (support).length;
  		var contact_message = (message).length;



  		if(first_name=='')
	  		this.userService.showError('Enter first name');
	  	else if(fn<3)
	  		this.userService.showError('First name allowed minimum 3 to maximum 25 character');
	  	else if(fn>25)
	  		this.userService.showError('First name allowed minimum 3 to maximum 25 character');
	  	else if(this.contact.contact_last_name=='')
	  		this.userService.showError('Enter last name');
	  	else if(ln<3)
	  		this.userService.showError('Last name allowed minimum 3 to maximum 25 character');
	  	else if(ln>25)
	  		this.userService.showError('Last name allowed minimum 3 to maximum 25 character');
	  	else if(contact_email=='')
	  		this.userService.showError('Enter email id');
	  	else if(email<10)
	  		this.userService.showError('Email id allowed minimum 10 to maximum 80 character');
	  	else if(email>80)
	  		this.userService.showError('Email id allowed minimum 10 to maximum 80 character');
	  	else if(!this.userService.isEmail(contact_email))
        	this.userService.showError('Enter valid email id');
	  	else if(contact_phone!='' && phone<7)
	  		this.userService.showError('Phone number allowed minimum 7 to maximum 15 character');
	  	else if(contact_phone!='' && phone>15)
	  		this.userService.showError('Phone number allowed minimum 7 to maximum 15 character');
	  	else if(contact_phone!='' && this.contact.contact_country=='')
	  		this.userService.showError('Select country code');
	  	else if(contact_phone=='' && this.contact.contact_country!='')
	  		this.userService.showError('Enter mobile number');
	  	else if(postal!='' && contact_postal>10)
	  		this.userService.showError('Postal code allowed minimum 5 to maximum 10 character');
	  	else if(support!='' && contact_support<3)
	  		this.userService.showError('Query about allowed minimum 3 to 100 characters');
	  	else if(support!='' && contact_support>100)
	  		this.userService.showError('Query about allowed minimum 3 to 100 characters');
	  	else if(message=='')
	  		this.userService.showError('Enter message');
	  	else if(contact_message<3)
	  		this.userService.showError('Message allowed minimum 3 to 250 characters');
	  	else if(contact_message>250)
	  		this.userService.showError('Message allowed minimum 3 to 250 characters');
	  	else{
	  		this.httpService.supportSubmit(this)
		      	.subscribe((resp: any) => {
		      	if(resp.result!=''){
		      		this.userService.showSuccess(resp.result.message);
		      		(<HTMLFormElement>document.getElementById("contact_form")).reset();
		      		setTimeout(()=>{
            			window.location.reload();
          			}, 1000);
		      	}else{
		      		this.userService.showError(JSON.stringify(resp.message));
		      	}
		      	this.ngxService.stop(); 
		    }, (errorResponse: HttpErrorResponse) => {
		        //this.ngxService.stop(); 
		        let a  = errorResponse.error ? errorResponse.error.message
		              : 'Sorry, unable to complete your request, please refresh this page and try again'
		        this.userService.showError(a) ;
		    });
	  	}
	}

	countryCode(){
	    this.query = 'SELECT * FROM '+this.tablenames.country_code_table+' WHERE 1=1 ORDER BY country_name '; 
	    this.httpService.commonFetch2(this)
	      .subscribe((resp: any) => {
	      //console.log(resp);
	      this.country_records = resp.result;
	    }, (errorResponse: HttpErrorResponse) => {
	        var isError = errorResponse.error ? errorResponse.error.message
	                : 'Sorry, unable to complete your request, please refresh this page and try again'
	        this.userService.showError(isError) ;
	    });
	  }
	
  	ngOnInit() { 	
    	//this.downloadImageS3();
    	this.countryCode();
    	//console.log('JSON PARSE');
    	var m = JSON.parse(localStorage.member_records);
    	//console.log(m);
    	this.contact.contact_first_name = m.member_first_name;
    	this.contact.contact_last_name = m.member_last_name;
    	this.contact.contact_phone = m.member_mobile;
    	this.contact.contact_email = m.member_email;
    	this.contact.contact_country = m.member_country_code;
  	}

}
