import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.scss']
})
export class TermComponent implements OnInit {
  records = '';
  errorMsg = '';
  query = '';
  table = '';
  title = '';
  desc = '';
  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient) { }

  ngOnInit() { 	
    this.table = this.tablenames.cms;
    this.query = 'SELECT * FROM '+this.table+' WHERE 1=1 AND cms_id =16 '; 
    this.httpService.commonFetch(this)
      .subscribe((resp: any) => {
      this.records = resp.result;
      this.title = resp.result[0].cms_title;
      this.desc = resp.result[0].cms_desc1;
    }, (errorResponse: HttpErrorResponse) => {
          //console.log(errorResponse);
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
    });
  }
}
