import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { ToastrManager } from 'ng6-toastr-notifications';

import {ActivatedRoute} from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-approve-completed',
  templateUrl: './approve-completed.component.html',
  styleUrls: ['./editor-login.component.scss']
})

export class ApproveCompletedComponent implements OnInit {
  editor_id = '';
  query = '';
  server_url = '';
  job_records = '';
  assign_id = '';
  job_no = 0;

  constructor(public toastr: ToastrManager,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private userService:UserService,private ngxService: NgxUiLoaderService,private route:ActivatedRoute) { }
  
  viewJobs(ass_id){
    let s = ass_id.toString();
    s = this.httpService.encryptNew(s);
    
    this.router.navigate(['/approval-view/'+s]);
  } 

  allocateRecords(this){
    this.ngxService.start();
      this.query = 'SELECT manager_name,manager_email,manager_mobile,project_title,'+this.tablenames.project_assign_editor+'.created_date,'+this.tablenames.project_assign_editor+'.modified_date,assign_id,(SELECT request_project_status_by_pm FROM '+this.tablenames.request_pm_editor+' WHERE 1=1 AND request_project_id  = project_id order by request_id DESC LIMIT 1) as request_project_status_by_pm,(SELECT request_project_assign FROM '+this.tablenames.request_member+' WHERE 1=1 AND request_project_id  = project_id order by request_id DESC LIMIT 1) as project_edit_request  FROM '+this.tablenames.project_assign_editor+' JOIN '+this.tablenames.manager+' ON manager_id = assign_manager_id JOIN '+this.tablenames.project+' ON project_id = assign_project_id WHERE 1=1  AND assign_editor_id = '+this.editor_id+' AND project_status_by_pm IN(1) AND assign_approval_by_editor = 1'; 
      this.httpService.commonFetch2(this)
        .subscribe((resp: any) => {
        this.job_records = resp.result;
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  ngOnInit() { 	
    this.job_no = 0;
    this.assign_id = '';
    this.editor_id  = localStorage.getItem('editor_id');
    this.server_url = this.tablenames.server_url;
    if(this.editor_id!=''){
      this.allocateRecords();
    }

    
    
  }


}
