import { Component, OnInit,ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import { ToastrManager } from 'ng6-toastr-notifications';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { VideoHours } from '../video-hours.pipe';
/* Payment module */
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";

@Component({
  selector: 'app-step-five',
  templateUrl: './step-five.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class StepFiveComponent implements OnInit {
  records = '';
  errorMsg = '';
  query = '';
  table = '';
  title = '';
  isError = '';
  project_id = '';
  member_id = '';
  member_name = '';
  member_email = '';
  no_images = '';
  gallery = '';
  server_url = '';
  load_process = '';
  gallery_table = '';
  primary_id = '';
  project = {
    project_id:'',
    project_title:'',
    project_style:'',
    project_speed:'',
    project_music_selection:'',
    project_music_file:'',
    project_video_duration:0,
    project_opening_title:'',
    
    project_closing_title:'',
    project_other_text:'',
    project_opening_title_option:'',
    project_closing_title_option:'',
    project_other_text_option:'',
    project_additional:'',
  };

  current_video_duration = 0;

  data_id = '';
  style_records = '';
  speed_records = '';
  music_records = '';
  music_category = '';
  editube_music = '';
  editube_music_name = '';
  editube_music_file = ''; 
  existing_music = '';
  current_record = '';
  step1_url ='';
  step2_url ='';
  step3_url ='';
  step4_url = '';
  step5_url = '';
  minutes = [1,2,3,4,5,6,7,8,9,10];
  check_image_exist ='';
  total_video  = '';
  total_image  = 0;
  choosen_editube_music = '';
  gallery_audio = '';
  project_id_decrypt = '';
  gallery_all = '';
  total_min = 0;
  total_size = 0;
  current_min = 0;
  current_size = 0;
  total_video_min = '';

  base_amount = 0;
  max_video_length  = 0;
  max_picture = 0;
  max_final_video_length = 0;
  max_storage = 0;
  additional_video_length = 0;
  additional_video_length_price = 0;
  additional_picture =0;
  additional_picture_price = 0;
  additional_final_video_length =0;
  additional_final_video_length_price = 0;
  additional_storage_size = 0;
  additional_storage_size_price = 0;

  real_additional_video_price = 0;
  real_additional_video_min = '0';
  real_additional_image_price = 0;
  real_additional_image_count = 0;
  real_additional_final_video_price = 0;
  real_additional_final_video_min = '0';
  real_additional_storage_size = 0;
  real_additional_storage_size_price = 0;
  overall_file_size = 0;
  sub_total = 0;
  promo_code = '';
  promo_code_percentage = 0;
  promo_id = 0;
  overall_total = 0;
  overall_net_amount = 0;
  new_user_discount_percentage =0;
  discount_amount = 0;
  member_exists = 0;

  member_exists_records = '';
  payment_made_already = '';
  payment_made_already_amount = 0;
  r_d_storage = 0;
  add_p = 0;
  add_v_m = 0;
  /* For payment */
  elements: Elements;
  card: StripeElement;

  token_id='';
  card_id ='';
  stripeTest: FormGroup;

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private route: ActivatedRoute,public userService:UserService,private fb: FormBuilder,private ngxService: NgxUiLoaderService,private stripeService: StripeService) { }

  
  stepOneCheck(){
    this.ngxService.start(); 
    this.httpService.stepOneCheck(this)
      .subscribe((resp: any) => {
      this.ngxService.stop(); 
      /* 
      *   Checking condition for redirect step one page if it is null
      */
      if(resp.result.result1[0].project_title==''){
        this.userService.showError('Enter project title');
        this.router.navigate(['/new-project/'+this.project_id]);
      }
      else if(resp.result.result2[0].tot=='' || resp.result.result2[0].tot=='0' ){
        this.userService.showError('Upload your image or video files');
        this.router.navigate(['/new-project/'+this.project_id]);
      }else if(resp.result.result1[0].project_style=='' || resp.result.result1[0].project_style=='0'){
         this.userService.showError('Select style');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_space_speed=='' || resp.result.result1[0].project_space_speed=='0'){
         this.userService.showError('Select space/speed');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_music_selection=='' || resp.result.result1[0].project_music_selection=='0'){
         this.userService.showError('Select music ');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_music_selection!='' && resp.result.result1[0].project_music_selection=='2' && (resp.result.result3[0].audio_count=='' || resp.result.result3[0].audio_count=='0') ){
        this.userService.showError('Please upload your music ');
        this.router.navigate(['/step-two/'+this.project_id]);
      }
      else if(resp.result.project_opening_title_option==''){
        this.userService.showError('Select opening title');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.project_opening_title_option=='1' && this.project.project_opening_title=='' ){
        this.userService.showError('Enter opening title');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.project_closing_title_option==''){
        this.userService.showError('Select closing title');  
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_closing_title_option=='1' && this.project.project_closing_title=='' ){
        this.userService.showError('Enter closing title');    
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_other_text_option==''){
        this.userService.showError('Select text option'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_other_text_option=="1" && this.project.project_other_text=='' ){
        this.userService.showError('Enter text option'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      
      else{
        //this.current_record = resp.result.result1[0];
        //console.log(resp);
        this.project.project_id = resp.result.result1[0].project_id;
        this.project.project_title = resp.result.result1[0].project_title;
        this.project.project_style = resp.result.result1[0].project_style;
        this.project.project_video_duration = resp.result.result1[0].project_video_duration;

        this.current_video_duration = resp.result.result1[0].project_video_duration;
        this.project.project_opening_title = resp.result.result1[0].project_opening_title_option==1?resp.result.result1[0].project_opening_title:'No Thanks';

        this.project.project_other_text_option = resp.result.result1[0].project_other_text_option==1?resp.result.result1[0].project_other_text:'No Thanks';

        this.project.project_closing_title = resp.result.result1[0].project_closing_title_option==1?resp.result.result1[0].project_closing_title:'No Thanks';

        this.project.project_other_text = resp.result.result1[0].project_other_text;



        this.project.project_additional = resp.result.result1[0].project_additional;

        this.project.project_music_selection = resp.result.result1[0].project_music_selection;

        this.project.project_music_file = resp.result.result1[0].project_music_file;
        this.checkUserExistPayment();
        this.projectFileSize();
        this.videoImage();
      }
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  stepFourRecords(this){
    this.ngxService.start(); 
    this.httpService.stepFourRecords(this).subscribe((resp: any) => {
      //console.log(resp);
      this.ngxService.stop(); 
      if(resp.result!='' ){

        this.userService.showSuccess('Forms submitted successfully');
        this.router.navigate(['/step-four/'+this.project_id]);
      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  videoImage(this){
    this.ngxService.start(); 
    this.httpService.videoImage(this).subscribe((resp: any) => {
      //console.log(resp);
      this.ngxService.stop(); 
      if(resp.result!='' ){
        this.total_video = resp.result.total_video.length;
        this.total_image = resp.result.total_image.length;
        this.gallery_audio = resp.result.total_audio;
        this.gallery_all = resp.result.all_records;

        for (let j = 0; j < this.gallery_all.length; j++) {
        
          if(this.gallery_all[j].gallery_video_min!='' && this.gallery_all[j].gallery_video_min!=0)
            this.current_min =  parseFloat(this.current_min) + parseFloat(this.gallery_all[j].gallery_video_min);

          this.current_size = parseFloat(this.current_size) +  parseFloat(this.gallery_all[j].gallery_file_size);
        }

        if(this.current_min!='')
          this.total_min = this.current_min/60;
        
        if(this.current_size!='')
        this.total_size = this.current_size/(this.tablenames.mb_size * this.tablenames.mb_size);
        
        
        if(this.current_min!='')
        this.total_video_min = this.httpService.secondsToTime(this.current_min);

        
        this.additionalCal();

      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }



  additionalCal(){
    /* Video length */
    let video_min =  (this.max_video_length * 60) - this.current_min   ;
    /*//console.log('A_V_L :'+this.max_video_length * 60);
    //console.log('CURRENT :'+this.current_min);
    //console.log('EXTRA : '+video_min);*/
    
    /* If video length is higher than configuration */
    if(video_min<0){

      let video_min_plus = ( video_min * video_min);
      video_min_plus = Math.sqrt(video_min_plus);
      
      /* Price calculate for video */
      let add_v_len = (this.additional_video_length) * 60; 
      this.add_v_m = (video_min_plus / add_v_len);

      this.add_v_m = this.roundExtra(this.add_v_m);

      this.real_additional_video_price = (this.add_v_m) * (this.additional_video_length_price);

      /*//console.log('add_v_len :'+add_v_len);
      //console.log('add_v_m :'+add_v_m);
      //console.log('additional_video_length_price :'+this.additional_video_length_price);
      //console.log('real_additional_video_price :'+this.real_additional_video_price);
      */
      this.real_additional_video_min = this.httpService.secondsToTime(video_min_plus).toString();
    }else
      this.real_additional_video_min = '0';
    
    /* Additional photos */
      let add_photos:number = (this.max_picture - this.total_image);
      //console.log('max_picture :'+this.max_picture);
      //console.log('total_image :'+this.total_image);
      //console.log('EXTRA :'+add_photos);
      if(add_photos<0){
        add_photos = (add_photos * add_photos)
        add_photos = Math.sqrt(add_photos);
        this.add_p = (add_photos / this.additional_picture);
        
        //add_p = Math.round(add_p);
        if(this.add_p!=0)
          this.add_p = this.roundExtra(this.add_p);
        
        this.real_additional_image_price = ( this.add_p * this.additional_picture_price );
        this.real_additional_image_count = add_photos;
      }

      //console.log('this.current_video_duration:'+this.current_video_duration);
      /* Additional final edited video size */
      let edited_video =  (this.max_final_video_length * 60) - (this.project.project_video_duration * 60)  ;
      //console.log('max_final_video_length :'+this.max_final_video_length);
      //console.log('this.project.project_video_duration'+this.project.project_video_duration);
      //console.log('Extra :'+edited_video);
      
      /* If final video length is higher than configuration */
      if(edited_video<0){

        let video_min_plus = ( edited_video * edited_video);
        video_min_plus = Math.sqrt(video_min_plus);
        
        /* Price calculate for video */
        let add_v_len = (this.additional_final_video_length) * 60; 
        let add_v_m = (video_min_plus / add_v_len);
        this.real_additional_final_video_price = (add_v_m) * (this.additional_video_length_price);

        
        this.real_additional_final_video_min = this.httpService.secondsToTime(video_min_plus).toString();
      }else
        this.real_additional_final_video_min = '0';

      /* Extra storage space */
      let extra_video_byte =  ((this.max_storage * this.tablenames.mb_size) * this.tablenames.mb_size * this.tablenames.mb_size) - (this.overall_file_size)  ;
      //console.log('MAXIMUM STORAGE :'+this.max_storage);
      //console.log('overall_file_size :'+this.overall_file_size);
      //console.log('EXTRA STORAGE :'+extra_video_byte);

      /* If final video size is higher than configuration */
      if(extra_video_byte<0){

        let extra_video_byte_plus = ( extra_video_byte * extra_video_byte);
        extra_video_byte_plus = Math.sqrt(extra_video_byte_plus);
        
        /* Price calculate for storage */
        let add_v_storage = (this.additional_storage_size * this.tablenames.mb_size) * this.tablenames.mb_size * this.tablenames.mb_size; 
        let add_v_s = (extra_video_byte_plus / add_v_storage);
        
        /* Convert MB to GB */
        add_v_s = add_v_s / ( 3 * this.tablenames.mb_size);
        
        this.real_additional_storage_size = (extra_video_byte_plus / ( this.tablenames.mb_size * this.tablenames.mb_size * this.tablenames.mb_size )) ;

        this.r_d_storage = Math.round(this.real_additional_storage_size);
        let a_storage = 0;

        if(this.r_d_storage!=0 ){
          
          a_storage = (this.r_d_storage / this.additional_storage_size );
          a_storage = this.roundExtra(a_storage);
        }

        this.real_additional_storage_size_price = (a_storage) * (this.additional_storage_size_price);

        
        
      }



      this.sub_total = this.base_amount + this.sub_total + this.real_additional_video_price + this.real_additional_image_price  + this.real_additional_final_video_price + this.real_additional_storage_size_price;
        
      this.overall_total =  this.sub_total;

      /*if(this.member_exists==0){
        this.discount_amount = (this.overall_total/100)*this.new_user_discount_percentage;
      }*/


      /* IF PAYMENT ALREADY MADE */
      this.overall_total = this.overall_total - this.payment_made_already_amount;

      this.overall_total = this.overall_total ;
      this.overall_net_amount = this.overall_total - this.discount_amount;

      
  }

  roundExtra(r){
    r = r.toString();
    let c = r.split('.');
    if(typeof c[1]!='undefined' && c[1]!='' ){
      return parseFloat(c[0])+1;
    }else{
      return parseFloat(c[0]);
    }
  }


  projectFileSize(this){
    this.ngxService.start(); 
    this.httpService.projectFileSize(this).subscribe((resp: any) => {
      this.ngxService.stop(); 
      if(resp.result!='' ){
        this.overall_file_size = resp.result.records[0].total_size;
      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  setting(this){
    this.ngxService.start(); 
    this.httpService.settings(this).subscribe((resp: any) => {
      this.ngxService.stop(); 
      if(resp.result!='' ){
        resp = resp.result.records[0];
        
        this.base_amount =resp.base_amount ;
        this.max_video_length  = resp.max_video_length;
        this.max_picture = resp.max_picture;
        this.max_final_video_length = resp.max_final_video_length;
        this.max_storage = resp.max_storage;
        this.additional_video_length = resp.additional_video_length;
        this.additional_video_length_price = resp.additional_video_length_price;
        this.additional_picture = resp.additional_picture;
        this.additional_picture_price = resp.additional_picture_price;
        this.additional_final_video_length = resp.additional_final_video_length;
        this.additional_final_video_length_price = resp.additional_final_video_length_price;
        this.additional_storage_size = resp.additional_storage_size;
        this.additional_storage_size_price = resp.additional_storage_size_price;
        this.new_user_discount_percentage = resp.new_user_discount_percentage;
        
      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }
  
  paymentMadeAlready(){
    this.ngxService.start();
    /* Query for gallery records */
    this.query = 'SELECT * FROM '+this.tablenames.project_payment+' WHERE 1=1 AND  payment_project_id = '+this.httpService.decryptNew(this.project_id)+' AND payment_status = 1 '; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      
      if(resp.result[0]!='')
        
        this.payment_made_already = resp.result[0].payment_id;
        this.payment_made_already_amount = resp.result[0].payment_discount;
      this.ngxService.stop(); 
    }, (errorResponse: HttpErrorResponse) => {
        //this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  checkUserExistPayment(this){
    this.ngxService.start(); 
    this.httpService.checkUserExistPayment(this).subscribe((resp: any) => {
      //console.log(resp);
      this.ngxService.stop(); 
      if(resp.result!='' ){
        //console.log('EXUSUSUUS');
        //console.log(resp);
        this.member_exists = resp.result.records[0].total;

        this.member_exists_records = resp.result.records[0];

      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }  

  /* Make payment */
  makePayment(this){
    //console.log(this);
    this.ngxService.start(); 
    this.httpService.makePayment(this).subscribe((resp: any) => {
      this.ngxService.stop(); 
      if(resp.result!='' ){
        $('#paymentModal').modal('hide');
        this.userService.showSuccess(resp.result.message);
        //this.ngxService.stop(); 
        this.router.navigate(['/payment/'+this.project_id]); 
      }else if(resp.message!='')
        this.userService.showError(JSON.stringify(resp.message));
        this.ngxService.stop(); 
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }
  
  /* Payment Popup */
  paymentPopup(){
    $('#paymentModal').modal('show');
  }

  buy() {
    const name = this.stripeTest.get('name').value;
    //const amount = this.stripeTest.get('amount').value;
    let m = localStorage.getItem('member_id');

    ////console.log(this.card.amount);

    if(typeof m=='undefined' || m==''){
      location.reload();
    }
    else if(name!='' && typeof name!='undefined'){
    this.stripeService
      .createToken(this.card, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          //console.log(result.token);
          this.card_id = result.token.card.id;
          this.token_id = result.token.id;
          
          this.ngxService.start(); 
          this.userService.showWarning("Payment in progress DONT refresh this page.");
          this.httpService.paymentStripe(this).subscribe((resp: any) => {
            //console.log(resp)
            //this.ngxService.stop(); 
            if(resp.result.result!='' && typeof resp.result.result!='undefined' ){
              //alert('Make payment');
              this.makePayment();
            }else if(resp.message!=''){
              this.ngxService.stop();
              if(resp.message.error.message!='' && typeof (resp.message.error.message)!='undefined' )
                this.userService.showError('Payment is failed. '+ (resp.message.error.message)+'. Please try again.');
              else
                this.userService.showError('Payment is failed. Please try again.');
            }
               
          }, (errorResponse: HttpErrorResponse) => {
              this.isError = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again'
              this.userService.showError(this.isError) ;
          });
          
        } else if (result.error) {
          // Error creating the token
          this.userService.showError(JSON.stringify(result.error.message));
        }
      });
    }else{
      alert('Enter name');
    }
    
  }

  clearPromo(){
    window.location.reload();
  }

  ApplyPromo(){
    if(this.promo_code!=''){
      
      this.ngxService.start();
      /* Query for gallery records */
      this.httpService.ApplyPromo(this)
        .subscribe((resp: any) => {
        //console.log(resp);

        if(resp.result!=''){
          /**/
          $('.apply_promo').remove();
          $('.clear_promo').show();
          $('input[name=promo_code]').attr('readonly','true');
          this.userService.showSuccess('Promo code applied') ;
          
          
          let p = resp.result[0].promo_percent;
          this.promo_id = resp.result[0].promo_id; 
          
          if(resp.result[0].promo_type==1){
            this.discount_amount = (this.overall_total/100)*p;
            this.overall_net_amount = this.overall_net_amount -  this.discount_amount;
          }else if(resp.result[0].promo_type==2){
            this.discount_amount = p;
            this.overall_net_amount = this.overall_net_amount -  this.discount_amount;
          }

          if(this.overall_net_amount<0){
            alert('After apply promocode net amount must greater than 0 ');
            window.location.reload();
          }

          //console.log('OVER ALL NET AMOUNT:'+this.overall_net_amount);
          //console.log('PERCENT AMT:'+p);
          

          //this.additionalCal();
        }else{
        
          if(resp.message!='' && typeof resp.message!='undefined' )
            this.userService.showWarning('Promocode is invalid. Enter a valid promocode') ;
          else
              this.userService.showWarning('Promocode is invalid. Enter a valid promocode');
        }
        this.ngxService.stop(); 
      }, (errorResponse: HttpErrorResponse) => {
          //this.ngxService.stop(); 
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(this.isError) ;
      });
    }else
      this.userService.showError('Promocode is invalid. Enter a valid promocode') ;
  }

  ngOnInit() { 
    this.promo_code_percentage = 0;
    this.setting();
    this.existing_music = '';
    this.primary_id  = 'gallery_id';
    this.gallery_table = this.tablenames.project_gallery
    this.load_process = '';
    this.server_url = this.tablenames.server_url;
    
    this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });
    
    this.httpService.paymentDoneAlready(this);
    
    this.step1_url = '/#/new-project/'+this.project_id;
    this.step2_url = '/#/step-two/'+this.project_id;
    this.step3_url = '/#/step-three/'+this.project_id;
    this.step4_url = '/#/step-four/'+this.project_id;
    this.step5_url = '/#/step-five/'+this.project_id;
    this.table = this.tablenames.project;
    this.member_id = localStorage.getItem('member_id');
    
    let m = JSON.parse(localStorage.getItem('member_records'));

    this.member_name = m.member_first_name+' '+m.member_last_name;
    this.member_email = m.member_email;
    this.project_id_decrypt = this.httpService.decryptNew(this.project_id);
    this.stepOneCheck();
    this.paymentMadeAlready();

    /* For payment */
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
      amount: ['', [Validators.required]]
    });
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#CFD7E0'
                }
              }
            }
          });
          this.card.mount('#card-element');
          
        }
      });
  }
}
