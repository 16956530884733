import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 


@Component({
  selector: 'app-our-process',
  templateUrl: './our-process.component.html',
  styleUrls: ['./our-process.component.scss']
})

export class OurprocessComponent implements OnInit {
    query = '';
    table = '';
    records = '';
    errorMsg = '';
    video_limit = '3';
    sample_videos = '';
    title0 ='';
    desc0 = '';

    title1 ='';
    desc1 = '';
    
    title2 ='';
    desc2 = '';

    title3 ='';
    desc3 = '';

    title4 ='';
    desc4 = '';

    title5 ='';
    desc5 = '';

    title6 ='';
    desc6 = '';

    title7 ='';
    desc7 = '';

    title8 ='';
    desc8 = '';

    title9 ='';
    desc9 = '';

    title10 ='';
    desc10 = '';

    title11 ='';
    desc11 = '';
    

  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService) { }

  ngOnInit() { 	
    window.scrollTo(0,0);

    this.table = this.tablenames.cms;

    this.query = 'SELECT * FROM '+this.table+' WHERE 1=1 AND cms_id BETWEEN 18 AND 29 '; 
          
    this.httpService.commonFetch(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.records = resp.result;
      this.title0 = resp.result[0].cms_title;
      this.desc0 = resp.result[0].cms_desc1;

      this.title1 = resp.result[1].cms_title;
      this.desc1 = resp.result[1].cms_desc1;

      this.title2 = resp.result[2].cms_title;
      this.desc2 = resp.result[2].cms_desc1;

      this.title3 = resp.result[3].cms_title;
      this.desc3 = resp.result[3].cms_desc1;

      this.title4 = resp.result[4].cms_title;
      this.desc4 = resp.result[4].cms_desc1;

      this.title5 = resp.result[5].cms_title;
      this.desc5 = resp.result[5].cms_desc1;

      this.title6 = resp.result[6].cms_title;
      this.desc6 = resp.result[6].cms_desc1;

      this.title7 = resp.result[7].cms_title;
      this.desc7 = resp.result[7].cms_desc1;

      this.title8 = resp.result[8].cms_title;
      this.desc8 = resp.result[8].cms_desc1;

      this.title9 = resp.result[9].cms_title;
      this.desc9 = resp.result[9].cms_desc1;

      this.title10 = resp.result[10].cms_title;
      this.desc10 = resp.result[10].cms_desc1;

      this.title11 = resp.result[11].cms_title;
      this.desc11 = resp.result[11].cms_desc1;
    }, (errorResponse: HttpErrorResponse) => {
          //console.log(errorResponse);
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
    });

    this.video_limit = '3';
    
    this.httpService.videoCategory(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.sample_videos = resp.result;
    }, (errorResponse: HttpErrorResponse) => {
          //console.log(errorResponse);
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
    });
    
  }

}
