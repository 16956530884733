import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { ToastrManager } from 'ng6-toastr-notifications';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-waiting-approve',
  templateUrl: './waiting-approve.component.html',
  styleUrls: ['./editor-login.component.scss']
})

export class WaitingApproveComponent implements OnInit {
  editor_id = '';
  query = '';
  server_url = '';
  job_records = '';
  assign_id = '';
  job_no = 0;
  current_manager_email = '';
  current_project = '';
  current_project_id = '';
  constructor(public toastr: ToastrManager,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private userService:UserService,private ngxService: NgxUiLoaderService) { }
  
  confirmJob(obj){
    let ass_id = obj.srcElement.attributes.assign_id.value;
    let manager_email = obj.srcElement.attributes.manager_email.value;
    let project_title = obj.srcElement.attributes.project_title.value;
    this.current_project_id = obj.srcElement.attributes.project_id.value;

    

    if(ass_id!=''){


      
      if(confirm('Are you sure confirm this job?')){  
      this.ngxService.start();
        this.assign_id = ass_id.toString();
        this.current_manager_email = manager_email;
        this.current_project = project_title;
        this.httpService.confirmJob(this)
          .subscribe((resp: any) => {
          if(resp.result.result=='success'){
            this.userService.showSuccess(resp.result.message);
            this.allocateRecords();
          }else{
            this.userService.showError(resp.result.message) ;
          }
          this.ngxService.stop();
        }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
            let isError = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(isError) ;
        });
      }
      
    }else{
      this.userService.showError('Id is not available');
    }
    
  }

  allocateRecords(this){
    this.ngxService.start();
      this.query = 'SELECT manager_name,manager_email,manager_mobile,project_title,project_id,'+this.tablenames.project_assign_editor+'.created_date,assign_id FROM '+this.tablenames.project_assign_editor+' JOIN '+this.tablenames.manager+' ON manager_id = assign_manager_id JOIN '+this.tablenames.project+' ON project_id = assign_project_id WHERE 1=1  AND assign_editor_id = '+this.editor_id+' AND project_status_by_pm NOT IN(1,3) AND assign_approval_by_editor = 2'; 

      this.httpService.commonFetch2(this)
        .subscribe((resp: any) => {
        this.job_records = resp.result;
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  ngOnInit() { 	
    this.job_no = 0;
    this.assign_id = '';
    this.editor_id  = localStorage.getItem('editor_id');
    this.server_url = this.tablenames.server_url;
    if(this.editor_id!=''){
      this.allocateRecords();
    }
    
  }


}
