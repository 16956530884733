import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import { ToastrManager } from 'ng6-toastr-notifications';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class PaymentComponent implements OnInit {
  records = '';
  errorMsg = '';
  query = '';
  table = '';
  title = '';
  isError = '';
  project_id = '';
  member_id = '';
  no_images = '';
  gallery = '';
  server_url = '';
  load_process = '';
  gallery_table = '';
  primary_id = '';
  project = {
    project_id:'',
    project_title:'',
    project_style:'',
    project_speed:'',
    project_music_selection:'',
    project_music_file:'',
    project_video_duration:0,
    project_opening_title:'',
    
    project_closing_title:'',
    project_other_text:'',
    project_opening_title_option:'',
    project_closing_title_option:'',
    project_other_text_option:'',
    project_additional:'',
  };

  current_video_duration = 0;

  data_id = '';
  style_records = '';
  speed_records = '';
  music_records = '';
  music_category = '';
  editube_music = '';
  editube_music_name = '';
  editube_music_file = ''; 
  existing_music = '';
  current_record = '';
  step1_url ='';
  step2_url ='';
  step3_url ='';
  step4_url = '';
  step5_url = '';
  minutes = [1,2,3,4,5,6,7,8,9,10];
  check_image_exist ='';
  total_video  = '';
  total_image  = 0;
  choosen_editube_music = '';
  gallery_audio = '';
  project_id_decrypt = '';
  gallery_all = '';
  total_min = 0;
  total_size = 0;
  current_min = 0;
  current_size = 0;
  total_video_min = '';

  base_amount = 0;
  max_video_length  = 0;
  max_picture = 0;
  max_final_video_length = 0;
  max_storage = 0;
  additional_video_length = 0;
  additional_video_length_price = 0;
  additional_picture =0;
  additional_picture_price = 0;
  additional_final_video_length =0;
  additional_final_video_length_price = 0;
  additional_storage_size = 0;
  additional_storage_size_price = 0;

  real_additional_video_price = 0;
  real_additional_video_min = '0';
  real_additional_image_price = 0;
  real_additional_image_count = 0;
  real_additional_final_video_price = 0;
  real_additional_final_video_min = '0';
  sub_total = 0;
  promo_code = '';
  overall_total = 0;
  overall_net_amount = 0;
  new_user_discount_percentage =0;
  discount_amount = 0;
  member_exists = 0;
  order_number =0;
  order_date = '';

  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private route: ActivatedRoute,private userService:UserService,private fb: FormBuilder,private ngxService: NgxUiLoaderService) { }

  
  stepOneCheck(){
    this.httpService.stepOneCheck(this)
      .subscribe((resp: any) => {
      /* 
      *   Checking condition for redirect step one page if it is null
      */
      if(resp.result.result1[0].project_title==''){
        this.userService.showError('Enter project title');
        this.router.navigate(['/new-project/'+this.project_id]);
      }
      else if(resp.result.result2[0].tot=='' || resp.result.result2[0].tot=='0' ){
        this.userService.showError('Upload your image or video files');
        this.router.navigate(['/new-project/'+this.project_id]);
      }else if(resp.result.result1[0].project_style=='' || resp.result.result1[0].project_style=='0'){
         this.userService.showError('Select style');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_space_speed=='' || resp.result.result1[0].project_space_speed=='0'){
         this.userService.showError('Select space/speed');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_music_selection=='' || resp.result.result1[0].project_music_selection=='0'){
         this.userService.showError('Select music ');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_music_selection!='' && resp.result.result1[0].project_music_selection=='2' && (resp.result.result3[0].audio_count=='' || resp.result.result3[0].audio_count=='0') ){
        this.userService.showError('Please upload your music ');
        this.router.navigate(['/step-two/'+this.project_id]);
      }
      else if(resp.result.project_opening_title_option==''){
        this.userService.showError('Select opening title');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.project_opening_title_option=='1' && this.project.project_opening_title=='' ){
        this.userService.showError('Enter opening title');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.project_closing_title_option==''){
        this.userService.showError('Select closing title');  
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_closing_title_option=='1' && this.project.project_closing_title=='' ){
        this.userService.showError('Enter closing title');    
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_other_text_option==''){
        this.userService.showError('Select text option'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_other_text_option=="1" && this.project.project_other_text=='' ){
        this.userService.showError('Enter text option'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_additional==''){
        this.userService.showError('Enter additional instructions'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else{
        //this.current_record = resp.result.result1[0];

        this.project.project_id = resp.result.result1[0].project_id;
        this.project.project_title = resp.result.result1[0].project_title;
        this.project.project_style = resp.result.result1[0].project_style;
        this.project.project_video_duration = resp.result.result1[0].project_video_duration;

        this.current_video_duration = resp.result.result1[0].project_video_duration;
        this.project.project_opening_title = resp.result.result1[0].project_opening_title_option==1?resp.result.result1[0].project_opening_title:'No Thanks';

        this.project.project_other_text_option = resp.result.result1[0].project_other_text_option==1?resp.result.result1[0].project_other_text:'No Thanks';

        this.project.project_closing_title = resp.result.result1[0].project_closing_title_option==1?resp.result.result1[0].project_closing_title:'No Thanks';

        this.project.project_additional = resp.result.result1[0].project_additional;

        this.project.project_music_selection = resp.result.result1[0].project_music_selection;

        this.project.project_music_file = resp.result.result1[0].project_music_file;
        this.existPayment();
        //this.videoImage();
      }
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  stepFourRecords(this){
    this.httpService.stepFourRecords(this).subscribe((resp: any) => {
      //console.log(resp);
      if(resp.result!='' ){
        this.userService.showSuccess('Forms submitted successfully');
        this.router.navigate(['/step-four/'+this.project_id]);
      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  videoImage(this){
    this.httpService.videoImage(this).subscribe((resp: any) => {
      //console.log(resp);
      if(resp.result!='' ){
        this.total_video = resp.result.total_video.length;
        this.total_image = resp.result.total_image.length;
        this.gallery_audio = resp.result.total_audio;
        this.gallery_all = resp.result.all_records;

        for (let j = 0; j < this.gallery_all.length; j++) {
        
          if(this.gallery_all[j].gallery_video_min!='' && this.gallery_all[j].gallery_video_min!=0)
            this.current_min =  parseFloat(this.current_min) + parseFloat(this.gallery_all[j].gallery_video_min);

          this.current_size = parseFloat(this.current_size) +  parseFloat(this.gallery_all[j].gallery_file_size);
        }

        this.total_min = this.current_min/60;
        this.total_size = this.current_size/(1024 * 1024);
        this.total_video_min = this.httpService.secondsToTime(this.current_min);

        
        //this.additionalCal();

      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }



  additionalCal(){
    /* Video length */
    let video_min =  (this.max_video_length * 60) - this.current_min   ;
    /*//console.log('A_V_L :'+this.max_video_length * 60);
    //console.log('CURRENT :'+this.current_min);
    //console.log('EXTRA : '+video_min);*/
    
    /* If video length is higher than configuration */
    if(video_min<0){

      let video_min_plus = ( video_min * video_min);
      video_min_plus = Math.sqrt(video_min_plus);
      
      /* Price calculate for video */
      let add_v_len = (this.additional_video_length) * 60; 
      let add_v_m = (video_min_plus / add_v_len);
      this.real_additional_video_price = (add_v_m) * (this.additional_video_length_price);

      /*//console.log('add_v_len :'+add_v_len);
      //console.log('add_v_m :'+add_v_m);
      //console.log('additional_video_length_price :'+this.additional_video_length_price);
      //console.log('real_additional_video_price :'+this.real_additional_video_price);
      */
      this.real_additional_video_min = this.httpService.secondsToTime(video_min_plus).toString();
    }else
      this.real_additional_video_min = '0';
    
    /* Additional photos */
      let add_photos:number = (this.max_picture - this.total_image);
      //console.log('max_picture :'+this.max_picture);
      //console.log('total_image :'+this.total_image);
      //console.log('EXTRA :'+add_photos);
      if(add_photos<0){
        add_photos = (add_photos * add_photos)
        add_photos = Math.sqrt(add_photos);
        let add_p = (add_photos / this.additional_picture);
        this.real_additional_image_price = ( add_p * this.additional_picture_price );
        this.real_additional_image_count = add_photos;
      }

      //console.log('this.current_video_duration:'+this.current_video_duration);
      /* Additional final edited video size */
      let edited_video =  (this.max_final_video_length * 60) - (this.project.project_video_duration * 60)  ;
      //console.log('max_final_video_length :'+this.max_final_video_length);
      //console.log('this.project.project_video_duration'+this.project.project_video_duration);
      //console.log('Extra :'+edited_video);
      
      /* If final video length is higher than configuration */
      if(edited_video<0){

        let video_min_plus = ( edited_video * edited_video);
        video_min_plus = Math.sqrt(video_min_plus);
        
        /* Price calculate for video */
        let add_v_len = (this.additional_final_video_length) * 60; 
        let add_v_m = (video_min_plus / add_v_len);
        this.real_additional_final_video_price = (add_v_m) * (this.additional_video_length_price);

        
        this.real_additional_final_video_min = this.httpService.secondsToTime(video_min_plus).toString();
      }else
        this.real_additional_final_video_min = '0';



      this.sub_total = this.base_amount + this.sub_total + this.real_additional_video_price + this.real_additional_image_price;
        
      this.overall_total =  this.sub_total;

      if(this.member_exists==0){
        this.discount_amount = (this.overall_total/100)*this.new_user_discount_percentage;
      }

      this.overall_total = this.overall_total;
      this.overall_net_amount = this.overall_total - this.discount_amount;
  }

  

  setting(this){
    this.httpService.settings(this).subscribe((resp: any) => {
      
      if(resp.result!='' ){
        resp = resp.result.records[0];
        
        this.base_amount =resp.base_amount ;
        this.max_video_length  = resp.max_video_length;
        this.max_picture = resp.max_picture;
        this.max_final_video_length = resp.max_final_video_length;
        this.max_storage = resp.max_storage;
        this.additional_video_length = resp.additional_video_length;
        this.additional_video_length_price = resp.additional_video_length_price;
        this.additional_picture = resp.additional_picture;
        this.additional_picture_price = resp.additional_picture_price;
        this.additional_final_video_length = resp.additional_final_video_length;
        this.additional_final_video_length_price = resp.additional_final_video_length_price;
        this.additional_storage_size = resp.additional_storage_size;
        this.additional_storage_size_price = resp.additional_storage_size_price;
        this.new_user_discount_percentage = resp.new_user_discount_percentage;
        
      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }
  
  existPayment(this){
    this.httpService.existPayment(this).subscribe((resp: any) => {
      //console.log(resp);
      if(resp.result!='' ){
        this.order_number = resp.result.records[0].payment_id;
        this.order_date = resp.result.records[0].created_date;
      }else if(resp.result=='error'){
        this.userService.showError(' file not received');
        this.router.navigate([this.step1_url]); 
      }
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }  

  ngOnInit() { 
    this.setting();
    this.existing_music = '';
    this.primary_id  = 'gallery_id';
    this.gallery_table = this.tablenames.project_gallery
    this.load_process = '';
    this.server_url = this.tablenames.server_url;
    
    this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });
    
    this.step1_url = '/#/new-project/'+this.project_id;
    this.step2_url = '/#/step-two/'+this.project_id;
    this.step3_url = '/#/step-three/'+this.project_id;
    this.step4_url = '/#/step-four/'+this.project_id;
    this.step5_url = '/#/step-five/'+this.project_id;
    this.table = this.tablenames.project;
    this.member_id = localStorage.getItem('member_id');
    
    this.project_id_decrypt = this.httpService.decryptNew(this.project_id);
    this.stepOneCheck();
  
  }
}
