import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';

import {HttpService} from './services/http.service';
import {UserService} from './services/user.service';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router/src/router_state';

@Injectable()
export class NeedAuthGuard implements CanActivate {

  constructor(private httpService: HttpService, private router: Router,private userService:UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const redirectUrl = route['_routerState']['url']+'index';

    if (this.userService.isLoggedInM()) {
      //console.log('AUTH');
      return true;
    }

    this.router.navigateByUrl(
      this.router.createUrlTree(
        ['/#/'], {
          queryParams: {
            redirectUrl
          }
        }
      )
    );

    return false;
  }
}