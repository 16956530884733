import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-video-step-three',
  templateUrl: './video-step-three.component.html',
  styleUrls: ['./video-step-three.component.css']
})
export class VideoStepThreeComponent implements OnInit {

 records = '';
  errorMsg = '';
  query = '';
  table = '';
  title = '';
  isError = '';
  project_id = '';
  member_id = '';
  no_images = '';
  gallery = '';
  server_url = '';
  load_process = '';
  gallery_table = '';
  primary_id = '';
  project = {
    project_id:'',
    project_title:'',
    type_of_edit:'',
    if_type_of_edit_others:'',
    duration_final_video:'',
    duration_final_video_value:'',
    footage_duration:'',
    purpose_of_final_video:'',
    final_video_format:'',
    final_video_date:'',
    comments:'',
    project_style:'',
    project_speed:'',
    project_style_name:'',
    project_speed_name:'',
    project_music_selection:'',
    project_music_file:'',
    project_video_duration:'',
    project_opening_title:'',
    is_url_shared:'',
    video_url:'',
    video_password:'',
    
    project_closing_title:'',
    project_other_text:'',
    project_opening_title_option:'',
    project_closing_title_option:'',
    project_other_text_option:'',
    project_additional:'',
    status:0
  };
  music_selection ='';
  data_id = '';
  style_records = '';
  speed_records = '';
  music_records = '';
  music_category = '';
  editube_music = '';
  editube_music_name = '';
  editube_music_file = ''; 
  existing_music = '';
  current_record = '';
  step1_url ='';
  step2_url ='';
  step3_url ='';
  step5_url = '';
  minutes = [1,2,3,4,5,6,7,8,9,10];
  check_image_exist ='';
  total_video  = '';
  total_image  = '';
  choosen_editube_music = '';
  gallery_audio = '';
  project_id_decrypt = '';
  gallery_all = '';
  total_min = 0;
  total_size = 0;
  current_min = 0;
  current_size = 0;
  total_video_min = '';
  s3url = '';
  constructor(private router: Router,public httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private route: ActivatedRoute,private userService:UserService,private fb: FormBuilder,private ngxService: NgxUiLoaderService) { }

  
  stepOneCheck(){
  this.ngxService.start(); 
    this.httpService.projectCheck(this)
      .subscribe((resp: any) => {

      if(resp.result.result1==''){
        window.location.href='/#/index';
      }
      this.ngxService.stop(); 
      /* 
      *   Checking condition for redirect step one page if it is null
      */
      if(resp.result.result1[0].project_title==''){
        this.userService.showError('Enter project title');
        this.router.navigate(['/new-project/'+this.project_id]);
      }
      
      else if(resp.result.result1[0].project_opening_title_option==''){
        this.userService.showError('Select opening title');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.result1[0].project_opening_title_option=='1' && resp.result.result1[0].project_opening_title=='' ){
        this.userService.showError('Enter opening title');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.result1[0].project_closing_title_option==''){
        this.userService.showError('Select closing title');  
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.result1[0].project_closing_title_option=='1' && resp.result.result1[0].project_closing_title=='' ){
        this.userService.showError('Enter closing title');    
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.result1[0].project_other_text_option==''){
        this.userService.showError('Select text option'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.result1[0].project_other_text_option=="1" && resp.result.result1[0].project_other_text=='' ){
        this.userService.showError('Enter text option'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      
      else{
        this.userService.showSuccess('Step 3 completed successfully');

        //this.current_record = resp.result.result1[0];
        this.project.project_id = resp.result.result1[0].project_id;
        this.project.project_title = resp.result.result1[0].project_title;
        if(resp.result.result1[0].type_of_edit==1)
        this.project.type_of_edit = 'Documentary';
        else if(resp.result.result1[0].type_of_edit==2)
        this.project.type_of_edit = 'Promotional video';
        else if(resp.result.result1[0].type_of_edit==3)
        this.project.type_of_edit = 'Youtube Video';
        else if(resp.result.result1[0].type_of_edit==4)
        this.project.type_of_edit = 'Social Media (FB/Twitter / Insta / Tiktok etc)';
        else if(resp.result.result1[0].type_of_edit==5)
        this.project.type_of_edit = 'Drama';
        else if(resp.result.result1[0].type_of_edit==6)
        this.project.type_of_edit = 'Advertisement';
        else if(resp.result.result1[0].type_of_edit==7)
        this.project.type_of_edit = 'Family/Occasion';
        else if(resp.result.result1[0].type_of_edit==8)
        this.project.type_of_edit = 'Others'+' - '+resp.result.result1[0].if_type_of_edit_others;
        
        
        this.project.duration_final_video = resp.result.result1[0].duration_final_video;
        if(resp.result.result1[0].duration_final_video==1)
        this.project.duration_final_video_value = resp.result.result1[0].duration_final_video_hour+'/'+resp.result.result1[0].duration_final_video_minutes+'/'+resp.result.result1[0].duration_final_video_seconds+ ' (Hour/Minutes/Seconds) ';
        else
        this.project.duration_final_video_value ='No limitation';


        this.project.footage_duration = resp.result.result1[0].footage_duration_hour+'/'+resp.result.result1[0].footage_duration_minutes+'/'+resp.result.result1[0].footage_duration_seconds+ ' (Hour/Minutes/Seconds) ';
        if(resp.result.result1[0].purpose_of_final_video==1)
        this.project.purpose_of_final_video = 'Broadcast';
        else if(resp.result.result1[0].purpose_of_final_video==2)
        this.project.purpose_of_final_video = 'Web';
        else if(resp.result.result1[0].purpose_of_final_video==3)
        this.project.purpose_of_final_video = 'Social Media';
        else if(resp.result.result1[0].purpose_of_final_video==4)
        this.project.purpose_of_final_video = 'Personal ';


        if(resp.result.result1[0].final_video_format==1)
        this.project.final_video_format = '2K';
        else if(resp.result.result1[0].final_video_format==2)
        this.project.final_video_format = '4K';
        

        this.project.final_video_date = resp.result.result1[0].final_video_date;
        this.project.comments = resp.result.result1[0].comments;
        this.project.status = resp.result.result1[0].status;

        this.project.is_url_shared = resp.result.result1[0].is_url_shared;
        this.project.video_url = resp.result.result1[0].video_url;
        this.project.video_password = resp.result.result1[0].video_password;

        this.project.project_opening_title = resp.result.result1[0].project_opening_title_option==1?resp.result.result1[0].project_opening_title:'No Thanks';

        this.project.project_other_text_option = resp.result.result1[0].project_other_text_option==1?resp.result.result1[0].project_other_text:'No Thanks';

        this.project.project_closing_title = resp.result.result1[0].project_closing_title_option==1?resp.result.result1[0].project_closing_title:'No Thanks';

        this.project.project_other_text = resp.result.result1[0].project_other_text_option==1?resp.result.result1[0].project_other_text:'No Thanks';


        //resp.result.result1[0].project_other_text;

        this.project.project_additional = resp.result.result1[0].project_additional;

        this.project.project_music_selection = resp.result.result1[0].project_music_selection;

        if(this.project.project_music_selection=='1')
          this.music_selection = 'Choose from EdiTube music';
        else if(this.project.project_music_selection=='2')
          this.music_selection = 'Using own Music';
        else if(this.project.project_music_selection=='3')
          this.music_selection = 'EdiTube chooses music';
        else if(this.project.project_music_selection=='4')
          this.music_selection = 'No music required';


        this.project.project_music_file = resp.result.result1[0].project_music_file;
      }
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  stepFourRecords(this){
  this.ngxService.start(); 
    this.httpService.stepFourRecords(this).subscribe((resp: any) => {
      //console.log(resp);
      this.ngxService.stop(); 
      if(resp.result!='' ){
        this.userService.showSuccess('Forms submitted successfully');
        this.router.navigate(['/video-final/'+this.project_id]);
      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  videoImage(this){
  this.ngxService.start(); 
    this.httpService.videoImage(this).subscribe((resp: any) => {
      //console.log(resp);
      this.ngxService.stop(); 
      if(resp.result!='' ){
        this.total_video = resp.result.total_video.length;
        this.total_image = resp.result.total_image.length;
        this.gallery_audio = resp.result.total_audio;
        this.gallery_all = resp.result.all_records;

        for (let j = 0; j < this.gallery_all.length; j++) {
        
          if(this.gallery_all[j].gallery_video_min!='' && this.gallery_all[j].gallery_video_min!=0)
            
            if(this.gallery_all[j].gallery_video_min!='' && this.gallery_all[j].gallery_video_min!=0)
            
            //alert(this.gallery_all[j].gallery_video_min);
            this.current_min =  Number(this.current_min) + Number(this.gallery_all[j].gallery_video_min);

            this.current_size = (this.current_size) +  (this.gallery_all[j].gallery_file_size);
        }

        if(this.total_min!='')
          this.total_min = this.current_min/60;

        if(this.current_size!='')
          this.total_size = (this.current_size/(this.tablenames.mb_size * this.tablenames.mb_size));
        
        if(this.current_min!=''){
          
          this.total_video_min = this.httpService.secondsToTime(this.current_min);
        }
      
      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  

  
  ngOnInit() { 
    
    this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });
    
    this.member_id = localStorage.getItem('member_id');
    this.stepOneCheck();

    this.step1_url = '/#/new-project/'+this.project_id;
    this.step2_url = '/#/step-two/'+this.project_id;
    this.step3_url = '/#/video-step-two/'+this.project_id;
    this.step5_url = '/#/video-final/'+this.project_id;
    
    
    //this.project_id_decrypt = this.httpService.decryptNew(this.project_id);
    

    /* MEMBER CHECK */
    this.httpService.checkUserExists(this.member_id).subscribe((resp: any) => {
      //console.log('CHECK EXISTSSSSSSSSSSSS');
      if(resp.result!=''  && typeof resp.result[0]['member_id']!='undefined'){
        //console.log('INSIDE');
        ////console.log(resp);
      }else{
        //console.log('LOGOUT PLZ');
        this.httpService.logoutUser();
        window.location.href='/#/index';
      }
    }, (errorResponse: HttpErrorResponse) => {
          
          let a = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(a) ;
    });
    

    //this.videoImage();

  }

}
