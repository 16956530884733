import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  records = '';
  sample_videos = '';
  errorMsg = '';
  query = '';
  table = '';
  video_limit ='';
  title8 ='';
  desc8 = '';

  title9 ='';
  desc9 = '';

  title10 ='';
  desc10 = '';

  title11 ='';
  desc11 = '';

  title12 ='';
  desc12 = '';

  title13 ='';
  desc13 = '';

  title14 ='';
  desc14 = '';

  setting = {
    new_user_promocode:'',
    new_user_discount_percentage:''
  };
  
  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService) { }

  settings(){
    
    this.httpService.settings(this)
      .subscribe((resp: any) => {
      if(resp.result!=''){
        //console.log('SEEINGS');
        //console.log(resp);
        resp = resp.result.records[0];
        this.setting.new_user_promocode = resp.new_user_promocode;
        this.setting.new_user_discount_percentage = resp.new_user_discount_percentage;


      }
    }, (errorResponse: HttpErrorResponse) => {
       
        
    });
  }

  ngOnInit() { 	
    this.table = this.tablenames.cms;

    this.query = 'SELECT * FROM '+this.table+' WHERE 1=1 AND cms_id BETWEEN 1 AND 20 '; 
          
    this.httpService.commonFetch(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.records = resp.result;
      this.title8 = resp.result[8].cms_title;
      this.desc8 = resp.result[8].cms_desc1;

      this.title9 = resp.result[9].cms_title;
      this.desc9 = resp.result[9].cms_desc1;

      this.title10 = resp.result[10].cms_title;
      this.desc10 = resp.result[10].cms_desc1;

      this.title11 = resp.result[11].cms_title;
      this.desc11 = resp.result[11].cms_desc1;

      this.title12 = resp.result[12].cms_title;
      this.desc12 = resp.result[12].cms_desc1;

      this.title13 = resp.result[13].cms_title;
      this.desc13 = resp.result[13].cms_desc1;

      this.title14 = resp.result[14].cms_title;
      this.desc14 = resp.result[14].cms_desc1;
    }, (errorResponse: HttpErrorResponse) => {
          //console.log(errorResponse);
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
    });

    this.video_limit = '3';
    
    this.httpService.videoCategory(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.sample_videos = resp.result;
    }, (errorResponse: HttpErrorResponse) => {
          //console.log(errorResponse);
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
    });
    

    this.settings();
  }

}
