import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { ToastrManager } from 'ng6-toastr-notifications';
import {ActivatedRoute} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-mapprove-view',
  templateUrl: './mapprove-view.component.html',
  styleUrls: ['./mdashboard.component.scss']
})

export class MapproveViewComponent implements OnInit {
  project_id = '';
  is_completed = '';
  final_file = '';
  member_id = '';
  query = '';
  server_url = '';
  job_records = '';
  member_records = '';
  request_project_id = '';
  request_message ='';
  assign_id = '';
  job_no = 0;
  as_p='';
  gallery = '';
  image = '';
  request_list = '';
  uploaded_status = '';
  request_complete = '';
  final_file_request = '';
  constructor(public toastr: ToastrManager,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService,private ngxService: NgxUiLoaderService,private route:ActivatedRoute) { }
  
  selectedFile: File;
  
  /* For image upload */
  public uploader:FileUploader = new FileUploader({
    isHTML5: true,maxFileSize: 1024*1024*1024
  });

  
  editor ={
    editor_id:'',
    editor_name:'',
    editor_email:'',
    editor_mobiel:'',
    created_date:''
  }

  project ={
    project_id:'',
    project_title:'',
    project_style:'',
    project_space_speed:'',
    project_music_selection:'',
    project_music_file:'',
    project_video_duration:'',
    project_opening_title:'',
    project_closing_title:'',
    project_other_text:'',
    project_opening_title_option:'',
    project_closing_title_option:'',
    project_other_text_option:'',
    project_additional:'',
    style_name:'',
    speed_name:'',
    project_member_id:''
  }
  s3url = '';
  thumb_url = '';
  submit_project={
    pr_comments_by_editor:'',
    pr_status_by_editor:'',
    pr_file:'',
  }

  final_output_file = '';
  final_pr_id = '';

  request_output_file = '';
  request_pr_id = '';

  project_status_by_pm = '';


  viewJobs(ass_id){
    let s = ass_id.toString();
    s = this.httpService.encryptNew(s);
    this.router.navigate(['/approve-view/'+s]);
  } 

  addProject(){
    this.submit_project.pr_comments_by_editor ='';
    this.submit_project.pr_status_by_editor ='';
    this.submit_project.pr_file ='';
    $('#submit-project-popup').modal('show');
  }

  uploadFile(data: FormData) {
    return this.httpClient.post(this.tablenames.image_upload, data);
  }

  uploadFiles(){
    let data = new FormData();
    let fileItem = this.uploader.queue[0]._file;
    data.append('photo', fileItem);
    this.ngxService.start(); 
    this.uploadFile(data).subscribe((resp: any) => {
      if(resp.result=='success' )
        this.submit_project.pr_file = resp.filename;
      else if(resp.result=='')
        this.userService.showError( resp.message);

      this.ngxService.stop(); 
          
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop(); 
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  allocateRecords(this){
    this.ngxService.start();
      
      /*this.query = 'SELECT manager_name,manager_email,manager_mobile,project_title,payment_amount,payment_discount,payment_net_amount,payment_status,project_live_status,'+this.tablenames.project+'.created_date,project_id,manager_id FROM '+this.tablenames.project+' JOIN '+this.tablenames.manager+' ON manager_id = project_manager_id JOIN '+this.tablenames.project_payment+' ON payment_project_id = project_id WHERE 1=1  AND project_id = '+this.httpService.decryptNew(this.project_id)+'  ';  
      */

      this.httpService.allocateRecords(this)
        .subscribe((resp: any) => {
        


        this.member_records = resp.result[0];
        //this.job_records.assign_project_id = resp.result[0].project_id;
        //this.project_status_by_pm = this.member_records.project_status_by_pm;
        //console.log(resp.result);

        this.projectDetails()
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  finalVideoDelete(pr_id){

    if(pr_id!='' && typeof pr_id!='undefined')
    {
      if(confirm('Are you sure want to delete?')){
        this.final_pr_id = pr_id; 
        this.httpService.finalVideoDelete(this)
          .subscribe((resp: any) => {
          this.ngxService.stop();
          if(resp.result!=''){
            alert('Final output file is deleted');
            window.location.reload();
          }else{
            this.userService.showError(JSON.stringify(resp.message)) ;
          }
          
        }, (errorResponse: HttpErrorResponse) => {
            this.ngxService.stop();
            let isError = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again'
            alert(isError) ;
        });
      }
    }
  }

  finalrequestVideoDelete(){
    if(confirm('Are you sure want to delete?')){
        this.httpService.finalrequestVideoDelete(this)
          .subscribe((resp: any) => {
          this.ngxService.stop();
          if(resp.result!=''){
            alert('Re-edited final output file is deleted');
            window.location.reload();
          }else{
            this.userService.showError(JSON.stringify(resp.message)) ;
          }
          
        }, (errorResponse: HttpErrorResponse) => {
            this.ngxService.stop();
            let isError = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again'
            alert(isError) ;
        });
      }
  }

  projectStatus(this){
    /*this.query = 'SELECT * FROM '+this.tablenames.project_submit_editor+' WHERE 1=1  AND pr_project_id = '+this.member_records.project_id+' AND pr_status_by_pm = 1 ORDER BY pr_id DESC LIMIT 1'; */
    //console.log(this.query);
    this.httpService.projectStatus(this)
      .subscribe((resp: any) => {
      if(resp.result[0]!=''){
        this.uploaded_status = resp.result[0];
        this.final_output_file  = resp.result[0].pr_file;
        this.is_completed =  resp.result[0].pr_id!=''?resp.result[0].pr_id:'';
        if(resp.result[0].file_delete_by_member=='0'){
          this.final_file = this.s3url+'assets/project-output/'+resp.result[0].pr_file;
        }
        else{

        }
      }
      
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        alert(isError) ;
    }); 
  }

  downloadServerFiles(pr_id){
    this.as_p = pr_id;
    this.as_p = this.as_p.toString(); 
    if(this.as_p!='' && typeof this.as_p!='undefined'){
    this.ngxService.start();
      this.httpService.downloadServerFilesMember(this.as_p)
        .subscribe((resp: any) => {
        if(resp.result.result=='success'){
          this.userService.showSuccess('File is ready to download, please enable if pop-up is blocked in browswer ') ;
          location.href =this.server_url+'assets/project-output/'+this.as_p+'.zip';
          
        }else{
          this.userService.showError(resp.result.message) ;
        }
        
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
    }
  }

  /* Project Details */
    projectDetails(this){
      let p  = this.httpService.decryptNew(this.project_id);
      this.httpService.projectRecord(p)
          .subscribe((resp: any) => {
            //console.log(resp);
            if(resp.result[0]!='' ){
              resp = resp.result[0];
              /* Assign value to field */
              this.project.project_id = resp.project_id;
              this.project.project_title = resp.project_title;
              this.project.project_style = resp.project_style;
              this.project.project_space_speed = resp.project_space_speed;
              this.project.project_music_selection = resp.project_music_selection;
              this.project.project_music_file = resp.project_music_file;
              this.project.project_video_duration = resp.project_video_duration;
              this.project.project_opening_title = resp.project_opening_title;
              this.project.project_closing_title = resp.project_closing_title;
              this.project.project_other_text = resp.project_other_text;
              this.project.project_opening_title_option = resp.project_opening_title_option;
              this.project.project_closing_title_option = resp.project_closing_title_option;
              this.project.project_other_text_option = resp.project_other_text_option;
              this.project.project_additional = resp.project_additional;
              this.project.style_name= resp.style_name;
              this.project.speed_name= resp.speed_name;
              this.project.project_member_id= resp.project_member_id;
              this.galleryRecords(this);
              this.projectStatus();
              this.requestCompletedStatus();
              this.requestForList();
          }else{
              this.isError = true;
              this.errorMsg = 'Record is not available, please logout and login';
            }
        }, (errorResponse: HttpErrorResponse) => {
             // console.log(errorResponse);
              this.errorMsg = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again';
        });
    }
  downloadRequestFile(obj){
    let files = obj.srcElement.attributes.files.value;
    location.href =this.server_url+'assets/request/'+files;
  }
  

  galleryRecords(this){
    this.ngxService.start();
    /* Query for gallery records */
    //this.query = 'SELECT * FROM '+this.tablenames.project_gallery+' WHERE 1=1  AND gallery_project_id = '+this.member_records.project_id; 
    this.httpService.galleryRecords(this)
      .subscribe((resp: any) => {
      this.gallery = resp.result;
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        alert(isError) ;
    });
  }
  
  requestForList(){
    this.httpService.requestForList(this)
        .subscribe((resp: any) => {
        //console.log('REQUEST');
        //console.log(resp);
        if(resp.result==''){
          //alert(resp.result);
        }else if(resp.result!=''){

          this.request_list = resp.result;
          //console.log(resp);
        }
        
    }, (errorResponse: HttpErrorResponse) => {
          let a  = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
              alert(a);
    });
  }



  projectRequestPopup(pro_id){
    this.request_project_id = pro_id;
    $('#request-new-edit').modal('show');
  }
  requestProjectEdit(this){
    //console.log(this);
    this.ngxService.start();
    this.httpService.requestProjectEdit(this)
      .subscribe((resp: any) => {
      if(resp.result==''){
        this.userService.showError(resp.message) ;
      }else{
         alert('New request submitted successfully');
         window.location.reload();
      }
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(isError) ;
    });

    
  }

  requestCompletedStatus(){
    this.ngxService.stop();
    
    //this.query = 'SELECT * FROM '+this.tablenames.request_pm_editor+' WHERE 1=1  AND request_project_id = '+this.project.project_id+' AND request_project_status_by_pm =1 '; 
    //console.log(this.query);
    
      this.httpService.requestCompletedStatus(this)
        .subscribe((resp: any) => {
        //console.log('PM editor');
        //console.log(resp);

        if(resp.result!=''){
         
          this.request_complete = resp.result[0];
          this.request_pr_id = resp.result[0].request_id;
          if(resp.result[0].file_delete_by_member!='1'){
            this.request_output_file  = resp.result[0].request_file;
            this.final_file_request = this.s3url+'assets/project-output/'+resp.result[0].request_file;
          }
          
        }
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          alert(isError) ;
      });
  }

  ngOnInit() { 	

    this.member_id = localStorage.getItem('member_id');
    this.s3url = this.tablenames.s3url;
    this.thumb_url = this.tablenames.thumb_url;
    this.server_url = this.tablenames.server_url;
    
    this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });
    if(this.project_id!=''){
      this.allocateRecords();
    }
  }

}
