import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { ToastrManager } from 'ng6-toastr-notifications';

import { NgxUiLoaderService } from 'ngx-ui-loader';

//18.219.7.2
const URL = 'https://www.editubepro.com:3000/api/upload';
@Component({
  selector: 'app-msettings',
  templateUrl: './msettings.component.html',
  styleUrls: ['./msettings.component.scss']
})

export class MsettingsComponent implements OnInit {
  id: any;
  
  ins_value ='';
  query = '';
  isError = '';
  update_start = '';
  update_end = '';
  data = '';
  header = '';
  table='';
  primary_id='';
  update_field='';
  folder_name = '';
  errorMsg = '';
  successMsg = '';
  submitBtnTxt = 'Submit';
  today :any;
  day :string;
  msettingserrorMsg ='';
  where_id ='';
  changeerrorMsg = '';
  changesuccessMsg = '';

  member_id ='';
  username = '';
  password = '';
  
  tmp_upload = '';
  image = '';
  img_upload = '';
  designation_folder = '';
  server_url = '';
  selectedFile: File;
  s3url = '';
  /* For image upload */
  //public uploader: FileUploader = new FileUploader({url: URL, itemAlias: 'photo'});
  //allowedMimeType: ['image/jpeg','image/jpg','image/png','image/gif'],
  public uploader:FileUploader = new FileUploader({ itemAlias: 'photo',
    isHTML5: true,
    allowedMimeType: ['image/jpeg','image/jpg','image/png','image/gif'],
    maxFileSize: 1024*1024
  });

  member = {
    member_first_name:'',
    member_last_name:'',
    member_email:'',
    member_mobile:'',
    member_country_code:'',
    member_image:'',
    member_id :'',
    current_password:'',
    member_password:'',
    confirm_password:'',
    member_address:'',
  };

  changepwd= {
    current_pwd:'',
    new_pwd:'',
    confirm_pwd:''
  }
  country_records = '';

  constructor(public toastr: ToastrManager,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService,private ngxService: NgxUiLoaderService) { 

  }

  countryCode(){
    this.query = 'SELECT * FROM '+this.tablenames.country_code_table+' WHERE 1=1 ORDER BY country_name'; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.country_records = resp.result;
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  
  uploadFile(data: FormData) {
    return this.httpClient.post(this.tablenames.upload_url, data);
  }

  uploadSubmit(){
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      //console.log(fileItem);
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.jpeg|\.JPG|\.JPEG|\.PNG|\.GIF)$/i;
      
      if(!allowedExtensions.exec(fileItem.name)){
          //console.log('INVALID');
          this.userService.showError('Please upload file having extensions png,jpg,gif,jpeg only.');
          this.ngxService.stop();
          return false;
      }else{
        //this.ngxService.start();
      }
      /*if(fileItem.size > (1024 * 1024 * 1024*1024*1024)){
        alert("Each File should be less than 10 MB of size.");
        return;
      }*/
    }

    let r = 0;

    let j = 0;
    
    for (j = 0; j < this.uploader.queue.length; j++) {
      
      //console.log('INSIDE');
      let data = new FormData();
      let fileItem = this.uploader.queue[j]._file;
      //var extension = fileItem.split('.').pop();
      ////console.log(extension);
      data.append('photo', fileItem);
      
      //console.log(data);
      this.uploadFile(data).subscribe((resp: any) => {
        //console.log(resp);
        r++;
        if(resp.result=='success' ){
          this.image = resp.filename;
          if(this.image!='' && typeof this.image!='undefined' &&  this.member_id!='' && typeof this.member_id!='undefined' ){
            
            this.query = 'UPDATE '+this.table+' SET member_image = "'+this.image+'" WHERE 1=1 AND member_id = '+this.member_id; 
              this.img_upload ='1';
              this.httpService.commonFetch2(this)
                .subscribe((resp: any) => {
                  //console.log(resp);
                  if(resp.result[0]!='' ){
                      
                      window.location.reload();
                      //resp = resp.result;
                      this.member.member_image = this.image;

                      this.image = '';

                }else{
                    this.userService.showError('Id is not available, edit only able to upload image');
                  }
              }, (errorResponse: HttpErrorResponse) => {
                    this.isError = errorResponse.error ? errorResponse.error.message
                        : 'Sorry, unable to complete your request, please refresh this page and try again';
                    this.userService.showError(this.isError);
              });
          }
        }
        else if(resp.result=='error')
          this.userService.showError(JSON.stringify(resp.message));
      }, (errorResponse: HttpErrorResponse) => {
        //console.log(errorResponse);
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(errorResponse) ;
      });
    

      
    }
    this.uploader.clearQueue();
  }

  submitMemberProfile(){
      //console.log('TESTING..........');
      //console.log(this.member);
      //console.log('PROFILE');
      this.today = Date();
      this.day = moment(this.today).format('YYYY-MM-DD HH:MM:SS');
      this.submitBtnTxt = 'Register';
      this.submitBtnTxt = 'Please wait...';
      
      var address_len = this.member.member_address!='' && this.member.member_address!=null?(this.member.member_address).length:0;

      var mobile = (this.member.member_mobile).length;
      var m = this.member.member_mobile;
      var c = this.member.member_country_code;

      if(this.member.member_first_name==''){
        this.userService.showError('Enter member first name'); 
      }
      else if(this.member.member_last_name==''){
        this.userService.showError('Enter member last name'); 
      }
      else if(this.member.member_last_name==''){
        this.userService.showError('Enter member last name'); 
      }
      else if(this.member.member_country_code!='' && this.member.member_mobile=='')
        this.userService.showError('Enter mobile number'); 
      else if(this.member.member_country_code=='' && this.member.member_mobile!='')
        this.userService.showError('Select country code');  
      else if(this.member.member_mobile!='' && mobile<7)
        this.userService.showError('Mobile number minimum 7 to maximum 15 digit allowed');  
      else if(this.member.member_mobile!='' && mobile>15)
        this.userService.showError('Mobile number minimum 7 to maximum 15 digit allowed');  
      else if(address_len!=0 && address_len<3){
        this.userService.showError('Address minimum 3 characters');
        return false;
      }
      else if(address_len!=0 && address_len>250){
        this.userService.showError('Address maximum 250 characters');
        return false;
      }else if(m!='' && c==''){
        this.userService.showError('Select country code');
        return false;
      }else{
        
                
        /* insert or updation */
        this.httpService.memberProfile(this)
        .subscribe((resps: any) => {
          //console.log(resps);
            if(resps.result.affectedRows==1)
              this.userService.showSuccess('Profile updated successfully');
            else if(resps.message.code!='')
              this.userService.showError(resps.message.sqlMessage);
            
          }, (errorResponse: HttpErrorResponse) => {
              this.isError = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again'
              this.userService.showError(this.isError);
        });
      }

      
  }

  /* Change password */
  changePassword(){

    //console.log('change');

    if(this.member.current_password==this.member.member_password){
      this.userService.showError('Current and member password is same');
    }
    else if(this.member.current_password!='' && typeof this.member.current_password!='undefined' && this.member.member_password!='' && typeof this.member.member_password!='undefined' && this.member.confirm_password!='' && typeof this.member.confirm_password!='undefined' ){
      let p = this.member.member_password;
      if(p.length<6)
        this.userService.showError('Password should be minmum 6 character');
      else if(p.length>30)
        this.userService.showError('Password should be maximum 30 character');
      else if(this.member.member_password==this.member.confirm_password){
        this.httpService.changePwdMember(this)
          .subscribe((resp: any) => {
            resp = resp.result;
            if(resp.result=='error' ){
              this.userService.showError(resp.message);
            }else if(resp.result=='success')
              this.userService.showSuccess(resp.message);
            
            (<HTMLFormElement>document.getElementById("change_password_form")).reset();
        }, (errorResponse: HttpErrorResponse) => {
              this.isError = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again';
              this.userService.showError(this.isError);
        });
      }else{
        this.userService.showError('Password and confirm password is not matched');
      }
    }
  }

  
  

  

  ngOnInit() {  
    this.s3url = this.tablenames.s3url;
    this.countryCode();
    this.successMsg ='';
    this.changeerrorMsg = '';
    this.changesuccessMsg ='';
    this.member_id  = localStorage.getItem('member_id');
    this.member.member_id  = this.member_id;
    this.query = 'SELECT * FROM '+this.tablenames.member+' WHERE 1=1 AND member_status = 1 AND member_id = '+this.member_id;
    
    this.table = this.tablenames.member;
    this.where_id = 'member_id';
    this.update_field = 'member_image';
    this.primary_id = 'member_id';
    this.folder_name = 'member';
    this.designation_folder = 'member';
    this.img_upload = '0';
    this.server_url = this.tablenames.server_url;
    

    this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
      //console.log('***** onWhenAddingFileFailed ********')
      $('#member_image').val('');
      alert('Allowed only jpg,png,jpeg,gif and 1 MB size only allowed to upload');

    }

    /* Files upload 
      this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          if(status==200){
            this.image = response;
            if(this.image!='' && typeof this.image!='undefined' &&  this.member_id!='' && typeof this.member_id!='undefined' ){
              
              this.query = 'UPDATE '+this.table+' SET member_image = "'+this.image+'" WHERE 1=1 AND member_id = '+this.member_id; 
                this.img_upload ='1';
                this.httpService.commonFetch2(this)
                  .subscribe((resp: any) => {
                    window.location.reload();
                    //console.log(resp);
                    if(resp.result[0]!='' ){
                        //this.image = '';
                        
                        //resp = resp.result;
                        this.member.member_image = this.image;


                      
                  }else{
                      this.userService.showError('Id is not available, edit only able to upload image');
                    }
                }, (errorResponse: HttpErrorResponse) => {
                      this.isError = errorResponse.error ? errorResponse.error.message
                          : 'Sorry, unable to complete your request, please refresh this page and try again';
                      this.userService.showError(this.isError);
                });
            }
          }
          else{
            alert('Have some problem to upload files, please refresh and try again');

          }
    };
    */

    this.httpService.commonFetch2(this)
        .subscribe((resp: any) => {
          //console.log(resp);
          if(resp.result[0]!='' ){
            resp = resp.result[0];
            if(resp!='' && typeof resp!='undefined'){
              this.member.member_first_name = resp.member_first_name!='' && resp.member_first_name!='null'?resp.member_first_name:'';
              this.member.member_last_name = resp.member_last_name!='' && resp.member_last_name!='null'?resp.member_last_name:'';
              this.member.member_mobile = resp.member_mobile!='' && resp.member_mobile!='null'?resp.member_mobile:'';
              this.member.member_country_code = resp.member_country_code;
              this.member.member_email = resp.member_email;
              this.member.member_image = resp.member_image;
              this.member.member_address = resp.member_address!='' && resp.member_address!='null'?resp.member_address:'';
              //alert(this.member.member_image);
              ////console.log(this.s3url+'assets/member/'+this.member.member_image);
            }else
              this.userService.showError('Records are not available');
          }else
            this.userService.showError('Records are not available');
          
      }, (errorResponse: HttpErrorResponse) => {
            this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(this.isError) ;
      });
  }


}
