import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
//import { MustMatch } from './must-match.validator';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  @ViewChild('recaptcha', {static: true } as any) recaptchaElement: ElementRef;

  id: any;
  ins_value ='';
  query = '';
  isError = false;
  update_start = '';
  update_end = '';
  data = '';
  header = '';
  table='';
  primary_id='';
  errorMsg = '';
  successMsg = '';
  submitBtnTxt = 'Register';
  today :any;
  day :string;
  loginerrorMsg ='';
  login_query = '';
  username = '';
  password = '';
  captcha_response = '';
  member = {
    member_first_name:'',
    member_last_name:'',
    member_email:'',
    member_mobile:'',
    member_country_code:'',
    member_password:'',
    confirm_password:''
  };

  login= {
    login_username:'',
    login_password:'',
    login_terms:''
  }

  forgot_email = '';
  forgot_type='member';
  country_records = '';

  emailPattern  = '';

  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService,private ngxService:NgxUiLoaderService) { }

  /* Recaptcha Start */
  expireCaptcha(r:any){
    //console.log('response',r);
    this.captcha_response = '';
  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LfQ_dsUAAAAAAhV3fN8kG8x7-nxtD1yHIVZHP91',
      'expired-callback': this.expireCaptcha.bind(this),
      'callback': (response) => {
        if(typeof response!='undefined' && response!=''){
          this.captcha_response = response;
          
        }
        else{
          this.captcha_response = '';
        }
        //console.log('captcha check')
        //console.log(response);
        //this.captcha_response = response;
      }
    });
  }
 
  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
 
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
 
  }
  /* Recaptcha End */

  /* Check alphanumeric */
  isAlphaNumeric(str) {
    //console.log(str);
    var code = 0;
    var i = 0; 
    var len = 0;
    //console.log(str.length);
    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      //console.log(code);
      if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
        //console.log('LOOP FALSE');
        return false;
      }
    }
    return true;
  }

  checkLoginExist(){
    let id = (localStorage.getItem('member_id'));

    if(id!='' && typeof id!='undefined'){
      window.location.href="/#/index";
    }else {
      let e_id = (localStorage.getItem('editor_id'));
      if(e_id!='' && typeof e_id!='undefined'){
        window.location.href="/#/index"; 
      }  
    }
  }

  isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  isNumber(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  /* Member registeration  */
  submitMember(){
    
      let p = this.member.member_password;

      var fn = (this.member.member_first_name).length;
      var ln = (this.member.member_last_name).length;
      var email = (this.member.member_email).length;
      var mobile = (this.member.member_mobile).length;

      if(this.member.member_first_name=='')
        this.userService.showError('Enter first name');
      else if(fn<3)
        this.userService.showError('First name minimum 3 to maximum 25 character allowed');
      else if(fn>25)
        this.userService.showError('First name minimum 3 to maximum 25 character allowed');
      else if(this.member.member_last_name=='')
        this.userService.showError('Enter last name');
      else if(ln<3)
        this.userService.showError('Last name minimum 3 to maximum 25 character allowed');
      else if(ln>25)
        this.userService.showError('Last name minimum 3 to maximum 25 character allowed');
      else if(this.member.member_email=='')
        this.userService.showError('Enter email id');
      else if(!this.userService.isEmail(this.member.member_email))
        this.userService.showError('Enter valid email id');
      else if(this.member.member_email!='' && email>80)
        this.userService.showError('Email id minimum 10 to maximum 80 character allwoed');
      else if(this.member.member_country_code!='' && this.member.member_mobile=='')
        this.userService.showError('Enter mobile number'); 
      else if(this.member.member_country_code!='' && /[^0-9]/.test(this.member.member_mobile))
        this.userService.showError('Enter number only in mobile field.');

      else if(this.member.member_country_code=='' && this.member.member_mobile!='')
        this.userService.showError('Select country code');  
      /*else if(this.member.member_mobile=='')
        this.userService.showError('Enter mobile number'); 
      */
      else if(this.member.member_mobile!='' && mobile<7)
        this.userService.showError('Mobile number minimum 7 to maximum 15 digit allowed');  
      else if(this.member.member_mobile!='' && mobile>15)
        this.userService.showError('Mobile number minimum 7 to maximum 15 digit allowed');  
      else if(this.member.member_password=='')
        this.userService.showError('Enter password');
      else if(this.member.confirm_password=='')
        this.userService.showError('Enter confirm password');
      else if(p.length<6)
        this.userService.showError('Password should be a combination of alphanumeric and special characters with min 6 to max 30 characters.');
      else if(p.length>30)
        this.userService.showError('Password should be a combination of alphanumeric and special characters with min 6 to max 30 characters.');
      else if(!/[^a-zA-Z0-9]/.test(p))
        this.userService.showError('Password should be a combination of alphanumeric and special characters.');
      else if(this.member.member_password!=this.member.confirm_password)
        this.userService.showError('Password and confirm password is not matched');
      else if(this.login.login_terms=='')
        this.userService.showError('Please agree terms and condition');
      /*else if( typeof this.captcha_response=='undefined' ||  this.captcha_response=='')
        this.userService.showError('Please check on the reCAPTCHA box');*/
      else{
        this.today = Date();
      //this.today = 'dd-MM-yyyy hh:mm:ss';
      //console.log(this.today);
      this.day = moment(this.today).format('YYYY-MM-DD HH:MM:SS');
      this.table = this.tablenames.member;
      this.submitBtnTxt = 'Register';
      this.submitBtnTxt = 'Please wait...';
      
      /* Registeration query */
      this.query = 'INSERT INTO '+this.table+' (member_first_name,member_last_name,member_email,member_mobile,member_password,created_date,member_country_code) VALUE("'+this.member.member_first_name+'","'+this.member.member_last_name+'","'+this.member.member_email+'","'+this.member.member_mobile+'","'+this.member.member_password+'","'+this.day+'","'+this.member.member_country_code+'")';

      this.ngxService.start();
      /* Service calling 
          else if(this.member.member_mobile!='' && resps.result.mobile>0){
            this.userService.showError('Mobile number already exists');
            this.submitBtnTxt = 'Register';
          }
      */
        /* insert or updation */
        this.httpService.memberCheck(this)
        .subscribe((resps: any) => {
          this.ngxService.stop();
          if(resps.result.email>0){
            this.userService.showError('Email id already exists');
            this.submitBtnTxt = 'Register';
          }else{
            /* insert or updation */
            this.httpService.memberRegister(this)
            .subscribe((resp: any) => {
            this.ngxService.stop();
              this.submitBtnTxt = 'Register';
              if(resp.result!='' && typeof resp.result!='undefined' ){
                this.userService.showSuccess('Thanks for registering with editube. Please login,To activate your account, please click on the activation link emailed to you. If the email is not found in Inbox, Please check in your Junk / Spam folder.');
                
                (<HTMLFormElement>document.getElementById("registerform")).reset();
                //alert('You have registered successfully');
                //window.location.reload();
              }
              else{
                 
                  this.userService.showError(JSON.stringify(resp.message));
              }
            }, (errorResponse: HttpErrorResponse) => {
                    //console.log(errorResponse);
                    this.isError = true;
                    this.errorMsg = errorResponse.error ? errorResponse.error.message
                        : 'Sorry, unable to complete your request, please refresh this page and try again';
              });
            }    
          }, (errorResponse: HttpErrorResponse) => {
              //console.log(errorResponse);
              this.isError = true;
              this.errorMsg = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again';
        });
      }
      //}
  }

  /* FORGOT PASSWORD */
  forgotPassword(){

    if(this.forgot_email!=''){
    this.ngxService.start();

      this.httpService.forgotPassword(this)
        .subscribe((resp: any) => {
          //console.log(resp);
          this.ngxService.stop();
          if(resp.result!='' ){
            this.userService.showSuccess(resp.result.message);
            this.forgot_email = '';  
          }else
            this.userService.showError(JSON.stringify(resp.message));
      }, (errorResponse: HttpErrorResponse) => {
            let a = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again';

            this.userService.showError(a);
      });
    }else{
      this.userService.showError('Please enter your Email-id');
    }
  }

  /* Member login */
  loginMember(){
    this.username = (this.login.login_username).trim();
    this.password = (this.login.login_password).trim();
    //console.log(this.login);
    if(this.login.login_username=='')
      this.userService.showError('Enter your email id');
    else if(this.password==''){
      this.userService.showError('Enter your password');
    }
    else if(this.username!='' && typeof this.username!='undefined' && this.password!='' && typeof this.password!='undefined' ){
    
      this.query = 'SELECT * FROM '+this.tablenames.member+' WHERE 1=1 AND BINARY member_email = BINARY "'+this.username+'" AND BINARY member_password = BINARY "'+this.password+'" AND member_status =1';
      
      this.httpService.memberLogin(this)
        .subscribe((resp: any) => {
          //console.log(resp);
          if(resp.result[0]!='' ){
            resp = resp.result[0];
            //console.log(resp);
            if(resp!='' && typeof resp!='undefined'){
              if(resp.member_email_verify==1){
                /* SUCCESS */
                localStorage.removeItem('admin_id');
                localStorage.removeItem('login_type');
                localStorage.removeItem('editor_id');
                localStorage.removeItem('type');
                

                localStorage.setItem('member_id', resp.member_id);
                localStorage.setItem('type', 'member');
                localStorage.setItem('member_records', JSON.stringify(resp));
                window.location.href =  '#mdashboard';
                location.reload();
              }
              else
                this.userService.showError('Please click on the email link emailed to you. Please check your junk folder also.');
              
            }else
              this.userService.showError('Invalid username and password');
            
          }else
            this.userService.showError('Invalid username and password');
          
      }, (errorResponse: HttpErrorResponse) => {
            this.loginerrorMsg = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again';
      });
    }
  }

  countryCode(){
    this.query = 'SELECT * FROM '+this.tablenames.country_code_table+' WHERE 1=1 ORDER BY country_name '; 
    this.httpService.commonFetch(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.country_records = resp.result;
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  ngOnInit() { 	
    ///this.checkLoginExist();
    this.loginerrorMsg ='';
    this.captcha_response = '';
    this.countryCode();

    this.emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"; 

    var u = localStorage.getItem('member_id');
    //console.log(u);
    
    if(u!='' && typeof u!='undefined' && u!=null ){
      window.location.href =  '/#/mdashboard';
      location.reload();
    }

    this.addRecaptchaScript();

  }

}


/* 
  Local storage

  localStorage.setItem(key, 'Value');

  let myItem = localStorage.getItem(key);

  localStorage.setItem(key, 'New Value');

  localStorage.removeItem(key);

  localStorage.clear();

  // Create item:
  let myObj = { name: 'Skip', breed: 'Labrador' };
  localStorage.setItem(key, JSON.stringify(myObj));

  // Read item:
  let item = JSON.parse(localStorage.getItem(key));

*/