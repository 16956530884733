import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';

@Component({
  selector: 'app-editorlogin',
  templateUrl: './editor-login.component.html',
  styleUrls: ['./editor-login.component.scss']
})

export class EditorLoginComponent implements OnInit {
  id: any;
  ins_value ='';
  query = '';
  isError = false;
  update_start = '';
  update_end = '';
  data = '';
  header = '';
  table='';
  primary_id='';
  errorMsg = '';
  successMsg = '';
  submitBtnTxt = 'Submit';
  today :any;
  day :string;
  loginerrorMsg ='';
  login_query = '';
  username = '';
  password = '';
  editor = {
    editor_name:'',
    editor_email:'',
  };

  login= {
    login_username:'',
    login_password:''
  }

  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService) { }

  /* Editor login */
  loginEditor(){

    this.username = this.login.login_username;
    this.password = this.login.login_password;
    
    if(this.username!='' && typeof this.username!='undefined' && this.password!='' && typeof this.password!='undefined' ){
    
      this.httpService.editorLogin(this)
        .subscribe((resp: any) => {
          //console.log(resp);
          if(resp.result[0]!='' ){
            resp = resp.result[0];

            if(resp!='' && typeof resp!='undefined'){

                localStorage.removeItem('admin_id');
                localStorage.removeItem('login_type');
                localStorage.removeItem('member_id');
                localStorage.removeItem('type');

                /* SUCCESS */
                localStorage.setItem('editor_id', resp.editor_id);
                localStorage.setItem('type', 'editor');
                localStorage.setItem('editor_records', JSON.stringify(resp));
                
                window.location.reload();

                //window.location.href =  '#edashboard';
            }else
              this.loginerrorMsg = 'Invalid username and password';
            
          }else
            this.loginerrorMsg = 'Invalid username and password';
          
      }, (errorResponse: HttpErrorResponse) => {
            this.loginerrorMsg = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again';
      });
    }
  }

  ngOnInit() { 	
    this.loginerrorMsg ='';
    
    if(localStorage.getItem('editor_id')!='' && typeof localStorage.getItem('editor_id')!='undefined'){
      window.location.href =  '#edashboard';
    }


  }

}
