import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { ToastrManager } from 'ng6-toastr-notifications';

import {ActivatedRoute} from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {

  member_id = '';
  query = '';
  server_url = '';
  member_records = '';
  
  constructor(public toastr: ToastrManager,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private userService:UserService,private ngxService: NgxUiLoaderService,private route:ActivatedRoute) { }
  
  viewJobs(ass_id){
    let s = ass_id.toString();
    s = this.httpService.encryptNew(s);
    this.router.navigate(['/job-list-view/'+s]);
  } 

  allocateRecords(this){
    this.ngxService.start();
    
      //this.query = 'SELECT manager_name,manager_email,manager_mobile,project_title,payment_amount,payment_discount,payment_net_amount,payment_status,project_live_status,'+this.tablenames.project_payment+'.created_date,project_id,project_id,project_member_id FROM '+this.tablenames.project+' JOIN '+this.tablenames.manager+' ON manager_id = project_manager_id JOIN '+this.tablenames.project_payment+' ON payment_project_id = project_id WHERE 1=1  AND project_member_id = '+this.member_id+' AND project_live_status!=3 '; 
      this.httpService.joblist(this)
        .subscribe((resp: any) => {
        this.member_records = resp.result;
        //console.log(this.member_records);
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  ngOnInit() { 	
    this.member_id  = localStorage.getItem('member_id');
    this.server_url = this.tablenames.server_url;
    if(this.member_id!=''){
      this.allocateRecords();
    }

    
    
  }


}
