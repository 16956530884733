import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxUiLoaderService } from 'ngx-ui-loader';
//18.219.7.2
const URL = 'http://18.219.7.2:3000/api/upload';
@Component({
  selector: 'app-esettings',
  templateUrl: './esettings.component.html',
  styleUrls: ['./editor-login.component.scss']
})

export class EsettingsComponent implements OnInit {
  id: any;
  
  ins_value ='';
  query = '';
  isError = '';
  update_start = '';
  update_end = '';
  data = '';
  header = '';
  table='';
  primary_id='';
  update_field='';
  folder_name = '';
  errorMsg = '';
  successMsg = '';
  submitBtnTxt = 'Submit';
  today :any;
  day :string;
  msettingserrorMsg ='';
  where_id ='';
  changeerrorMsg = '';
  changesuccessMsg = '';

  editor_id ='';
  username = '';
  password = '';
  
  tmp_upload = '';
  image = '';
  img_upload = '';
  designation_folder = '';
  server_url = '';
  selectedFile: File;
  /* For image upload */
  //public uploader: FileUploader = new FileUploader({url: URL, itemAlias: 'photo'});

  editor = {
    editor_name:'',
    editor_mobile:'',
    editor_address:'',
    editor_image:'',
    editor_id :'',
    editor_gender:'',
    editor_email:'',
    editor_country_code:'',
    editor_country:'',
    editor_city:'',
    editor_password:'',
    current_password:'',
    member_password:'',
    confirm_password:''
  };
  s3url = '';
  changepwd= {
    current_pwd:'',
    new_pwd:'',
    confirm_pwd:''
  }

  public uploader:FileUploader = new FileUploader({ itemAlias: 'photo',
    isHTML5: true,
    allowedMimeType: ['image/jpeg','image/jpg','image/png','image/gif','video/mov','video/mp4','video/quicktime','video/mp4'],
    maxFileSize: 1024*1024
  });

  constructor(public toastr: ToastrManager,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService,private ngxService: NgxUiLoaderService) { 

  }

  uploadFile(data: FormData) {
    
    return this.httpClient.post(this.tablenames.upload_url, data);
  }

  uploadSubmit(){
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      //console.log(fileItem);
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.jpeg|\.JPG|\.JPEG|\.PNG|\.GIF)$/i;
      
      if(!allowedExtensions.exec(fileItem.name)){
          //console.log('INVALID');
          this.userService.showError('Please upload file having extensions png,jpg,gif,jpeg only.');
          this.ngxService.stop();
          return false;
      }else{
        //this.ngxService.start();
      }
      /*if(fileItem.size > (1024 * 1024 * 1024*1024*1024)){
        alert("Each File should be less than 10 MB of size.");
        return;
      }*/
    }

    let r = 0;

    let j = 0;
    
    for (j = 0; j < this.uploader.queue.length; j++) {
      
      //console.log('INSIDE');
      let data = new FormData();
      let fileItem = this.uploader.queue[j]._file;
      //var extension = fileItem.split('.').pop();
      //console.log(extension);
      data.append('photo', fileItem);
      
      //console.log(data);
      this.uploadFile(data).subscribe((resp: any) => {
        //console.log(resp);
        r++;
        if(resp.result=='success' ){
          this.image = resp.filename;
          if(this.image!='' && typeof this.image!='undefined' &&  this.editor_id!='' && typeof this.editor_id!='undefined' ){
              
            this.query = 'UPDATE '+this.table+' SET editor_image = "'+this.image+'" WHERE 1=1 AND editor_id = '+this.editor_id; 
              this.img_upload ='1';
              this.httpService.commonFetch2(this)
                .subscribe((resp: any) => {
                  //console.log(resp);
                  if(resp.result[0]!='' ){
                      window.location.reload();
                      //resp = resp.result;
                      this.editor.editor_image = this.image;
                      this.image = '';
                }else{
                    this.userService.showError('Id is not available, edit only able to upload image');
                  }
              }, (errorResponse: HttpErrorResponse) => {
                    this.isError = errorResponse.error ? errorResponse.error.message
                        : 'Sorry, unable to complete your request, please refresh this page and try again';
                    this.userService.showError(this.isError);
              });
          }
        }
        else if(resp.result=='error')
          this.userService.showError(JSON.stringify(resp.message));
      }, (errorResponse: HttpErrorResponse) => {
        //console.log(errorResponse);
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(errorResponse) ;
      });
    }
    this.uploader.clearQueue();
  }

  submitEditorProfile(){

    //console.log('PROFILE');
      this.today = Date();
      this.day = moment(this.today).format('YYYY-MM-DD HH:MM:SS');
      this.submitBtnTxt = 'Register';
      this.submitBtnTxt = 'Please wait...';
      
      /* Registeration query */
      this.query = 'UPDATE '+this.table+' SET editor_name = "'+this.editor.editor_name+'",editor_address = "'+this.editor.editor_address+'",editor_mobile = "'+this.editor.editor_mobile+'",editor_country_code = "'+this.editor.editor_country_code+'",modified_date = "'+this.day+'",modified_by ="'+this.editor.editor_id+'" WHERE 1=1 AND editor_id = '+this.editor.editor_id;

      /* Service calling */
        /* insert or updation */
        this.httpService.commonFetch2(this)
        .subscribe((resps: any) => {
          //console.log(resps);
            if(resps.result.affectedRows==1)
              this.userService.showSuccess('Profile updated successfully');
            else if(resps.message.code!='')
              this.userService.showError(resps.message.sqlMessage);
            
          }, (errorResponse: HttpErrorResponse) => {
              this.isError = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again'
              this.userService.showError(this.isError);
        });
  }

  /* Change password */
  changePassword(this){
    //console.log('change');
    if(this.editor.current_password!='' && typeof this.editor.current_password!='undefined' && this.editor.editor_password!='' && typeof this.editor.editor_password!='undefined' && this.editor.confirm_password!='' && typeof this.editor.confirm_password!='undefined' ){
      let p = this.editor.editor_password;
      if(p.length<8)
        this.userService.showError('Password should be 8 character');
      else if(!/[^a-zA-Z0-9]/.test(p))
        this.userService.showError('Enter alpha numeric password');
      else if(this.editor.editor_password==this.editor.confirm_password){
        this.httpService.changePwdEditor(this)
          .subscribe((resp: any) => {
            resp = resp.result;
            if(resp.result=='error' ){
              this.userService.showError(resp.message);
            }else if(resp.result=='success')
              this.userService.showSuccess(resp.message);
            
        }, (errorResponse: HttpErrorResponse) => {
              this.isError = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again';
              this.userService.showError(this.isError);
        });
      }else{
        this.userService.showError('Password and confirm password is not matched');
      }
    }
  }
  
  ngOnInit() { 	
    this.s3url = this.tablenames.s3url;
    this.successMsg ='';
    this.changeerrorMsg = '';
    this.changesuccessMsg ='';
    this.editor_id  = localStorage.getItem('editor_id');
    //this.editor.editor_id  = this.editor_id;
    this.query = 'SELECT * FROM '+this.tablenames.editor+' WHERE 1=1 AND editor_status = 1 AND editor_id = '+this.editor_id;
    
    this.table = this.tablenames.editor;
    this.where_id = 'editor_id';
    this.update_field = 'editor_image';
    this.primary_id = 'editor_id';
    this.folder_name = 'editor';
    this.designation_folder = 'editor';
    this.img_upload = '0';
    this.server_url = this.tablenames.server_url;
    
    this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
      //console.log('***** onWhenAddingFileFailed ********')
      $('#editor_image').val('');
      alert('Allowed only jpg,png,jpeg,gif and 1 MB size only allowed to upload');
    }

    /* Files upload 
      this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          if(status==200){
            this.image = response;
            if(this.image!='' && typeof this.image!='undefined' &&  this.editor_id!='' && typeof this.editor_id!='undefined' ){
              
              this.query = 'UPDATE '+this.table+' SET editor_image = "'+this.image+'" WHERE 1=1 AND editor_id = '+this.editor_id; 
                this.img_upload ='1';
                this.httpService.commonFetch2(this)
                  .subscribe((resp: any) => {
                    //console.log(resp);
                    if(resp.result[0]!='' ){
                        //window.location.reload();
                        //resp = resp.result;
                        this.editor.editor_image = this.image;
                  }else{
                      this.userService.showError('Id is not available, edit only able to upload image');
                    }
                }, (errorResponse: HttpErrorResponse) => {
                      this.isError = errorResponse.error ? errorResponse.error.message
                          : 'Sorry, unable to complete your request, please refresh this page and try again';
                      this.userService.showError(this.isError);
                });
            }
          }
          else{
            alert('Have some problem to upload files, please refresh and try again');

          }
    };
    */
    this.httpService.commonFetch2(this)
        .subscribe((resp: any) => {
          //console.log(resp);
          if(resp.result[0]!='' ){
            resp = resp.result[0];
            if(resp!='' && typeof resp!='undefined'){
              this.editor.editor_name = resp.editor_name;
              this.editor.editor_mobile = resp.editor_mobile;
              this.editor.editor_country_code = resp.editor_country_code;
              this.editor.editor_email = resp.editor_email;
              this.editor.editor_image = resp.editor_image;
              this.editor.editor_address = resp.editor_address;
              this.editor.editor_id = resp.editor_id;
            }else
              this.userService.showError('Records are not available');
          }else
            this.userService.showError('Records are not available');
          
      }, (errorResponse: HttpErrorResponse) => {
            this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(this.isError) ;
      });
  }


}
