import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-edashboard',
  templateUrl: './edashboard.component.html',
  styleUrls: ['./editor-login.component.scss']
})

export class EdashboardComponent implements OnInit {
  
	username ='';
  server_url ='';
  editor_records ='';
  editor_image = '';
  table = '';
  query = '';
  s3url = '';
  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private userService:UserService) { }

  ngOnInit() { 	
  	this.s3url = this.tablenames.s3url;
    this.server_url = this.tablenames.server_url;
    let editor_id = (localStorage.getItem('editor_id'));

    this.table = this.tablenames.editor;

    this.query = 'SELECT * FROM '+this.table+' WHERE 1=1 AND editor_id = '+editor_id; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.editor_records = resp.result[0];
      this.username = resp.result[0].editor_name;
      this.editor_image = resp.result[0].editor_image
    }, (errorResponse: HttpErrorResponse) => {
          
          let a  = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(a) ;
    });
  }

}
