import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import {ActivatedRoute} from '@angular/router';

import {FileUploader} from "ng2-file-upload";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";

import { ToastrManager } from 'ng6-toastr-notifications';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class StepTwoComponent implements OnInit {
  records = '';
  errorMsg = '';
  query = '';
  table = '';
  title = '';
  isError = '';
  project_id = '';
  member_id = '';
  no_images = '';
  gallery = '';
  server_url = '';
  load_process = '';
  gallery_table = '';
  primary_id = '';
  project = {
    project_id:'',
    project_title:'',
    project_style:'',
    project_speed:'',
    project_music_selection:'',
    project_music_file:'',
    project_video_duration:'',
  };
  data_id = '';
  style_records = '';
  speed_records = '';
  music_records = '';
  music_category = '';
  editube_music = '';
  editube_music_name = '';
  editube_music_file = ''; 
  existing_music = '';

  step1_url ='';
  step2_url ='';
  step3_url ='';
  check_image_exist ='';
  next_enable = '';
  qry  =   '';
  data_image   =   '';
  s3url='';
  choose_editube_music = '';

  where_field  =   '';
  folder_name = '';

  // 1 GB - 1024 * 1024 * 1024
  uploadForm: FormGroup;
  public uploader:FileUploader = new FileUploader({
    isHTML5: true,
    //allowedMimeType: ['audio/mp3','audio/wav'],
    maxFileSize: 1024 * 1024 * 1024
  });
  
  constructor(private router: Router,public httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private route: ActivatedRoute,public userService:UserService,private fb: FormBuilder,private ngxService: NgxUiLoaderService) { }

  uploadFile(data: FormData) {
    return this.httpClient.post(this.tablenames.upload_music, data);
  }

  deleteVideoImage(obj){

    this.data_id  =   obj.srcElement.attributes.data_id.value;
    this.data_image   =   obj.srcElement.attributes.data_image.value;
    this.table   =   obj.srcElement.attributes.table.value;
    this.where_field  =   obj.srcElement.attributes.where_id.value;
    
    this.folder_name  =   obj.srcElement.attributes.folder_name.value;
    
    if(confirm('Are you sure want to delete?')){
        this.ngxService.start();
      this.qry = 'DELETE FROM '+this.table+' WHERE 1=1 AND  '+this.where_field+' = '+this.data_id;

      this.httpService.deleteVideoImage(this).subscribe((resp: any) => {
        //console.log(resp);
        this.galleryRecords();
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(this.isError) ;
      });

    }else{
        alert('Cancel');
    }
  }
  uploadSubmit(){
  
  //this.load_process = '1';
  this.no_images = '';
    ////console.log('INSIDE SUPLOAF');
    //console.log(this.uploader.queue.length);
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      
      let allowedExtensions = /(\.mp3|\.wav)$/i;
      var str = fileItem.name;
      str =  str.toLowerCase();
      if(!allowedExtensions.exec(str)){
        this.userService.showError('Please upload file having extensions wav,mp3 only.');
        this.ngxService.stop();

        setTimeout(()=>{    
          window.location.reload();
        }, 1000);
        
        return false;
      }

      /*if(fileItem.size > (10 * 1024 * 1024)){
        this.ngxService.stop();
        alert("Each File should be less than 10 MB of size.");
        
        setTimeout(()=>{    
          window.location.reload();
        }, 1000);

        return false;
      }*/


    }

    

    let r = 0;
    let j = 0;
    
    for (j = 0; j < this.uploader.queue.length; j++) {
      this.ngxService.start();
      //console.log('INSIDE');
      let data = new FormData();
      let fileItem = this.uploader.queue[j]._file;
      ////console.log(fileItem.name);
      data.append('file', fileItem);
      data.append('fileSeq', 'seq'+j);
      //console.log(data);
      this.uploadFile(data).subscribe((resp: any) => {
        //console.log(resp);
        r++;
        if(resp.result=='success')
          this.no_images += resp.filename+',';
        else if(resp.result=='error')
          this.userService.showError( resp.message);

        if(r==j){
          this.ngxService.stop(); 
          this.steptwoSubmit();
        }

      }, (errorResponse: HttpErrorResponse) => {
      this.ngxService.stop(); 
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(errorResponse) ;
      });
    }
    this.uploader.clearQueue();
  }

  

  /* Form submit */
  steptwoSubmit(){
  
    if(this.project.project_style=='')
      this.userService.showError('Select style');
    else if(this.project.project_speed=='')
      this.userService.showError('Select space/speed');
    else if(this.project.project_music_selection=='')
      this.userService.showError('Select music selection');
    else if(this.project.project_music_selection!='' && this.project.project_music_selection=='2' &&  this.existing_music=='' && this.no_images=='')
      this.userService.showError('Please upload your music file');
    else{
      //console.log('FORM INSIDE');
      
      this.ngxService.start(); 
      this.httpService.stepTwoUpdate(this).subscribe((resp: any) => {

        //console.log(resp);
        this.ngxService.stop(); 
        this.galleryRecords();
        if(resp.result!='' ){
          //this.galleryRecords();
          //this.userService.showSuccess('Step 2 completed successfully');
          this.next_enable = '1';
           this.no_images = '';
          //this.router.navigate(['/step-three/'+this.project_id]);
        }else if(resp.result=='error'){
          this.userService.showError(JSON.stringify(resp.error));
        }

      }, (errorResponse: HttpErrorResponse) => {
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(this.isError) ;
      });
    }
  }

  steptwoSubmitNext(){
    if(this.project.project_style=='')
      this.userService.showError('Select style');
    else if(this.project.project_speed=='')
      this.userService.showError('Select space/speed');
    else if(this.project.project_music_selection=='')
      this.userService.showError('Select music selection');
    else if(this.project.project_music_selection!='' && this.project.project_music_selection=='2' &&  this.existing_music=='' && this.no_images=='')
      this.userService.showError('Please upload your music file');
    else{
      //console.log('FORM INSIDE');
      
      this.ngxService.start(); 
      this.httpService.stepTwoUpdate(this).subscribe((resp: any) => {

        //console.log(resp);
        this.ngxService.stop(); 
        if(resp.result!='' ){
          this.galleryRecords();
          this.userService.showSuccess('Step 2 completed successfully');
          this.next_enable = '1';
           this.no_images = '';
          this.router.navigate(['/step-three/'+this.project_id]);
        }else if(resp.result=='error')
          this.userService.showError(JSON.stringify(resp.error));
      }, (errorResponse: HttpErrorResponse) => {
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(this.isError) ;
      });
    }
  }

  galleryRecords(){
    /* Query for gallery records */
    this.ngxService.start(); 
    this.query = 'SELECT * FROM '+this.tablenames.project_gallery+' WHERE 1=1 AND gallery_type = 3 AND gallery_project_id = '+this.httpService.decryptNew(this.project_id); 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      this.gallery = resp.result;
      this.ngxService.stop(); 
      this.existing_music +=resp.result;
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  stepOneCheck(){

  this.ngxService.start(); 
    this.httpService.stepOneCheck(this)
      .subscribe((resp: any) => {
      this.ngxService.stop(); 
      //console.log(resp);
      
      if(resp.result==''){
        window.location.href='/#/index';
      }
      
      /* 
      *   Checking condition for redirect step one page if it is null
      */
      if(resp.result.result1[0].project_title==''){
        this.userService.showError('Enter project title');
        this.router.navigate(['/new-project/'+this.project_id]);
      }
      else if(resp.result.result2[0].tot=='' || resp.result.result2[0].tot=='0' ){
        this.userService.showError('Upload your image or video files');
        this.router.navigate(['/new-project/'+this.project_id]);
      }else{
        this.project.project_id = resp.result.result1[0].project_id;
        this.project.project_style = resp.result.result1[0].project_style;
        this.project.project_speed = resp.result.result1[0].project_space_speed;
        this.project.project_music_selection = resp.result.result1[0].project_music_selection;
        this.project.project_music_file = resp.result.result1[0].project_music_file;
        if(resp.result.result1[0].project_music_selection=='1'){
          this.choose_editube_music = resp.result.result1[0].project_music_file;
          
          $(".tab-pane .music-list ul li a").removeClass('default-selection');

          $(".tab-pane").removeClass('default-selection');

          $(".tab-pane .music-list ul li a[music_file='" + resp.result.result1[0].project_music_file + "']").addClass("default-selection");
          
          //console.log('classsss '+$(".tab-pane .music-list ul li a[music_file='" + resp.result.result1[0].project_music_file + "']").attr('class'));

          var p = $(".tab-pane .music-list ul li a[music_file='" + resp.result.result1[0].project_music_file + "']").attr('music_tab');
          //console.log('#'+p+'-tab');
          $('#'+p+'-tab').addClass('default-selection');
        }
        this.galleryRecords();
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  editubeMusic(obj){
    $(".tab-pane .music-list ul li a").removeClass('default-selection');
    $(".tab-pane").removeClass('default-selection');

    this.project.project_music_file = obj.srcElement.attributes.music_file.value;
    this.editube_music = obj.srcElement.attributes.data_id.value;
    this.editube_music_name = obj.srcElement.attributes.data_name.value;
    this.editube_music_file = obj.srcElement.attributes.music_file.value;
    this.choose_editube_music = this.editube_music_file;

  }

  ngOnInit() { 
    this.s3url = this.tablenames.s3url;
    this.next_enable = '';
    /*msbapTitle = 'Audio Title';
    msbapAudioUrl = 'http://192.168.1.99/editube-service/assets/project/1/file-1550122576719';  
    */
    this.existing_music = '';
    this.primary_id  = 'gallery_id';
    this.gallery_table = this.tablenames.project_gallery
    this.load_process = '';
    this.server_url = this.tablenames.server_url;
    /* Upload Form */
    this.uploadForm = this.fb.group({
      project_title:[null,null],
      project_files: [null, null],
      type:  [null, Validators.compose([Validators.required])]
    });

    this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });
    
    this.httpService.paymentDoneAlready(this);
    

    this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
      //console.log('***** onWhenAddingFileFailed ********')
      $('#project_music_file').val('');
      alert('Only allowed mp3,wav and 1GB size only allowed to upload');

    }

    this.table = this.tablenames.project;
    this.member_id = localStorage.getItem('member_id');
      
    /* MEMBER CHECK */
    this.httpService.checkUserExists(this.member_id).subscribe((resp: any) => {
      //console.log('CHECK EXISTSSSSSSSSSSSS');
      if(resp.result!=''  && typeof resp.result[0]['member_id']!='undefined'){
        //console.log('INSIDE');
        ////console.log(resp);
      }else{
        //console.log('LOGOUT PLZ');
        this.httpService.logoutUser();
        window.location.href='/#/index';
      }
    }, (errorResponse: HttpErrorResponse) => {
          
          let a = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(a) ;
    });


    this.step1_url = '/#/new-project/'+this.project_id;
    this.step2_url = '/#/step-two/'+this.project_id;
    this.step3_url = '/#/step-three/'+this.project_id;
    /* Style */
    this.query = 'SELECT * FROM '+this.tablenames.style+' WHERE 1=1 AND style_status =1  '; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      this.style_records = resp.result;
    }, (errorResponse: HttpErrorResponse) => {
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(this.isError) ;
    });

    /* Speed */
    this.query = 'SELECT * FROM '+this.tablenames.speed+' WHERE 1=1 AND speed_status =1  '; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      this.speed_records = resp.result;
    }, (errorResponse: HttpErrorResponse) => {
          
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(this.isError) ;
    });

    /* Music category */
    this.query = 'SELECT * FROM '+this.tablenames.music_category+' WHERE 1=1 AND category_status =1  '; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      this.music_category = resp.result;
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });  

    /* Music  */
    this.query = 'SELECT * FROM '+this.tablenames.music+' WHERE 1=1 AND music_status =1  '; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
       //= resp.result;

       this.music_records = resp.result.reduce((r,{music_category_id})=>{
          if(!r.some(o=>o.music_category_id==music_category_id)){
            r.push({music_category_id,groupItem:resp.result.filter(v=>v.music_category_id==music_category_id)});
      }
      return r;
      },[]);

    }, (errorResponse: HttpErrorResponse) => {
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(this.isError) ;
    });  

    setTimeout(()=>{    
      this.stepOneCheck();
    }, 1000);
    
    

  }
}
