import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { ToastrManager } from 'ng6-toastr-notifications';
import {ActivatedRoute} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileSaverService } from 'ngx-filesaver';




const URL = 'http://18.219.7.2:3000/api/upload';
@Component({
  selector: 'app-approve-view',
  templateUrl: './approve-view.component.html',
  styleUrls: ['./editor-login.component.scss']
})

export class ApproveViewComponent implements OnInit {
  s3url = '';
  thumb_url = '';
  editor_id = '';
  is_completed = '';
  is_completed_request = '';
  query = '';
  server_url = '';
  job_records = '';
  assign_id = '';
  job_no = 0;
  as_p='';
  gallery = '';
  image = '';
  uploaded_status = '';
  filename = '';
  filepath= '';
  download_link = '';
  constructor(public toastr: ToastrManager,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService,private ngxService: NgxUiLoaderService,private route:ActivatedRoute,private _FileSaverService: FileSaverService) { }
  
  selectedFile: File;
  /* For image upload */
  public uploader:FileUploader = new FileUploader({
    isHTML5: true,maxFileSize: 1024*1024*1024
  });

  
  editor ={
    editor_id:'',
    editor_name:'',
    editor_email:'',
    editor_mobiel:'',
    created_date:''
  }

  project ={
    project_id:'',
    project_title:'',
    project_style:'',
    project_space_speed:'',
    project_music_selection:'',
    project_music_file:'',
    project_video_duration:'',
    project_opening_title:'',
    project_closing_title:'',
    project_other_text:'',
    project_opening_title_option:'',
    project_closing_title_option:'',
    project_other_text_option:'',
    project_additional:'',
    project_member_id:'',
    style_name:'',
    speed_name:'',
  }

  request_free_records = '';
  request_list_records = '';
  submit_project={
    pr_comments_by_editor:'',
    pr_status_by_editor:'',
    pr_file:'',
  }
  request_project={
    request_comment_editor:'',
    request_project_status_by_editor:'',
    request_file:'',
  }

  project_status_by_pm = '';



  viewJobs(ass_id){
    let s = ass_id.toString();
    s = this.httpService.encryptNew(s);
    this.router.navigate(['/approve-view/'+s]);
  } 

  addProject(){
    this.submit_project.pr_comments_by_editor ='';
    this.submit_project.pr_status_by_editor ='';
    this.submit_project.pr_file ='';
    $('#submit-project-popup').modal('show');
  }

  addRequestProject(){
    this.request_project.request_file ='';
    this.request_project.request_project_status_by_editor ='';
    this.request_project.request_comment_editor ='';
    $('#request-submit-project-popup').modal('show');
  }

  uploadFile(data: FormData) {
    return this.httpClient.post(this.tablenames.image_upload, data);
  }

  uploadFiles(){
    let data = new FormData();
    let fileItem = this.uploader.queue[0]._file;
    let allowedExtensions = /(\.mp4|\.3gpp|\.mpeg|\.webm|\.x-flv|\.x-m4v|\.x-ms-wmv|\.x-msvideo|\.mov|\.JPG|\.JPEG|\.PNG|\.GIF|\.MP4|\.3GPP|\.MPEG|\.WEBM|\.X-FLV|\.X-M4V|\.X-MS-WMV|\.X-MSVIDEO|\.MOV)$/i;

    if(!allowedExtensions.exec(fileItem.name)){

          //console.log('INVALID '+fileItem.name);
          /*
          this.userService.showError('Please upload file having extensions png,jpg,gif,jpeg,mp4,3gpp,mpeg,webm,x-flv,x-m4v,x-ms-wmv,x-msvideo,.mov only.');
          */
          alert('Please upload file having extensions png,jpg,gif,jpeg,mp4,3gpp,mpeg,webm,x-flv,x-m4v,x-ms-wmv,x-msvideo,.mov only.');
          window.location.reload();
          this.ngxService.stop();
          return false;
      }else{
        this.ngxService.start();
      }

    data.append('photo', fileItem);

    this.ngxService.start(); 
    this.uploadFile(data).subscribe((resp: any) => {
      if(resp.result=='success' )
        this.submit_project.pr_file = resp.filename;
      else if(resp.result=='')
        this.userService.showError( resp.message);

      this.ngxService.stop(); 
          
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop(); 
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  uploadFilesRequest(){
    let data = new FormData();
    let fileItem = this.uploader.queue[0]._file;
    data.append('photo', fileItem);
    this.ngxService.start(); 
    this.uploadFile(data).subscribe((resp: any) => {
      if(resp.result=='success' )
        this.request_project.request_file = resp.filename;
      else if(resp.result=='')
        this.userService.showError( resp.message);

      this.ngxService.stop(); 
          
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop(); 
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  submitProjectEditor(){
    if(this.submit_project.pr_file=='')
      this.userService.showError('Please upload file');
    else if(this.submit_project.pr_status_by_editor=='')
      this.userService.showError('Select job status');
    else{
      this.ngxService.start();
      
      this.httpService.submitProject(this)
        .subscribe((resp: any) => {
        if(resp.result.result=='success')
          this.userService.showSuccess(resp.result.message);
        else
          this.userService.showError(resp.message);

        window.location.reload();
        $('#submit-project-popup').modal('hide');  
        this.uploadedStatus();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
    }
  }



  requestProjectEditor(){
    if(this.request_project.request_file=='')
      this.userService.showError('Please upload file');
    else if(this.request_project.request_project_status_by_editor=='')
      this.userService.showError('Select job status');
    else{
      this.ngxService.start();
      
      this.httpService.requestProjectEditor(this)
        .subscribe((resp: any) => {
        if(resp.result.result=='success'){
          this.userService.showSuccess(resp.result.message);
          window.location.reload();
        }
        else
          this.userService.showError(resp.message);

        $('#submit-project-popup').modal('hide');  
        this.uploadedStatus();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
    }
  }


  allocateRecords(this){
    //window.open('http://192.168.1.99/editube-service/hello.zip');
    this.ngxService.start();
      this.query = 'SELECT manager_name,manager_email,manager_mobile,project_title,'+this.tablenames.project_assign_editor+'.created_date,'+this.tablenames.project_assign_editor+'.modified_date,assign_id,assign_project_id,assign_editor_id,assign_manager_id,assign_member_id,editor_name,editor_email,project_status_by_pm FROM '+this.tablenames.project_assign_editor+' JOIN '+this.tablenames.manager+' ON manager_id = assign_manager_id JOIN '+this.tablenames.project+' ON project_id = assign_project_id JOIN '+this.tablenames.editor+' ON editor_id = assign_editor_id WHERE 1=1  AND assign_id = '+this.httpService.decryptNew(this.assign_id)+' AND assign_approval_by_editor = 1 AND assign_editor_id = '+this.editor_id; 
      this.httpService.commonFetch2(this)
        .subscribe((resp: any) => {
        this.job_records = resp.result[0];
        this.project_status_by_pm = this.job_records.project_status_by_pm;
        //console.log(resp.result);

        this.projectDetails()
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  /* Project Details */
    projectDetails(this){
      this.httpService.projectDetails(this)
          .subscribe((resp: any) => {
            //console.log(resp);
            if(resp.result[0]!='' ){
              resp = resp.result[0];
              /* Assign value to field */
              this.project.project_id = resp.project_id;
              this.project.project_title = resp.project_title;
              this.project.project_style = resp.project_style;
              this.project.project_space_speed = resp.project_space_speed;
              this.project.project_music_selection = resp.project_music_selection;
              this.project.project_music_file = resp.project_music_file;
              this.project.project_video_duration = resp.project_video_duration;
              this.project.project_opening_title = resp.project_opening_title;
              this.project.project_closing_title = resp.project_closing_title;
              this.project.project_other_text = resp.project_other_text;
              this.project.project_opening_title_option = resp.project_opening_title_option;
              this.project.project_closing_title_option = resp.project_closing_title_option;
              this.project.project_other_text_option = resp.project_other_text_option;
              this.project.project_additional = resp.project_additional;
              this.project.style_name= resp.style_name;
              this.project.speed_name= resp.speed_name;
              this.project.project_member_id = resp.project_member_id;
              this.galleryRecords(this);
              this.projectStatus(this);
              this.uploadedStatus(this);
              this.requestCompletedStatus(this);
              this.requestForFree(this);
          }else{
              this.isError = true;
              this.errorMsg = 'Record is not available, please logout and login';
            }
        }, (errorResponse: HttpErrorResponse) => {
              //console.log(errorResponse);
              this.errorMsg = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again';
        });
    }

  downloadServerFiles(pr_id){
    
    this.as_p = pr_id;
    this.as_p = this.as_p.toString(); 
    if(this.as_p!='' && typeof this.as_p!='undefined'){
    this.ngxService.start();
      this.httpService.downloadServerFiles(this)
        .subscribe((resp: any) => {
        if(resp.result.result=='success'){
          this.userService.showSuccess('File is ready to download, please enable if pop-up is blocked in browswer ') ;
          location.href =this.server_url+'assets/project-output/'+this.as_p+'.zip';
          
        }else{
          this.userService.showError(resp.result.message) ;
        }
        
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
    }
    
  }

  galleryRecords(this){
    this.ngxService.start();
    /* Query for gallery records */
    this.query = 'SELECT * FROM '+this.tablenames.project_gallery+' WHERE 1=1  AND gallery_project_id = '+this.job_records.assign_project_id; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      this.gallery = resp.result;
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        alert(isError) ;
    });
  }



  projectStatus(this){
    this.query = 'SELECT * FROM '+this.tablenames.project_submit_editor+' WHERE 1=1  AND pr_project_id = '+this.job_records.assign_project_id+' AND pr_assign_id = '+this.job_records.assign_id+' AND pr_editor_id = '+this.job_records.assign_editor_id+' AND pr_member_id = '+this.job_records.assign_member_id+' AND pr_status_by_pm = 1 ORDER BY pr_id DESC LIMIT 1'; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      //console.log('COMPLETED');
      //console.log(resp);
      this.is_completed =  resp.result[0].pr_id!=''?resp.result[0]:'';
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        alert(isError) ;
    });
  }

  uploadedStatus(this){
    this.query = 'SELECT * FROM '+this.tablenames.project_submit_editor+' WHERE 1=1  AND pr_project_id = '+this.job_records.assign_project_id+' AND pr_assign_id = '+this.job_records.assign_id+' AND pr_editor_id = '+this.job_records.assign_editor_id+' AND pr_member_id = '+this.job_records.assign_member_id; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      this.uploaded_status = resp.result;
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        alert(isError) ;
    }); 
  }

  requestForFree(this){
    this.ngxService.start();
    /* Query for gallery records */
    this.query = 'SELECT * FROM '+this.tablenames.request_member+' WHERE 1=1  AND request_project_id = '+this.job_records.assign_project_id+' AND request_editor_id = '+this.editor_id; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      //console.log(resp);

      if(resp.result!=''){
        this.request_free_records = resp.result;
        this.requestForFreeList(this);
      }
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        alert(isError) ;
    });
  }

  requestForFreeList(this){
    this.ngxService.start();
    /* Query for gallery records */
    this.query = 'SELECT * FROM '+this.tablenames.request_pm_editor+' WHERE 1=1  AND request_project_id = '+this.job_records.assign_project_id+' AND request_editor_id = '+this.editor_id; 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      if(resp.result!='')
        this.request_list_records = resp.result;
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        alert(isError) ;
    });  
  }

  requestCompletedStatus(this){
    this.ngxService.start();
    this.query = 'SELECT * FROM '+this.tablenames.request_pm_editor+' WHERE 1=1  AND request_project_id = '+this.job_records.assign_project_id+' AND request_editor_id = '+this.editor_id+' AND request_project_status_by_pm =1 '; 
      this.httpService.commonFetch(this)
        .subscribe((resp: any) => {
        if(resp.result!='')
          this.is_completed_request = resp.result[0];
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          alert(isError) ;
      });
  }

  downloads3Files(url){
    /*console.log(url);
    var hiddenIFrameID = 'iframeDisplay',
        iframe = document.getElementById(hiddenIFrameID);
    if (iframe === null) {
        iframe = document.createElement('iframe');
        iframe.id = hiddenIFrameID;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
    }
    iframe.src = url;
    */
  }

  rawFileDownload(){
    $('#s3downloadModal').modal('show');
    
    /*var j =0;
    for(j=0;j<this.gallery.length;j++){
      this.filename = (this.gallery[j]['gallery_file']);
      this.filepath = this.tablenames.s3url+'assets/project/'+this.gallery[j]['gallery_project_id']+'/'+(this.gallery[j]['gallery_file']);
      
      this.downloads3Files(this.filepath);
      console.log(this.filepath);
      break;

      
      this.httpService.s3DownloadFile(this)
        .subscribe((resp: any) => {
        if(resp.result.urls!=''){
          this.downloads3Files(resp.result.urls);
          //this.download_link = resp.result.urls;
          //window.open(resp.result.urls,'',"width=500,height=500")
        }
        
      }, (errorResponse: HttpErrorResponse) => {
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          alert(isError) ;
      });
      
      
    }*/

  }

  s3DownloadFile_old(obj){
    if(confirm('Are you sure going to download multiple files?')){
      var filename  = obj.srcElement.attributes.filename.value;
      var filepath  = obj.srcElement.attributes.filepath.value;
      //window.open(this.tablenames.node_url+'s3directDownload?filename='+filename+'&filepath='+filepath,'_blank');
      var a = this.tablenames.node_url+'s3directDownload?filename='+filename+'&filepath='+filepath;
       document.getElementById("iframeDisplay").innerHTML = "<iframe src="+a+" ></iframe>";
    }
  }

  s3DownloadFile(obj){
    //this.filename  = obj.srcElement.attributes.filename.value;
    //this.filepath  = obj.srcElement.attributes.filepath.value;

    /*this.httpService.s3DownloadFile(this)
      .subscribe((resp: any) => {
      if(resp.result.urls!=''){
        this.download_link = resp.result.urls;
        window.open(resp.result.urls,'',"width=500,height=500")
      }
      
    }, (errorResponse: HttpErrorResponse) => {
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        alert(isError) ;
    });
    */
    //window.open(this.tablenames.node_url+'s3directDownload?filename='+filename+'&filepath='+filepath,'_blank');
    //var a = this.tablenames.node_url+'s3directDownload?filename='+filename+'&filepath='+filepath;
    //document.getElementById("iframeDisplay").innerHTML = "<iframe src="+a+" ></iframe>";

    var node_url = this.tablenames.node_url;
    if(confirm('Are you sure going to download multiple files?')){
      $('.filedownload_field li').each(function () {
        //console.log($(this).attr('filename'));
        var filename = $(this).attr('filename');
        var filepath = $(this).attr('filepath');
        
        //window.open(node_url+'s3directDownload?filename='+filename+'&filepath='+filepath,'_blank');
      
        var a = node_url+'s3directDownload?filename='+filename+'&filepath='+filepath;
          $( "<iframe src="+a+" ></iframe>" ).appendTo( "#iframeDisplay" );
          //document.getElementById("").innerHTML = "<iframe src="+a+" ></iframe>";
          
      });
    }

  }



  ngOnInit() { 	
    this.s3url = this.tablenames.s3url;
    this.thumb_url = this.tablenames.thumb_url;
    this.job_no = 0;
    this.editor_id  = localStorage.getItem('editor_id');
    this.server_url = this.tablenames.server_url;
    
    this.route.params.subscribe(params => {
      this.assign_id = params['assign_id'];
    });
    if(this.assign_id!=''){
      this.allocateRecords();
    }
    

    /* Files upload */
      this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          if(status==200){
            this.image = response;
            if(this.image!='' && typeof this.image!='undefined'){
              alert(this.image);
             
            }
          }
          else{
            alert('Have some problem to upload files, please refresh and try again');

          }
    };

  }




}
