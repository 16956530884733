import {Injectable} from '@angular/core';
//import {HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';


//import {Http} from "@angular/http";

import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders,HttpRequest} from '@angular/common/http';

import { Tablename } from '../app.tablename'; 

import {Router} from '@angular/router';
import {UserService} from '../services/user.service';

import * as bootstrap from 'bootstrap';

import {EncrDecrService} from '../services/EncrDecrService.service';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    
    //readonly baseURL = 'https://www.editubepro.com:3000/admin-ajax';

    //var baseURL = 'https://www.editube.com:3000/admin-ajax';

    //var baseURL = '';

    //readonly baseURL = 'http://localhost:3000/admin-ajax';
    
    private baseURL;

    readonly phpUrl = 'https://www.editubepro.com/php/sso_login_freshdesk.php';    

    constructor(public EncrDecr : EncrDecrService, private httpClient: HttpClient,private tablenames : Tablename,private userService:UserService,private router:Router) {
        this.baseURL = this.tablenames.baseURL;
    }

    
    encryptNew(str:any)
    {
        var actual = str;
        
        if(actual!=''){
            actual.toString();
        }
        /*console.log('REAL',str);
        console.log('ENCRYPT',btoa(actual));*/

        /* BTOA */
        return btoa(actual);

        /*var test = '1';
        if(test!=''){
            var encrypted = btoa(test);
            var decrypted = atob(encrypted);
            console.log('actual :',test)
            console.log('Encrypt',encrypted);
            console.log('Decrypt',decrypted);
        }*/
        
        /*console.log('ENCRYPT');
        console.log(str);
        //return str;
        var encrypted = this.EncrDecr.set('editube78964213', actual);
        var decrypted = this.EncrDecr.get('editube78964213', encrypted);

        console.log('Encrypt',encrypted);
        console.log('Decrypt',decrypted);

        return encrypted;*/

        /*if(actual==8 || actual=='8'){
            return '8eUj';
        }
        else if(actual==18 || actual=='18'){
            return 'eUj';
        }else{
        */
            
            //latest
            
            /*var key = 8524; 
            var result = "";
            var i=0;
            
            for(i=0; i<actual.length;i++)
            {
                result += String.fromCharCode(key^actual.charCodeAt(i));
            }
            
            return result;
            */


        //}
    }

    decryptNew(str:any)
    {
        var actual=str;
        
        if(actual!=''){
            actual.toString();
        }
        
        /* ATOB */
        return atob(actual);
        /*console.log('DECRYPT');        
        console.log(str);
        var encrypted = this.EncrDecr.set('editube78964213', actual);
        var decrypted = this.EncrDecr.get('editube78964213', encrypted);

        //console.log('Encrypt',encrypted);
        console.log('Decrypt',decrypted);

        return decrypted;*/

        /*if(actual=='8eUj'){
            return '8';
        }
        else if(str=='eUj'){
            return '18';
        }else{
        */
            
            
            //latest
            
            /*var key = 8524; //Any integer value
            var result="";  
            var i=0;  
            for(i=0; i<actual.length; i++)
            {
                result += String.fromCharCode(key^actual.charCodeAt(i));
            }
            return result;*/
            

        //}
    }

    isLoggedIn(): boolean {
        const token = localStorage.getItem('admin_id');
        return token != null && token.trim().length > 0;
    }
    
    getLoginUser(): string {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo !== '' ? JSON.parse(userInfo) : {};
        return (userInfo) ? userInfo['firstName'] : '';
    }

    getLoginUserId(): number {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo !== '' ? JSON.parse(userInfo) : {};
        return (userInfo) ? userInfo['userID'] : '';
    }

    getLoginUserEmail(): number {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo !== '' ? JSON.parse(userInfo) : {};
        return (userInfo) ? userInfo['emailLogin'] : '';
    }

    getToken(): string {
        const token = localStorage.getItem('authToken');
        return (token != null && token.trim().length > 0) ? token : null;
    }

    logoutUser() {
        localStorage.removeItem('member_id');
        localStorage.removeItem('type');
        localStorage.removeItem('admin_id');
        localStorage.removeItem('login_type');
        localStorage.removeItem('editor_id');
        //window.location = 'admin/login';
    }

    /* Country fetch */
    getCountry(all:any) {
        var country_id = all.country_id!="" && typeof all.country_id!='undefined'?all.country_id:"";
        const data = {country_id: country_id,action:this.encryptNew('9')};
        const d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`,d, {headers: h});
    }

    /* CMS login */
    login(username: string, password: string) {
    	const data = {username: username, password: password,action:this.encryptNew('1')};
        const d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`,d, {headers: h});
    }

    /* 
    * CMS REOCRD
    */
    cmsRecord(id:number){
        const qry = 'SELECT * FROM '+this.tablenames.cms+' WHERE 1=1 AND cms_id = '+id;
        //const data = {qry: qry,action:2};
        let data = {r: this.encryptNew(qry),action:this.encryptNew(this.tablenames.action)};
        const d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});        
    }

    /* CMS UPDATE */
    cmsUpdate(all:any){
        //console.log(all);
        /* Update Query */
        const qry = ' UPDATE '+this.tablenames.cms+' SET cms_title ="'+all.cms_title+'",   cms_desc1 ="'+all.cms_desc1+'",cms_desc2 ="'+all.cms_desc2+'",cms_desc3 ="'+all.cms_desc3+'" WHERE 1=1 AND cms_id = '+all.cms_id;
        
        //const data = {qry:qry,action:2};
        let data = {r: this.encryptNew(qry),action:this.encryptNew(this.tablenames.action)};
        const d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});      
    }
    
    /* Status change */
    statusChange(obj){
        ////console.log(obj);
        const id = obj.srcElement.attributes.data_id.value;
        if(id!='' && typeof id!='undefined' ){
            const where = obj.srcElement.attributes.data_where.value;
            const update_field = obj.srcElement.attributes.data_update.value;
            const table = obj.srcElement.attributes.data_table.value;
            const s = obj.srcElement.attributes.data_status.value;
            
            const status = obj.srcElement.attributes.data_status.value==1?2:1;
            
            const qry = 'UPDATE '+table+' SET '+update_field+' = '+status+' WHERE 1=1 AND '+where+' = '+id;
            //const data = {qry:qry,action:2};
            let data = {r: this.encryptNew(qry),action:this.encryptNew(this.tablenames.action)};
            const d = JSON.stringify(data);
            //console.log(d);
            const h = new HttpHeaders({'Content-Type': 'application/json'});
            return  this.httpClient.post(`${this.baseURL}`, d,{headers: h}).subscribe((resp: any) => {
              //Success
              window.location.reload();
          }, (errorResponse: HttpErrorResponse) => {
              /*e = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again';
              alert();*/
          });

        }        
    }

    /* Common Delete */
    deleteRecord(obj){
        
        ////console.log(obj);
        const id = obj.srcElement.attributes.data_id.value;
        if (confirm("Are you sure?"))
        {
            if(id!='' && typeof id!='undefined' ){
                const where = obj.srcElement.attributes.data_where.value;
                const update_field = obj.srcElement.attributes.data_update.value;
                const table = obj.srcElement.attributes.data_table.value;
                const qry = 'DELETE FROM '+table+' WHERE '+where+' = '+id;

                //const data = {qry:qry,action:2};
                let data = {r: this.encryptNew(qry),action:this.encryptNew(this.tablenames.action)};
                const d = JSON.stringify(data);
                const h = new HttpHeaders({'Content-Type': 'application/json'});
                return  this.httpClient.post(`${this.baseURL}`, d,{headers: h}).subscribe((resp: any) => {
                  //Success
                  window.location.reload();
              }, (errorResponse: HttpErrorResponse) => {
                  /*e = errorResponse.error ? errorResponse.error.message
                      : 'Sorry, unable to complete your request, please refresh this page and try again';
                  alert();*/
              });   

            }else{

            }   
        }
    }    

    /* Delete Whole Project */
    deleteProject(project_id){
        let member_id = localStorage.getItem('member_id');

        if(member_id!='' && typeof member_id!='undefined' && project_id!='' && typeof project_id!='undefined' ){

            if(confirm('Are sure want to delete?')){
                project_id = project_id.toString();
                member_id = member_id.toString();
                
                let data = {member_id: this.encryptNew(member_id),action:this.encryptNew('43'),project_id:this.encryptNew(project_id)};
                const d = JSON.stringify(data);
                const h = new HttpHeaders({'Content-Type': 'application/json'});
                
                return  this.httpClient.post(`${this.baseURL}`, d,{headers: h}).subscribe((resp: any) => {
                        if(resp.result!=''){
                            alert('Your job is deleted');
                            window.location.href='/#/mdashboard';
                        }else{
                            this.userService.showError(JSON.stringify(resp.message));
                        }
                        
                  }, (errorResponse: HttpErrorResponse) => {
                      let e = errorResponse.error ? errorResponse.error.message
                          : 'Sorry, unable to complete your request, please refresh this page and try again';
                      alert(e);
                });
            }

        }else{
            this.userService.showError('You are unauthorized person');
        }
    }

    /* Delete single image */
    deleteImage(obj){
        //console.log(obj);    
        var data_id  =   obj.srcElement.attributes.data_id.value;
        var data_image   =   obj.srcElement.attributes.data_image.value;
        var table   =   obj.srcElement.attributes.table.value;
        var where_field  =   obj.srcElement.attributes.where_id.value;
        var update_field  =   obj.srcElement.attributes.update_field.value;
        var folder_name  =   obj.srcElement.attributes.folder_name.value;
        
        if(confirm('Are you sure want to delete?')){
            
            var qry = 'UPDATE '+table+' SET '+update_field+' = "" WHERE 1=1 AND '+where_field+' = '+data_id;

            let data = {r: (qry),action:this.encryptNew(this.tablenames.action2),del:('1'),df:(folder_name),m:(data_image)};
            const d = JSON.stringify(data);
            const h = new HttpHeaders({'Content-Type': 'application/json'});
            
            return  this.httpClient.post(`${this.baseURL}`, d,{headers: h}).subscribe((resp: any) => {
                  //Success
                  window.location.reload();
              }, (errorResponse: HttpErrorResponse) => {
                  /*e = errorResponse.error ? errorResponse.error.message
                      : 'Sorry, unable to complete your request, please refresh this page and try again';
                  alert();*/
            });

        }
    }

    deleteGallery(obj){
        var data_id  =   obj.srcElement.attributes.data_id.value;
        var data_image   =   obj.srcElement.attributes.data_image.value;
        var table   =   obj.srcElement.attributes.table.value;
        var where_field  =   obj.srcElement.attributes.where_id.value;
        
        var folder_name  =   obj.srcElement.attributes.folder_name.value;
        
        if(confirm('Are you sure want to delete?')){
            
            var qry = 'DELETE FROM '+table+' WHERE 1=1 AND  '+where_field+' = '+data_id;

            let data = {r: (qry),action:this.encryptNew(this.tablenames.action2),del:('1'),df:(folder_name),m:(data_image)};
            const d = JSON.stringify(data);
            const h = new HttpHeaders({'Content-Type': 'application/json'});
            
            return  this.httpClient.post(`${this.baseURL}`, d,{headers: h}).subscribe((resp: any) => {
                  $(obj.target).parent().parent().remove();
                  //Success
                  //window.location.reload();
              }, (errorResponse: HttpErrorResponse) => {
                  /*e = errorResponse.error ? errorResponse.error.message
                      : 'Sorry, unable to complete your request, please refresh this page and try again';
                  alert();*/
            });

        }else{
            alert('Cancel');
        }
    }

    deleteVideoImage(all:any){
        let data = {r: (all.qry),action:this.encryptNew(this.tablenames.action2),del:('1'),df:(all.folder_name),m:(all.data_image)};
        const d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, data,{headers: h});  
    }

    /* Video category add and update */
    categoryUpdate(all:any){
        //console.log(all);
        const query = 'INSERT INTO  '+this.tablenames.video_category+' (category_name,created_date) VALUES ("'+all.category_name+'","'+all.dateTime+'")';

        /* Update Query */
        
        /* Ajax calling */
        
        //const data = {qry: query,action:2};
        let data = {r: this.encryptNew(query),action:this.encryptNew(this.tablenames.action)};
        const d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, data,{headers: h});    
    }

    memberProfile(all:any){
        let data = {member_id:all.member.member_id,member_first_name:all.member.member_first_name,member_last_name:all.member.member_last_name,member_mobile:all.member.member_mobile,member_country_code:all.member.member_country_code,member_address:all.member.member_address,action:this.encryptNew('memberPro')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }

    /* Fetch records */
    commonFetch(all:any){

        var qry = all.query;
        var image = all.image!='' && typeof all.image!='undefined'?all.image:'';
        var df = all.designation_folder!='' && typeof all.designation_folder!='undefined'?all.designation_folder:'';
        
        var img_upload = all.img_upload!='' && typeof all.img_upload!='undefined'?all.img_upload:'';
        
        let data = {r: this.encryptNew(qry),action:this.encryptNew(this.tablenames.action),m:this.encryptNew(image),df:this.encryptNew(df),img_upload:this.encryptNew(img_upload)};
        let d = JSON.stringify(data);
        ////console.log(d);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});
    }

    commonFetch2(all:any){

        var qry = all.query;
        var image = all.image!='' && typeof all.image!='undefined'?all.image:'';
        var df = all.designation_folder!='' && typeof all.designation_folder!='undefined'?all.designation_folder:'';
        
        var img_upload = all.img_upload!='' && typeof all.img_upload!='undefined'?all.img_upload:'';
        
        let data = {r: (qry),action:this.encryptNew(this.tablenames.action2),m:(image),df:(df),img_upload:(img_upload)};
        let d = JSON.stringify(data);
        
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});
    }



    /* Already existing email and mobile */
    memberCheck(all:any){
        let data = {
            member_first_name:this.encryptNew(all.member.member_first_name),
            member_last_name:this.encryptNew(all.member.member_last_name),
            member_email:this.encryptNew(all.member.member_email),
            member_mobile:this.encryptNew(all.member.member_mobile),
            member_password:this.encryptNew(all.member.member_password),
            action:this.encryptNew('10')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }


    /* Already existing email and mobile */
    editorCheck(all:any){
        let id = all.id!='' && typeof all.id!='undefined'?all.id:'';
        ////console.log(all);
        let data = {email:all.editor.editor_email,mobile:all.editor.editor_mobile,action:this.encryptNew('5'),id:id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    /* Editor register by admin */
    editorRegister(all:any){    
        var qry = all.query;
        let id = all.id!='' && typeof all.id!='undefined'?all.id:'';
        let data = {r: this.encryptNew(qry),email:all.editor.editor_email,mobile:all.editor.editor_mobile,action:this.encryptNew('6'),password:this.encryptNew(all.editor.editor_password),id:id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    /* Already existing email and mobile */
    managerCheck(all:any){
        let id = all.id!='' && typeof all.id!='undefined'?all.id:'';
        ////console.log(all);
        let data = {email:all.manager.manager_email,mobile:all.manager.manager_mobile,action:this.encryptNew('7'),id:id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    /* Member register  */
    memberRegister(all:any){    
        var qry = all.query;
        let id = all.id!='' && typeof all.id!='undefined'?all.id:'';
        let data = {r: this.encryptNew(qry),
            
            action:this.encryptNew('6'),
            password:this.encryptNew(all.member.member_password),
            id:id,
            types:'member',
            member_first_name:this.encryptNew(all.member.member_first_name),
            member_last_name:this.encryptNew(all.member.member_last_name),
            member_email:this.encryptNew((all.member.member_email).toLowerCase()),
            member_mobile:this.encryptNew(all.member.member_mobile),
            member_country_code:this.encryptNew(all.member.member_country_code)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    /* Projecy manager register by admin */
    managerRegister(all:any){    
        var qry = all.query;
        let id = all.id!='' && typeof all.id!='undefined'?all.id:'';
        let data = {r: this.encryptNew(qry),email:all.manager.manager_email,mobile:all.manager.manager_mobile,action:this.encryptNew('6'),password:this.encryptNew(all.manager.manager_password),id:id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    /* Video category */
    videoCategory(all:any){
        var qry = 'SELECT video_id,video_name,video_url,category_name,category_id FROM '+this.tablenames.video+' JOIN '+this.tablenames.video_category+' ON video_category_id = category_id WHERE 1=1 AND video_status = 1 AND category_status = 1 ';
        if(all.video_limit!='' && typeof all.video_limit!='undefined' )
            qry +=' LIMIT 0,'+all.video_limit;

        let data = {video_limit:all.video_limit,action:this.encryptNew('47')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});       
    }

    /* Editor assign to manager */
    editorUpdate(manager_id:any,editor_id:any){
        let data = {manager_id: manager_id,editor_id:editor_id,action:this.encryptNew('8')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});      
    }

    /* check member activate */
    memberActivate(all:any){
        let data = {mem_id: all.mem_id,action:this.encryptNew('11')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});
    }

    /* Change password for member */
    changePwdMember(all:any){
        let data = 
        {old_pwd: all.member.current_password,new_password:all.member.member_password,member_id:this.encryptNew(all.member.member_id),action:this.encryptNew('12')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});
    }

    /* try it */
    tryIt(all:any){
        //$('#tryit-popup').modal('show');
        $('input[name=new_video_title]').val('');
        var member_id  = localStorage.getItem('member_id');
        if(member_id!='' && typeof member_id!='undefined' ){

            let data = 
            {member_id:this.encryptNew(member_id),action:this.encryptNew('13')};
            let d = JSON.stringify(data);
            const h = new HttpHeaders({'Content-Type': 'application/json'});

            this.httpClient.post(`${this.baseURL}`, d,{headers: h})
            .subscribe((resp: any) => {
                if(resp.result!='' ){
                    if(resp!='' && typeof resp!='undefined'){
                        var s:string = ''+resp.result[0].project_id+'';
                        this.userService.showWarning('Already you have a job in the draft, Please make payment or delete to post a new job.');
                        window.location.href = '/#/new-project/'+this.encryptNew(s);
                    }else if(resp.message!=''){
                        this.userService.showError(JSON.stringify(resp));
                    }else{
                        this.userService.showError('Your session id not available, please logout and try again');
                    }
                  }else{
                    $('#tryit-popup').modal('show');
                  }
                }, (errorResponse: HttpErrorResponse) => {
                    
                    this.userService.showError(errorResponse.error ? errorResponse.error.message
                        : 'Sorry, unable to complete your request, please refresh this page and try again') ;
                });
        }else{
            this.userService.showError('Please login and try again');
        }
    }

    /*  New project creation */
    createNewProject(all:any){
        var member_id  = localStorage.getItem('member_id');
        let data = {member_id:(member_id),video_title:all.new_video_title,action:this.encryptNew('newpro')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});
    }

    uploadFile(data:any) {
      //console.log('PIC');
      //console.log(data);
      const d = JSON.stringify(data);
      const h = new HttpHeaders({'Content-Type': 'application/json'});
        return this.httpClient.post('http://localhost:3000/upload', d,{headers: h});
    }

     createProject(all:any){
        let data = {member_id:this.encryptNew(all.member_id),project_id:all.project_id,project_title:all.project_title, type_of_edit:all.type_of_edit, duration_final_video:all.duration_final_video,footage_duration:all.footage_duration,purpose_of_final_video:all.purpose_of_final_video,final_video_format:all.final_video_format,final_video_date:all.final_video_date,comments:all.comments, duration_final_video_value:all.duration_final_video_value, duration_final_video_hour:all.duration_final_video_hour,duration_final_video_minutes:all.duration_final_video_minutes, duration_final_video_seconds:all.duration_final_video_seconds,if_type_of_edit_others:all.other_type_of_edit,footage_duration_hour:all.footage_duration_hour,footage_duration_minutes:all.footage_duration_minutes,footage_duration_seconds:all.footage_duration_seconds,is_url_shared:all.is_url_shared,video_url:all.video_url,video_password:all.video_password, action:this.encryptNew('newvideo')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }
    videostepTwoUpdate(all:any){
        let data = {action:this.encryptNew('newvideo_step_2'),project_id:all.project_id,member_id:this.encryptNew(all.member_id),color_grading:all.project.color_grading,project_opening_title_option:all.project.project_opening_title_option,project_closing_title_option:all.project.project_closing_title_option,project_opening_title:all.project.project_opening_title,project_closing_title:all.project.project_closing_title,project_other_text_option:all.project.project_other_text_option,project_other_text:all.project.project_other_text,project_additional:all.project.project_additional};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});          
    }
    projectCheck(all:any){
        let data = {action:this.encryptNew('projectCheck'),project_id:all.project_id,member_id:this.encryptNew(all.member_id)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});       
    }

    stepOne(all:any){
        let data = {member_id:this.encryptNew(all.member_id),project_title:all.project.project_title,action:this.encryptNew('14'),project_files:all.project.project_files,project_id:all.project_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }    

    stepOneUpdate(all:any){
        let data = {member_id:this.encryptNew(all.member_id),project_title:all.project.project_title,action:this.encryptNew('14'),project_files:all.no_images,project_id:all.project_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    stepTwoUpdate(all:any){
        //console.log(all.no_images);
        let data = {member_id:this.encryptNew(all.member_id),project_style:all.project.project_style,project_speed:all.project.project_speed,project_music_selection:all.project.project_music_selection,action:this.encryptNew('15'),project_files:all.no_images,project_id:all.project_id,project_music_file:all.project.project_music_file};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    stepOneCheck(all:any){
        let data = {action:this.encryptNew('16'),project_id:all.project_id,member_id:this.encryptNew(all.member_id)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});       
    }

    stepThreeUpdate(all:any){
        let data = {action:this.encryptNew('17'),project_id:all.project_id,member_id:this.encryptNew(all.member_id),project_video_duration:all.project.project_video_duration,project_opening_title_option:all.project.project_opening_title_option,project_closing_title_option:all.project.project_closing_title_option,project_opening_title:all.project.project_opening_title,project_closing_title:all.project.project_closing_title,project_other_text_option:all.project.project_other_text_option,project_other_text:all.project.project_other_text,project_additional:all.project.project_additional};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});          
    }

    stepFourRecords(all:any){
        let data = {action:this.encryptNew('18'),project_id:all.project_id,member_id:this.encryptNew(all.member_id)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    videoImage(all:any){
        let data = {action:this.encryptNew('19'),project_id:all.project_id,member_id:this.encryptNew(all.member_id)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    videoImageUpdate(all:any){
        let data = {action:this.encryptNew('20'),project_id:this.encryptNew(all.project.project_id),member_id:this.encryptNew(all.member_id)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});       
    }

    secondsToTime(secs)
    {
        secs = Math.round(secs);
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        let result = (hours < 10 ? "0" + hours : hours);
          result += ":" + (minutes < 10 ? "0" + minutes : minutes);
          result += ":" + (seconds  < 10 ? "0" + seconds : seconds);
        return result;
    }

    settings(all:any){
        let data = {action:this.encryptNew('21')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }

    checkUserExistPayment(all:any){
        let data = {action:this.encryptNew('22'),project_id:all.project_id,member_id:this.encryptNew(all.member_id)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }

    ApplyPromo(all:any){
        let data = {action:this.encryptNew('33'),promo_code:all.promo_code,member_id:all.member_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }
    cancel_job(all:any){

      
        let data = {action:this.encryptNew('cancel-job'),project_id:all.project_id,member_id:this.encryptNew(all.member_id)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }
    accept_job(all:any){

      
        let data = {action:this.encryptNew('accept-job'),project_id:all.project_id,member_id:this.encryptNew(all.member_id)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }
    enquiry_job(all:any){

      
        let data = {action:this.encryptNew('enquiry-job'),project_id:all.project_id,message:all.message,member_id:all.member_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }
final_payment(all:any){
console.log(all);
        let promo_code = all.promo_code!='' && typeof all.promo_code!='undefined'?all.promo_code:'';
        let project_status=3;
        let data = {action:this.encryptNew('video_payment'),project_id:all.project_id,member_id:this.encryptNew(all.member_id),discount_amount:all.discount_amount,overall_net_amount:all.overall_net_amount,overall_total:all.overall_total,card_id:all.card_id,token_id:all.token_id,promo_id:all.promo_id,member_name:all.member_name,member_email:all.member_email,promo_code:promo_code,source_url:all.source_url,new_payable_amount:all.new_payable_amount,new_payment_status:all.new_payment_status,project_status:all.project_status,google_drive_link:all.google_drive_link};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }
video_payment(all:any){

        let promo_code = all.promo_code!='' && typeof all.promo_code!='undefined'?all.promo_code:'';
        let project_status=2;
        let data = {action:this.encryptNew('video_payment'),project_id:all.project_id,member_id:this.encryptNew(all.member_id),discount_amount:all.discount_amount,overall_net_amount:all.overall_net_amount,overall_total:all.overall_total,card_id:all.card_id,token_id:all.token_id,promo_id:all.promo_id,member_name:all.member_name,member_email:all.member_email,promo_code:promo_code,source_url:all.source_url,new_payable_amount:all.new_payable_amount,project_status:project_status};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }
    makePayment(all:any){

        let promo_code = all.promo_code!='' && typeof all.promo_code!='undefined'?all.promo_code:'';
        let data = {action:this.encryptNew('23'),project_id:all.project_id,member_id:this.encryptNew(all.member_id),discount_amount:all.discount_amount,overall_net_amount:all.overall_net_amount,overall_total:all.overall_total,card_id:all.card_id,token_id:all.token_id,promo_id:all.promo_id,member_name:all.member_name,member_email:all.member_email,promo_code:promo_code,new_payment_status:all.new_payment_status};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }

    existPayment(all:any){
        let data = {action:this.encryptNew('24'),project_id:all.project_id,member_id:this.encryptNew(all.member_id)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});        
    }

    projectFileSize(all:any){
        let data = {action:this.encryptNew('25'),project_id:all.project_id,member_id:this.encryptNew(all.member_id)};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});           
    }
    
    /* Change password for editor */
    changePwdEditor(all:any){

        let data = 
        {old_pwd: all.editor.current_password,new_password:all.editor.editor_password,editor_id:this.encryptNew(all.editor_id),action:this.encryptNew('26')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});
    }    


    /* Confirm job by editor */
    confirmJob(all:any){
        let data = 
        {assign_id: this.encryptNew(all.assign_id),action:this.encryptNew('29'),manager_email:all.current_manager_email,project_title:all.current_project,project_id:all.current_project_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});
    }

    /* Project Details */
    projectDetails(all:any){
        let j = (all.job_records.assign_project_id);
        j = j.toString();
        let data = {member_id:this.encryptNew('1'),project_id:this.encryptNew(j),action:this.encryptNew('18')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});         
    }

    projectRecord(all:any){
        let j = (all);
        j = j.toString();
        let data = {member_id:this.encryptNew('1'),project_id:this.encryptNew(j),action:this.encryptNew('18')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});         
    }

    /* Download Server files */
    downloadServerFiles(all:any){
        let j = (all.job_records.assign_project_id);
        j = j.toString();
        let data = {project_id:this.encryptNew(j),action:this.encryptNew('30')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});            
    }

    /* Download Server files */
    downloadServerFilesMember(all:any){
        let j = (all);
        j = j.toString();
        let data = {project_id:this.encryptNew(j),action:this.encryptNew('30')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});            
    }

    /* Submit project status by editor */
    submitProject(all:any){
        
        let data = {project_id:all.job_records.assign_project_id,assign_id:all.job_records.assign_id,editor_id:all.job_records.assign_editor_id,manager_id:all.job_records.assign_manager_id,member_id:all.job_records.assign_member_id,action:this.encryptNew('31'),pr_file:all.submit_project.pr_file,pr_status_by_editor:all.submit_project.pr_status_by_editor,pr_comments_by_editor:all.submit_project.pr_comments_by_editor,manager_email:all.job_records.manager_email,editor_name:all.job_records.editor_name,project_title:all.project.project_title};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    /* FREE REQUEST */
    requestProjectEditor(all:any){
        let data = {project_id:all.job_records.assign_project_id,editor_id:all.job_records.assign_editor_id,manager_id:all.job_records.assign_manager_id,member_id:all.job_records.assign_member_id,action:this.encryptNew('39'),request_file:all.request_project.request_file,request_project_status_by_editor:all.request_project.request_project_status_by_editor,request_comment_editor:all.request_project.request_comment_editor,manager_email:all.job_records.manager_email,editor_name:all.job_records.editor_name,project_title:all.project.project_title};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    paymentDoneAlready(all:any){
        let j = this.decryptNew(all.project_id);

        const qry = 'SELECT * FROM '+this.tablenames.project_payment+' WHERE 1=1 AND payment_status = 1 AND payment_project_id = "'+j+'" ';
        let data = {r: (qry),action:this.encryptNew(this.tablenames.action2)};
        const d = JSON.stringify(data);
        
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h})
        .subscribe((resp: any) => {
            //console.log('PAYMENT CHECK');
            //console.log(resp);
            if(typeof resp.result[0]!='undefined' && resp.result[0]['payment_id']!='' ){
                this.router.navigate(['/index']);
            }
            
        }, (errorResponse: HttpErrorResponse) => {
              /*e = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again';
              alert();*/
        });
    }

    requestProjectEdit(all:any){
        let data = {project_id:all.request_project_id,manager_id:all.member_records.manager_id,message:all.request_message,action:this.encryptNew('34'),member_id:all.project.project_member_id,manager_email:all.member_records.manager_email,project_title:all.project.project_title};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    requestForList(all:any){
        let data = {project_id:this.decryptNew(all.project_id),action:this.encryptNew('35')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h}); 
    }

    memberLogin(all:any){
        let data = {username:all.username,password:this.encryptNew(all.password),action:this.encryptNew('46')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }

    editorLogin(all:any){
        let data = {username:all.username,password:all.password,action:this.encryptNew('editorL')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }

    forgotPassword(all:any){
        let data = {email:(all.forgot_email),type:all.forgot_type,action:this.encryptNew('37')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});    
    }

    paymentStripe(all:any){
        let data = {token_id:all.token_id,overall_net_amount:all.overall_net_amount,card_id:all.card_id,action:this.encryptNew('paymentStripe')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h}); 
    }
    
    contactSubmit(all:any){
        let data = {contact_first_name:(all.contact.contact_first_name).trim(),
            contact_last_name:(all.contact.contact_last_name).trim(),
            contact_company_name:(all.contact.contact_company_name).trim(),
            contact_email:(all.contact.contact_email).trim(),
            contact_phone:(all.contact.contact_phone).trim(),
            contact_message:(all.contact.contact_message).trim(),
            contact_postal:(all.contact.contact_postal).trim(),
            contact_country:(all.contact.contact_country).trim(),
            contact_support:(all.contact.contact_support).trim(),
            action:this.encryptNew('41')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }

    supportSubmit(all:any){
        let data = {contact_first_name:(all.contact.contact_first_name).trim(),
            contact_last_name:(all.contact.contact_last_name).trim(),
            contact_company_name:(all.contact.contact_company_name).trim(),
            contact_email:(all.contact.contact_email).trim(),
            contact_phone:(all.contact.contact_phone).trim(),
            contact_message:(all.contact.contact_message).trim(),
            contact_postal:(all.contact.contact_postal).trim(),
            contact_country:(all.contact.contact_country).trim(),
            contact_support:(all.contact.contact_support).trim(),
            action:this.encryptNew('49')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});     
    }

    galleryUpdates(all:any){
        let data = {member_id:this.encryptNew(all.member_id),project_title:all.project.project_title,action:this.encryptNew('14'),project_files:all.no_images,project_id:all.project_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});   
    }

    checkUserExists(member_id){
        let data = {member_id:member_id,action:this.encryptNew('52')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});       
    }


    finalVideoDelete(all:any)
    {
        let pr = all.final_pr_id.toString();
        let data = {pr_id:this.encryptNew(pr),action:this.encryptNew('44'),file_name:all.final_output_file};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});       
    }

    finalrequestVideoDelete(all:any){
        let request_pr_id = all.request_pr_id.toString();
        let pr = all.final_pr_id.toString();
        let data = {request_pr_id:this.encryptNew(request_pr_id),action:this.encryptNew('45'),request_output_file:all.request_output_file};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});           
    }

    s3DownloadFile(all:any){
        let data = {action:this.encryptNew('50'),filename:all.filename,filepath:all.filepath};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h}); 
    }

    /* S3 */
    getpresignedurls(all:any){
        
        let data = {action:this.encryptNew('51'),type_image:all.type_image,image_rename:all.image_rename,project_id:all.project.project_id};
        
        let d = JSON.stringify(data);
        
        const h = new HttpHeaders({'Content-Type': 'application/json'});

        return this.httpClient.post(`${this.baseURL}`,d, { headers: h});
    }

    uploadfileAWSS3(fileuploadurl, contenttype, file): Observable<any>{ 
        //this will be used to upload all files to AWS S3
        //console.log('AWS upload');
        const headers = new HttpHeaders({'Content-Type': contenttype});
        const req = new HttpRequest(
            'PUT',
            fileuploadurl,
            file,
        {
            headers: headers,
            reportProgress: true, //This is required for track upload process
        });
        return this.httpClient.request(req);
    }

    faq(){
        let data = {action:this.encryptNew('53')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }

    mapprove(all:any){
        let data = {action:this.encryptNew('54'),member_id:all.member_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }
    joblist(all:any){
        let data = {action:this.encryptNew('job-list'),member_id:all.member_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }
    enquirymessage(all:any){
        let data = {action:this.encryptNew('enquiry-message'),member_id:all.member_id,project_id:all.project_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }

    allocateRecords(all:any){
        let data = {action:this.encryptNew('55'),project_id:this.decryptNew(all.project_id),member_id:all.member_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }

    projectStatus(all:any){
        let data = {action:this.encryptNew('56'),project_id:this.decryptNew(all.project_id),member_id:all.member_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }
    requestCompletedJob(all:any){
        let data = {action:this.encryptNew('completed-project'),project_id:all.project_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }
    requestCompletedStatus(all:any){
        let data = {action:this.encryptNew('57'),project_id:this.decryptNew(all.project_id),member_id:all.member_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }

    
    galleryRecords(all:any){
        let data = {action:this.encryptNew('58'),project_id:this.decryptNew(all.project_id),member_id:all.member_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }
    
    
    completedProject(all:any){
        let data = {action:this.encryptNew('59'),member_id:all.member_id};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }

    countryCode(){
        let data = {action:this.encryptNew('60')};
        let d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return  this.httpClient.post(`${this.baseURL}`, d,{headers: h});  
    }
}
