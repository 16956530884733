import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateMemberComponent implements OnInit {
  records = '';
  errorMsg = '';
  query = '';
  table = '';
  mem_id = '';
  active_success ='';
  active_error ='';
  constructor(private route: ActivatedRoute,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService) { }

  ngOnInit() { 	
    this.table = this.tablenames.member;
    this.route.params.subscribe(params => {
      this.mem_id = params['mem_id'];
    });

    if(this.mem_id!='' && typeof this.mem_id!='undefined'){
      
      this.httpService.memberActivate(this)
        .subscribe((resp: any) => {
        if(resp.result.result!='' && typeof resp.result.result!='undefined' && resp.result.result=='error' ){
          this.userService.showError(JSON.stringify(resp.result.message));
          this.active_error = resp.result.message;
          this.active_success = '';
        }
        else if(resp.result.result!='' && typeof resp.result.result!='undefined' && resp.result.result=='success' ){
          this.active_error = '';
          this.userService.showSuccess('Thanks for registering with editube. Please login.');
          this.active_success = 'Thanks for registering with editube. Please login.';
          this.router.navigate(['/login']);
        }
        
      }, (errorResponse: HttpErrorResponse) => {
          this.active_error = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
      });  
    }else
      this.active_error = 'Member id is not avilable';
    
    //console.log(this.httpService.encryptNew('23'));
    
    //U
  }
}
