import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})

export class FaqComponent implements OnInit {
  
	faq_records = '';
	query ='';
	table = '';
  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient) { }



  ngOnInit() { 	
    //this.table = this.tablenames.faq_table;
    //this.query = 'SELECT * FROM '+this.table+' WHERE 1=1 '; 
          
    this.httpService.faq()
      	.subscribe((resp: any) => {
    	this.faq_records = resp.result;
    }, (errorResponse: HttpErrorResponse) => {
          //console.log(errorResponse);
          
    });  
    
  }

}
