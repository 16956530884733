import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
//import {HttpErrorResponse} from '@angular/common/http';

import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

/* Table Name */
import { Tablename } from '../app.tablename'; 

import { NgxUiLoaderService } from 'ngx-ui-loader';

function htmlToPlaintext(text) {
  return text ? String(text).replace(/<[^>]+>/gm, '') : '';
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  records = '';
  sample_videos = '';
  errorMsg = '';
  query = '';
  table = '';
  video_limit ='';
  title0 ='';
  desc0 = '';

  title1 ='';
  desc1 = '';
  
  title2 ='';
  desc2 = '';

  title3 ='';
  desc3 = '';

  title4 ='';
  desc4 = '';

  title5 ='';
  desc5 = '';

  title6 ='';
  desc6 = '';

  title7 ='';
  desc7 = '';

  title8 ='';
  desc8 = '';

  title9 ='';
  desc9 = '';

  title10 ='';
  desc10 = '';

  title11 ='';
  desc11 = '';

  title12 ='';
  desc12 = '';

  title13 ='';
  desc13 = '';

  title14 ='';
  desc14 = '';

  setting = {
    new_user_promocode:'',
    new_user_discount_percentage:'',
    base_amount :''
  };

  constructor(private router: Router,public httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private userService:UserService,private ngxService: NgxUiLoaderService) { }

  settings(){
    
    this.httpService.settings(this)
      .subscribe((resp: any) => {
      if(resp.result!=''){
        //console.log('SEEINGS');
        //console.log(resp);
        resp = resp.result.records[0];
        this.setting.new_user_promocode = resp.new_user_promocode;
        this.setting.new_user_discount_percentage = resp.new_user_discount_percentage;
        this.setting.base_amount = resp.base_amount;

      }
    }, (errorResponse: HttpErrorResponse) => {
       
        
    });
  }

  ngOnInit() { 	
  console.log('First check 47');
    this.httpService.encryptNew(47);

    this.table = this.tablenames.cms;

    this.query = 'SELECT * FROM '+this.table+' WHERE 1=1 AND cms_id BETWEEN 1 AND 20 '; 
    

    this.httpService.commonFetch(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.title0 = resp.result[0].cms_title;
      this.desc0 = resp.result[0].cms_desc1;

      this.title1 = resp.result[1].cms_title;
      this.desc1 = resp.result[1].cms_desc1;

      this.title2 = resp.result[2].cms_title;
      this.desc2 = resp.result[2].cms_desc1;

      this.title3 = resp.result[3].cms_title;
      this.desc3 = resp.result[3].cms_desc1;

      this.title4 = resp.result[4].cms_title;
      this.desc4 = resp.result[4].cms_desc1;

      this.title5 = resp.result[5].cms_title;
      this.desc5 = resp.result[5].cms_desc1;

      this.title6 = resp.result[6].cms_title;
      this.desc6 = resp.result[6].cms_desc1;

      this.title7 = resp.result[7].cms_title;
      this.desc7 = resp.result[7].cms_desc1;

      this.title8 = resp.result[8].cms_title;
      this.desc8 = resp.result[8].cms_desc1;

      this.title9 = resp.result[9].cms_title;
      this.desc9 = resp.result[9].cms_desc1;

      this.title10 = resp.result[10].cms_title;
      this.desc10 = resp.result[10].cms_desc1;

      this.title11 = resp.result[11].cms_title;
      this.desc11 = resp.result[11].cms_desc1;

      this.title12 = resp.result[12].cms_title;
      this.desc12 = resp.result[12].cms_desc1;

      this.title13 = resp.result[13].cms_title;
      this.desc13 = resp.result[13].cms_desc1;

      this.title14 = resp.result[14].cms_title;
      this.desc14 = resp.result[14].cms_desc1;
      
      this.records = resp.result;
    }, (errorResponse: HttpErrorResponse) => {

          //console.log(errorResponse);
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
    });

    this.settings();
    this.video_limit = '3';
    
    this.httpService.videoCategory(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.sample_videos = resp.result;
    }, (errorResponse: HttpErrorResponse) => {
          //console.log(errorResponse);
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
    });
    
    //this.ngxService.start();

  }

}
