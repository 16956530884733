import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
@Component({
  selector: 'app-video-samples',
  templateUrl: './video-samples.component.html',
  styleUrls: ['./video-samples.component.scss']
})

export class VideosamplesComponent implements OnInit {
  records = '';
  query = '';
  table = '';
  sample_videos = '';
  errorMsg = '';
  
  constructor(private route: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient) { }

  ngOnInit() { 	
    $('#navbarSupportedContent').trigger('click');

    this.httpService.videoCategory(this)
      .subscribe((resp: any) => {
      //console.log(resp);
      this.sample_videos = resp.result;
    }, (errorResponse: HttpErrorResponse) => {
          //console.log(errorResponse);
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
    });
    
  }

}
