import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders,HttpRequest,HttpEvent} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import {ActivatedRoute} from '@angular/router';

import {FileUploader} from "ng2-file-upload";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";

import { ToastrManager } from 'ng6-toastr-notifications';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import * as AWS from 'aws-sdk';

declare var TextDecoder;

import { Injectable } from '@angular/core';

import { VideoHours } from '../video-hours.pipe';
import { formatDate } from '@angular/common';



@Component({
  selector: 'app-new-video',
  templateUrl: './new-video.component.html',
  styleUrls: ['./new-video.component.css']
})
export class NewVideoComponent implements OnInit {
   records = '';
  errorMsg = '';
  query = '';
  table = '';
  title = '';
  isError = '';
  project_id = '';
  member_id = '';
  no_images = '';
  gallery = '';
  server_url = '';
  load_process = '';
  gallery_table = '';
  primary_id = '';

  project_title='';
  type_of_edit='';
  other_type_of_edit='';
  duration_final_video='2';
  duration_final_video_value='';
  footage_duration='';
  footage_duration_hour='';
  footage_duration_minutes='';
  footage_duration_seconds='';
  duration_final_video_hour='';
  duration_final_video_minutes='';
  duration_final_video_seconds='';
  is_url_shared='';
  video_url='';
  video_password='';

  comments='';
  final_video_date='';
  final_video_format='';
  purpose_of_final_video='';
  status=0;
  constructor(private router: Router,public httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private route: ActivatedRoute,public userService:UserService,private fb: FormBuilder,private ngxService: NgxUiLoaderService) { }
  ngOnInit() {

this.member_id = localStorage.getItem('member_id');

 this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });
    this.httpService.checkUserExists(this.member_id).subscribe((resp: any) => {
      //console.log('CHECK EXISTSSSSSSSSSSSS');
      if(resp.result!=''  && typeof resp.result[0]['member_id']!='undefined'){
        //console.log('INSIDE');
        ////console.log(resp);
      }else{
        //console.log('LOGOUT PLZ');
        this.httpService.logoutUser();
        window.location.href='/#/index';
      }
    }, (errorResponse: HttpErrorResponse) => {
          
          let a = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(a) ;
    });
  this.stepOneCheck();

  }

stepOneCheck(){
    this.ngxService.start(); 
    this.httpService.projectCheck(this)
      .subscribe((resp: any) => {
      this.ngxService.stop(); 
        this.project_title = resp.result.result1[0].project_title;
        this.duration_final_video = resp.result.result1[0].duration_final_video;
        this.duration_final_video_value = resp.result.result1[0].duration_final_video_value;
        this.purpose_of_final_video = resp.result.result1[0].purpose_of_final_video;
        this.final_video_format = resp.result.result1[0].final_video_format;
        this.type_of_edit = resp.result.result1[0].type_of_edit;
        this.other_type_of_edit = resp.result.result1[0].if_type_of_edit_others;
        this.duration_final_video_hour = resp.result.result1[0].duration_final_video_hour;
        this.duration_final_video_minutes = resp.result.result1[0].duration_final_video_minutes;
        this.duration_final_video_seconds = resp.result.result1[0].duration_final_video_seconds;

        this.footage_duration_hour = resp.result.result1[0].footage_duration_hour;
        this.footage_duration_minutes = resp.result.result1[0].footage_duration_minutes;
        this.footage_duration_seconds = resp.result.result1[0].footage_duration_seconds;


        this.is_url_shared = resp.result.result1[0].is_url_shared;
        this.video_url = resp.result.result1[0].video_url;
        this.video_password = resp.result.result1[0].video_password;




        this.footage_duration = resp.result.result1[0].footage_duration;
        this.status=resp.result.result1[0].status;
       // this.final_video_date = resp.result.result1[0].final_video_date.toISOString().slice(0,10);
        //alert(this.final_video_date);
        this.final_video_date = formatDate(resp.result.result1[0].final_video_date, 'yyyy-MM-dd', 'en-US');
        this.comments = resp.result.result1[0].comments;
        
    }, (errorResponse: HttpErrorResponse) => {
    this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }


  steponeSubmit(nstep){
    //alert(this.type_of_edit);
    if($.trim(this.project_title)==''){
      this.userService.showError('Enter title') ;
    } else if ($.trim(this.type_of_edit)==''){
      this.userService.showError('Select Type of Edit') ;
    }else if ($.trim(this.footage_duration_hour)==''){
      this.userService.showError('Enter Footage Hour') ;
    }else if ($.trim(this.footage_duration_minutes)==''){
      this.userService.showError('Enter Footage Minutes') ;
    }else if ($.trim(this.footage_duration_seconds)==''){
      this.userService.showError('Enter Footage Seconds') ;
    }else if ($.trim(this.is_url_shared)==''){
      this.userService.showError('Select Footage Share Option') ;
    }else if ($.trim(this.is_url_shared)=='1' && $.trim(this.video_url)==''){

      this.userService.showError('Enter Footage URL') ;
    }else if ($.trim(this.duration_final_video)==''){

      this.userService.showError('Select Duration of Final Video') ;
    }else if ($.trim(this.purpose_of_final_video)==''){

      this.userService.showError('Select Purpose of Final Video') ;
    }else if ($.trim(this.final_video_format)==''){

      this.userService.showError('Select Final Video Format') ;
    }
    else
    {
        this.ngxService.start(); 
        this.httpService.createProject(this).subscribe((resp: any) => {
        //console.log(resp);
        this.ngxService.stop(); 
        if(resp.result!='' ){
          //this.httpService.videoImageUpdate(this);
          
          //if(this.project_id=='')
          if(resp.result.insertId!=0)
          this.project_id=resp.result.insertId;
          this.userService.showSuccess('Step 1 completed successfully');
          nstep=1;
          if(nstep==1)
            this.router.navigate(['/video-step-two/'+this.project_id]);

        }else if(resp.result=='error')
          this.userService.showError(' file not received');
      }, (errorResponse: HttpErrorResponse) => {
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(this.isError) ;
      });


    }
    
  }

}
