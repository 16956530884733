import { Component, OnInit } from '@angular/core';


export class Tablename {
constructor() {}
/* Table name */    
  /* 1- live server, 2-local server */
  

  /* LIVE SERVER */
    
    // Editube Stage
    /* */
    //readonly adminAppUrl: string = 'https://www.stage.editube.com:3000/admin-ajax';
   // readonly adminAppUrl: string = 'http://editube.dreammediaa.com.sg/api/api';
   readonly adminAppUrl: string = 'https://editube.com/api/api';
    readonly baseAPIUrl: string = 'https://api.github.com/';
    readonly upload_url = 'https://www.stage.editube.com:3000/api/upload';
    readonly server_assets = 'https://www.stage.editube.com/api/assets/';
    readonly action:string = 'pneumonoultramicroscopicsilicovolcanoconiosis';
    readonly action2:string = 'pneumonoultramicroscopicsilicovolcanoconiosis2';
    readonly image_upload:string = 'https://www.stage.editube.com:3000/api/upload';
    readonly server_url:string = 'https://www.stage.editube.com/api/';
    readonly upload_gallery = 'https://www.stage.editube.com:3000/upload';
    readonly upload_music = 'https://www.stage.editube.com:3000/music-file';
    readonly s3upload = 'https://www.stage.editube.com:3000/s3upload';
    readonly s3url = 'https://s3-ap-southeast-1.amazonaws.com/editubestage/';
    readonly thumb_url = 'https://www.stage.editube.com/api/thumb/';
     readonly node_url: string = 'https://www.stage.editube.com:3000/';
   // readonly baseURL:string = 'https://www.stage.editube.com:3000/admin-ajax';
    
    //readonly node_url: string = 'http://localhost:3000/';
  // readonly baseURL:string = 'http://localhost:3000/admin-ajax';
  //  readonly baseURL:string = 'http://editube.dreammediaa.com.sg/api/api';
  readonly baseURL:string = 'https://editube.com/api/api';
   //readonly baseURL:string = 'http://localhost:3000/admin-ajax';
    

     // Editube Live
    /*  
    readonly adminAppUrl: string = 'https://www.editube.com:3000/admin-ajax';
    readonly baseAPIUrl: string = 'https://api.github.com/';
    readonly upload_url = 'https://www.editube.com:3000/api/upload';
    readonly server_assets = 'https://www.editube.com/api/assets/';
    readonly action:string = 'pneumonoultramicroscopicsilicovolcanoconiosis';
    readonly action2:string = 'pneumonoultramicroscopicsilicovolcanoconiosis2';
    readonly image_upload:string = 'https://www.editube.com:3000/api/upload';
    readonly server_url:string = 'https://www.editube.com/api/';
    readonly upload_gallery = 'https://www.editube.com:3000/upload';
    readonly upload_music = 'https://www.editube.com:3000/music-file';
    readonly s3upload = 'https://www.editube.com:3000/s3upload';
    readonly s3url = 'https://s3-ap-southeast-1.amazonaws.com/editube/';
    readonly thumb_url = 'https://www.editube.com/api/thumb/';
    readonly node_url: string = 'https://www.editube.com:3000/';
    readonly baseURL:string = 'https://www.editube.com:3000/admin-ajax';
    */
    

    // testing server
    /* 
    readonly adminAppUrl: string = 'http://3.1.111.192:3000/admin-ajax';
    readonly baseAPIUrl: string = 'https://api.github.com/';
    readonly upload_url = 'http://3.1.111.192:3000/api/upload';
    readonly server_assets = 'https://www.editube.com/api/assets/';
    readonly action:string = 'pneumonoultramicroscopicsilicovolcanoconiosis';
    readonly action2:string = 'pneumonoultramicroscopicsilicovolcanoconiosis2';
    readonly image_upload:string = 'http://3.1.111.192:3000/api/upload';
    readonly server_url:string = 'http://3.1.111.192/api/';
    readonly upload_gallery = 'http://3.1.111.192:3000/upload';
    readonly upload_music = 'http://3.1.111.192:3000/music-file';
    readonly s3upload = 'http://3.1.111.192:3000/s3upload';
    readonly s3url = 'https://s3-ap-southeast-1.amazonaws.com/editube/';
    readonly thumb_url = 'http://3.1.111.192/api/thumb/';
    readonly node_url: string = 'http://3.1.111.192:3000/';
    readonly baseURL:string = 'http://3.1.111.192:3000/admin-ajax';
  */  

  /* LOCAL SERVER  
    readonly adminAppUrl: string = 'http://localhost:3000/admin-ajax';
    readonly baseAPIUrl: string = 'https://api.github.com/';
    readonly upload_url = 'http://localhost:3000/api/upload';
    readonly server_assets = 'http://192.168.1.99/editube-service/assets/';
    readonly action:string = 'pneumonoultramicroscopicsilicovolcanoconiosis';
    readonly image_upload:string = 'http://localhost:3000/api/upload';
    readonly server_url:string = 'http://192.168.1.99/editube-service/';
    readonly upload_gallery = 'http://localhost:3000/upload';
    readonly upload_music = 'http://localhost:3000/music-file';
  */

  readonly mb_size:number = 1024;
  readonly gb_size:number = 1024 * 1024 * 1024 ;

  readonly prefiex : string = 'et_';
  readonly cms: string = 'et_cms';
  readonly video_category: string = 'et_video_category';
  readonly video: string = 'et_video';
  readonly training_category: string = 'et_training_category';
  readonly training: string = 'et_training';
  readonly style: string = 'et_style';
  readonly country: string = 'et_country';
  readonly speed: string = 'et_space_speed';
  readonly music_category: string = 'et_music_category';
  readonly music: string = 'et_music';
  readonly banner: string = 'et_banner';
  readonly member: string = 'et_member';
  readonly editor: string = 'et_editor';
  readonly manager: string = 'et_manager';
  readonly promo: string = 'et_promocode';
  readonly assign: string = 'et_assign';
  readonly admin: string = 'et_admin';
  readonly settings: string = 'et_settings';
  readonly package: string = 'et_package';
  readonly project: string = 'et_project';
  readonly project_gallery: string = 'et_project_gallery';
  readonly project_allocate_pm:string = 'et_project_allocate_pm';
  readonly project_assign_editor:string = 'et_project_assign_editor';
  readonly project_submit_editor:string = 'et_project_submit_editor';
  readonly project_payment = 'et_payment';
  readonly request_member = 'et_request_member';
  readonly request_pm_editor = 'et_request_pm_editor';
  readonly country_code_table = 'et_countrycode';
  readonly faq_table = 'et_faq';


  /* Listing page limit */
  readonly page_length: number = 10;
  
  ngOnInit() {  
    
  }
}
