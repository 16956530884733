import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import { ToastrManager } from 'ng6-toastr-notifications';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-video-final',
  templateUrl: './video-final.component.html',
  styleUrls: ['./video-final.component.css']
})
export class VideoFinalComponent implements OnInit {

   records = '';
  errorMsg = '';
  query = '';
  table = '';
  title = '';
  isError = '';
  project_id = '';
  member_id = '';
  no_images = '';
  gallery = '';
  server_url = '';
  load_process = '';
  gallery_table = '';
  primary_id = '';
  project = {
    project_id:'',
    project_title:'',
    type_of_edit:'',
    duration_final_video:'',
    duration_final_video_value:'',
    footage_duration:'',
    purpose_of_final_video:'',
    final_video_format:'',
    final_video_date:'',
    comments:'',
    project_style:'',
    project_speed:'',
    project_music_selection:'',
    project_music_file:'',
    project_video_duration:0,
    project_opening_title:'',
    
    project_closing_title:'',
    project_other_text:'',
    project_opening_title_option:'',
    project_closing_title_option:'',
    project_other_text_option:'',
    project_additional:'',
  };

  current_video_duration = 0;

  data_id = '';
  style_records = '';
  speed_records = '';
  music_records = '';
  music_category = '';
  editube_music = '';
  editube_music_name = '';
  editube_music_file = ''; 
  existing_music = '';
  current_record = '';
  step1_url ='';
  step2_url ='';
  step3_url ='';
  step4_url = '';
  step5_url = '';
  minutes = [1,2,3,4,5,6,7,8,9,10];
  check_image_exist ='';
  total_video  = '';
  total_image  = 0;
  choosen_editube_music = '';
  gallery_audio = '';
  project_id_decrypt = '';
  gallery_all = '';
  total_min = 0;
  total_size = 0;
  current_min = 0;
  current_size = 0;
  total_video_min = '';

  base_amount = 0;
  max_video_length  = 0;
  max_picture = 0;
  max_final_video_length = 0;
  max_storage = 0;
  additional_video_length = 0;
  additional_video_length_price = 0;
  additional_picture =0;
  additional_picture_price = 0;
  additional_final_video_length =0;
  additional_final_video_length_price = 0;
  additional_storage_size = 0;
  additional_storage_size_price = 0;

  real_additional_video_price = 0;
  real_additional_video_min = '0';
  real_additional_image_price = 0;
  real_additional_image_count = 0;
  real_additional_final_video_price = 0;
  real_additional_final_video_min = '0';
  sub_total = 0;
  promo_code = '';
  overall_total = 0;
  overall_net_amount = 0;
  new_user_discount_percentage =0;
  discount_amount = 0;
  member_exists = 0;
  order_number =0;
  order_date = '';

  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private route: ActivatedRoute,private userService:UserService,private fb: FormBuilder,private ngxService: NgxUiLoaderService) { }

  
  stepOneCheck(){
    this.httpService.projectCheck(this)
      .subscribe((resp: any) => {
      /* 
      *   Checking condition for redirect step one page if it is null
      */
      if(resp.result.result1[0].project_title==''){
        this.userService.showError('Enter project title');
        this.router.navigate(['/new-project/'+this.project_id]);
      }
      
      else if(resp.result.project_opening_title_option==''){
        this.userService.showError('Select opening title');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.project_opening_title_option=='1' && this.project.project_opening_title=='' ){
        this.userService.showError('Enter opening title');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.project_closing_title_option==''){
        this.userService.showError('Select closing title');  
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_closing_title_option=='1' && this.project.project_closing_title=='' ){
        this.userService.showError('Enter closing title');    
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_other_text_option==''){
        this.userService.showError('Select text option'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_other_text_option=="1" && this.project.project_other_text=='' ){
        this.userService.showError('Enter text option'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.project_additional==''){
        this.userService.showError('Enter additional instructions'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else{
        //this.current_record = resp.result.result1[0];

        this.project.project_id = resp.result.result1[0].project_id;
        this.project.project_title = resp.result.result1[0].project_title;
        this.project.project_style = resp.result.result1[0].project_style;
        this.project.project_video_duration = resp.result.result1[0].project_video_duration;
        if(resp.result.result1[0].type_of_edit==1)
        this.project.type_of_edit = 'Documentary';
        else if(resp.result.result1[0].type_of_edit==2)
        this.project.type_of_edit = 'Promotional video';
        else if(resp.result.result1[0].type_of_edit==3)
        this.project.type_of_edit = 'Youtube Video';
        else if(resp.result.result1[0].type_of_edit==4)
        this.project.type_of_edit = 'Social Media (FB/Twitter / Insta / Tiktok etc)';
        else if(resp.result.result1[0].type_of_edit==5)
        this.project.type_of_edit = 'Drama';
        else if(resp.result.result1[0].type_of_edit==6)
        this.project.type_of_edit = 'Advertisement';
        else if(resp.result.result1[0].type_of_edit==7)
        this.project.type_of_edit = 'Family/Occasion';
        else if(resp.result.result1[0].type_of_edit==8)
        this.project.type_of_edit = 'Others';

        this.current_video_duration = resp.result.result1[0].project_video_duration;
        this.project.project_opening_title = resp.result.result1[0].project_opening_title_option==1?resp.result.result1[0].project_opening_title:'No Thanks';

        this.project.project_other_text_option = resp.result.result1[0].project_other_text_option==1?resp.result.result1[0].project_other_text:'No Thanks';

        this.project.project_closing_title = resp.result.result1[0].project_closing_title_option==1?resp.result.result1[0].project_closing_title:'No Thanks';

        this.project.project_additional = resp.result.result1[0].project_additional;

        this.project.project_music_selection = resp.result.result1[0].project_music_selection;

        this.project.project_music_file = resp.result.result1[0].project_music_file;
        this.order_date = resp.result.result1[0].created_date;
      //  this.existPayment();
        //this.videoImage();
      }
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  stepFourRecords(this){
    this.httpService.stepFourRecords(this).subscribe((resp: any) => {
      //console.log(resp);
      if(resp.result!='' ){
        this.userService.showSuccess('Forms submitted successfully');
        this.router.navigate(['/step-four/'+this.project_id]);
      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  



  

  

  
  
  existPayment(this){
    this.httpService.existPayment(this).subscribe((resp: any) => {
      //console.log(resp);
      if(resp.result!='' ){
        this.order_number = resp.result.records[0].payment_id;
        this.order_date = resp.result.records[0].created_date;
      }else if(resp.result=='error'){
        this.userService.showError(' file not received');
        this.router.navigate([this.step1_url]); 
      }
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }  

  ngOnInit() { 
    
    this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });
    
    this.member_id = localStorage.getItem('member_id');
    this.project_id_decrypt = this.project_id;
    
    this.stepOneCheck();
  
  }

}
