import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})

export class HowitworksComponent implements OnInit {
  query='';
  table='';
  records = '';
  errorMsg = '';
  title0 ='';
  desc0 = '';

  title1 ='';
  desc1 = '';
  
  title2 ='';
  desc2 = '';

  title3 ='';
  desc3 = '';
  constructor(private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService) { }

  ngOnInit() { 	



    //console.log(this.userService.isLoggedInM);

    this.table = this.tablenames.cms;
    this.query = 'SELECT * FROM '+this.table+' WHERE 1=1 AND cms_id BETWEEN 5 AND 10 '; 
          
    this.httpService.commonFetch(this)
      .subscribe((resp: any) => {
      
      this.records = resp.result;
      
      this.title0 = resp.result[0].cms_title;
      this.desc0 = resp.result[0].cms_desc1;

      this.title1 = resp.result[1].cms_title;
      this.desc1 = resp.result[1].cms_desc1;

      this.title2 = resp.result[2].cms_title;
      this.desc2 = resp.result[2].cms_desc1;

      this.title3 = resp.result[3].cms_title;
      this.desc3 = resp.result[3].cms_desc1;


    }, (errorResponse: HttpErrorResponse) => {
          //console.log(errorResponse);
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
    });  
  }

}
