import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { ToastrManager } from 'ng6-toastr-notifications';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";

@Component({
  selector: 'app-job-list-view',
  templateUrl: './job-list-view.component.html',
  styleUrls: ['./job-list-view.component.css']
})
export class JobListViewComponent implements OnInit {

   project_id = '';
  is_completed = '';
  final_file = '';
  member_id = '';
  isError='';
  query = '';
  server_url = '';
  job_records = '';
  member_records = '';
  request_project_id = '';
  request_message ='';
  assign_id = '';
  job_no = 0;
  as_p='';
  gallery = '';
  image = '';
  request_list = '';
  uploaded_status = '';
  request_complete = '';
  final_file_request = '';
  payment_made_already='';
  overall_net_amount=0;
  source_url='';
  new_payment_status=0;
  message='';
  google_drive_link='';
  
/* For payment */
  elements: Elements;
  card: StripeElement;

  token_id='';
  card_id ='';
  stripeTest: FormGroup;
  acceptForm: FormGroup;
  stripeFinalTest:FormGroup;
  enquiryForm:FormGroup;


  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(public toastr: ToastrManager,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService,private ngxService: NgxUiLoaderService,private route:ActivatedRoute,private stripeService: StripeService,private fb: FormBuilder,) { }
  
  selectedFile: File;
  
  /* For image upload */
  public uploader:FileUploader = new FileUploader({
    isHTML5: true,maxFileSize: 1024*1024*1024
  });

  
  editor ={
    editor_id:'',
    editor_name:'',
    editor_email:'',
    editor_mobiel:'',
    created_date:''
  }

  project ={
    project_id:'',
    project_title:'',
    type_of_edit:'',
    duration_final_video:'',
    duration_final_video_value:'',
    footage_duration:'',
    purpose_of_final_video:'',
    final_video_format:'',
    final_video_date:'',
    comments:'',
    project_style:'',
    project_space_speed:'',
    project_music_selection:'',
    project_music_file:'',
    project_video_duration:'',
    project_opening_title:'',
    project_closing_title:'',
    project_other_text:'',
    project_opening_title_option:'',
    project_closing_title_option:'',
    project_other_text_option:'',
    project_additional:'',
    style_name:'',
    speed_name:'',
    project_member_id:'',
    color_grading:'',
    delivery_date:'',
    google_drive_link:'',
    payable_amount:'',
    payment_status:0,
    payment_mode:0,
    discount:'',
    amount:'',
    other_info:'',
    status:0,
    new_payable_amount:'',
    first_cut:'',
    is_url_shared:'',
    video_url:'',
    video_password:'',
  }
  project_status=0;
  s3url = '';
  thumb_url = '';
  submit_project={
    pr_comments_by_editor:'',
    pr_status_by_editor:'',
    pr_file:'',
  }

  final_output_file = '';
  final_pr_id = '';

  request_output_file = '';
  request_pr_id = '';

  project_status_by_pm = '';


  viewJobs(ass_id){
    let s = ass_id.toString();
    s = this.httpService.encryptNew(s);
    this.router.navigate(['/approve-view/'+s]);
  } 

  addProject(){
    this.submit_project.pr_comments_by_editor ='';
    this.submit_project.pr_status_by_editor ='';
    this.submit_project.pr_file ='';
    $('#submit-project-popup').modal('show');
  }

  uploadFile(data: FormData) {
    return this.httpClient.post(this.tablenames.image_upload, data);
  }

  uploadFiles(){
    let data = new FormData();
    let fileItem = this.uploader.queue[0]._file;
    data.append('photo', fileItem);
    this.ngxService.start(); 
    this.uploadFile(data).subscribe((resp: any) => {
      if(resp.result=='success' )
        this.submit_project.pr_file = resp.filename;
      else if(resp.result=='')
        this.userService.showError( resp.message);

      this.ngxService.stop(); 
          
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop(); 
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  allocateRecords(this){
    this.ngxService.start();
      
      /*this.query = 'SELECT manager_name,manager_email,manager_mobile,project_title,payment_amount,payment_discount,payment_net_amount,payment_status,project_live_status,'+this.tablenames.project+'.created_date,project_id,manager_id FROM '+this.tablenames.project+' JOIN '+this.tablenames.manager+' ON manager_id = project_manager_id JOIN '+this.tablenames.project_payment+' ON payment_project_id = project_id WHERE 1=1  AND project_id = '+this.httpService.decryptNew(this.project_id)+'  ';  
      */

      this.httpService.allocateRecords(this)
        .subscribe((resp: any) => {
        


        this.member_records = resp.result[0];
        //this.job_records.assign_project_id = resp.result[0].project_id;
        //this.project_status_by_pm = this.member_records.project_status_by_pm;
        //console.log(resp.result);

        this.projectDetails()
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  finalVideoDelete(pr_id){

    if(pr_id!='' && typeof pr_id!='undefined')
    {
      if(confirm('Are you sure want to delete?')){
        this.final_pr_id = pr_id; 
        this.httpService.finalVideoDelete(this)
          .subscribe((resp: any) => {
          this.ngxService.stop();
          if(resp.result!=''){
            alert('Final output file is deleted');
            window.location.reload();
          }else{
            this.userService.showError(JSON.stringify(resp.message)) ;
          }
          
        }, (errorResponse: HttpErrorResponse) => {
            this.ngxService.stop();
            let isError = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again'
            alert(isError) ;
        });
      }
    }
  }

  finalrequestVideoDelete(){
    if(confirm('Are you sure want to delete?')){
        this.httpService.finalrequestVideoDelete(this)
          .subscribe((resp: any) => {
          this.ngxService.stop();
          if(resp.result!=''){
            alert('Re-edited final output file is deleted');
            window.location.reload();
          }else{
            this.userService.showError(JSON.stringify(resp.message)) ;
          }
          
        }, (errorResponse: HttpErrorResponse) => {
            this.ngxService.stop();
            let isError = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again'
            alert(isError) ;
        });
      }
  }

  projectStatus(this){
    /*this.query = 'SELECT * FROM '+this.tablenames.project_submit_editor+' WHERE 1=1  AND pr_project_id = '+this.member_records.project_id+' AND pr_status_by_pm = 1 ORDER BY pr_id DESC LIMIT 1'; */
    //console.log(this.query);
    this.httpService.projectStatus(this)
      .subscribe((resp: any) => {
      if(resp.result[0]!=''){
        this.uploaded_status = resp.result[0];
        this.final_output_file  = resp.result[0].pr_file;
        this.is_completed =  resp.result[0].pr_id!=''?resp.result[0].pr_id:'';
        if(resp.result[0].file_delete_by_member=='0'){
          this.final_file = this.s3url+'assets/project-output/'+resp.result[0].pr_file;
        }
        else{

        }
      }
      
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        alert(isError) ;
    }); 
  }

  downloadServerFiles(pr_id){
    this.as_p = pr_id;
    this.as_p = this.as_p.toString(); 
    if(this.as_p!='' && typeof this.as_p!='undefined'){
    this.ngxService.start();
      this.httpService.downloadServerFilesMember(this.as_p)
        .subscribe((resp: any) => {
        if(resp.result.result=='success'){
          this.userService.showSuccess('File is ready to download, please enable if pop-up is blocked in browswer ') ;
          location.href =this.server_url+'assets/project-output/'+this.as_p+'.zip';
          
        }else{
          this.userService.showError(resp.result.message) ;
        }
        
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
    }
  }

  /* Project Details */
    projectDetails(this){
      //let p  = this.httpService.decryptNew(this.project_id);

      this.httpService.projectCheck(this)
          .subscribe((resp: any) => {
            //console.log(resp);
            if(resp.result.result1[0]!='' ){
              resp = resp.result.result1[0];
              /* Assign value to field */
              this.project.project_id = resp.project_id;
              this.project.project_title = resp.project_title;
              this.project.project_opening_title = resp.project_opening_title;
              this.project.project_closing_title = resp.project_closing_title;
              this.project.project_other_text = resp.project_other_text;
              this.project.project_opening_title_option = resp.project_opening_title_option;
              this.project.project_closing_title_option = resp.project_closing_title_option;
              this.project.project_other_text_option = resp.project_other_text_option;
              this.project.project_additional = resp.project_additional;
              this.project.status = resp.status;
              this.project.is_url_shared = resp.is_url_shared;
              this.project.video_url = resp.video_url;
              this.project.video_password = resp.video_password;


               if(resp.type_of_edit==1)
        this.project.type_of_edit = 'Documentary';
        else if(resp.type_of_edit==2)
        this.project.type_of_edit = 'Promotional video';
        else if(resp.type_of_edit==3)
        this.project.type_of_edit = 'Youtube Video';
        else if(resp.type_of_edit==4)
        this.project.type_of_edit = 'Social Media (FB/Twitter / Insta / Tiktok etc)';
        else if(resp.type_of_edit==5)
        this.project.type_of_edit = 'Drama';
        else if(resp.type_of_edit==6)
        this.project.type_of_edit = 'Advertisement';
        else if(resp.type_of_edit==7)
        this.project.type_of_edit = 'Family/Occasion';
        else if(resp.type_of_edit==8)
        this.project.type_of_edit = 'Others -'+resp.if_type_of_edit_others;
        

        this.project.duration_final_video = resp.duration_final_video;
        if(resp.duration_final_video==1)
        this.project.duration_final_video_value = resp.duration_final_video_hour+'/'+resp.duration_final_video_minutes+'/'+resp.duration_final_video_seconds+ ' (Hour/Minutes/Seconds) ';
        else
        this.project.duration_final_video_value ='No limitation';


        this.project.footage_duration = resp.footage_duration_hour+'/'+resp.footage_duration_minutes+'/'+resp.footage_duration_seconds+ ' (Hour/Minutes/Seconds) ';
        if(resp.purpose_of_final_video==1)
        this.project.purpose_of_final_video = 'Broadcast';
        else if(resp.purpose_of_final_video==2)
        this.project.purpose_of_final_video = 'Web';
        else if(resp.purpose_of_final_video==3)
        this.project.purpose_of_final_video = 'Social Media';
        else if(resp.purpose_of_final_video==4)
        this.project.purpose_of_final_video = 'Personal ';


        if(resp.final_video_format==1)
        this.project.final_video_format = '2K';
        else if(resp.final_video_format==2)
        this.project.final_video_format = '4K';

        this.project.final_video_date = resp.final_video_date;
        this.project.comments = resp.comments;
       this.project.color_grading = resp.color_grading;
       this.project.delivery_date = resp.delivery_date;
       this.project.amount = resp.amount;
       this.project.discount = resp.discount;
       this.project.google_drive_link = resp.google_drive_link;
       this.project.other_info = resp.other_info;
       this.project.payable_amount = resp.payable_amount;
       this.project.payment_mode = resp.payment_mode;
       this.project.paid_amount = resp.paid_amount;
       this.project.total_amount = resp.total_amount;
       this.project.first_cut=resp.first_cut;

       if(resp.payable_amount==0)
       this.project.payment_status =2;
       else
       this.project.payment_status = resp.payment_status;
        if(resp.payment_status==2)
        this.requestCompletedJob()

       this.overall_net_amount=resp.payable_amount;
       this.new_payable_amount =  parseInt(resp.total_amount)-(parseInt(resp.payable_amount)+parseInt(resp.paid_amount)); 
      if(this.new_payable_amount==0)
      this.new_payment_status=2;
      else
      this.new_payment_status=1;
              
          }else{
              this.isError = true;
              this.errorMsg = 'Record is not available, please logout and login';
            }
        }, (errorResponse: HttpErrorResponse) => {
             // console.log(errorResponse);
              this.errorMsg = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again';
        });
    }
  downloadRequestFile(obj){
    let files = obj.srcElement.attributes.files.value;
    location.href =this.server_url+'assets/request/'+files;
  }
  

  galleryRecords(this){
    this.ngxService.start();
    /* Query for gallery records */
    //this.query = 'SELECT * FROM '+this.tablenames.project_gallery+' WHERE 1=1  AND gallery_project_id = '+this.member_records.project_id; 
    this.httpService.galleryRecords(this)
      .subscribe((resp: any) => {
      this.gallery = resp.result;
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        alert(isError) ;
    });
  }
  
  requestForList(){
    this.httpService.requestForList(this)
        .subscribe((resp: any) => {
        //console.log('REQUEST');
        //console.log(resp);
        if(resp.result==''){
          //alert(resp.result);
        }else if(resp.result!=''){

          this.request_list = resp.result;
          //console.log(resp);
        }
        
    }, (errorResponse: HttpErrorResponse) => {
          let a  = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
              alert(a);
    });
  }



  projectRequestPopup(pro_id){
    this.request_project_id = pro_id;
    $('#request-new-edit').modal('show');
  }
  requestProjectEdit(this){
    //console.log(this);
    this.ngxService.start();
    this.httpService.requestProjectEdit(this)
      .subscribe((resp: any) => {
      if(resp.result==''){
        this.userService.showError(resp.message) ;
      }else{
         alert('New request submitted successfully');
         window.location.reload();
      }
      this.ngxService.stop();
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop();
        let isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(isError) ;
    });

    
  }

requestCompletedJob(){
    this.ngxService.stop();
    
    //this.query = 'SELECT * FROM '+this.tablenames.request_pm_editor+' WHERE 1=1  AND request_project_id = '+this.project.project_id+' AND request_project_status_by_pm =1 '; 
    //console.log(this.query);
    
      this.httpService.requestCompletedJob(this)
        .subscribe((resp: any) => {
        //console.log('PM editor');
        //console.log(resp);

        if(resp.result!=''){
            this.request_output_file  = resp.result[0].request_file;
            this.final_file_request = resp.result[0].completed_url;
        }
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          alert(isError) ;
      });
  }

  requestCompletedStatus(){
    this.ngxService.stop();
    
    //this.query = 'SELECT * FROM '+this.tablenames.request_pm_editor+' WHERE 1=1  AND request_project_id = '+this.project.project_id+' AND request_project_status_by_pm =1 '; 
    //console.log(this.query);
    
      this.httpService.requestCompletedStatus(this)
        .subscribe((resp: any) => {
        //console.log('PM editor');
        //console.log(resp);

        if(resp.result!=''){
         
          this.request_complete = resp.result[0];
          this.request_pr_id = resp.result[0].request_id;
          if(resp.result[0].file_delete_by_member!='1'){
            this.request_output_file  = resp.result[0].request_file;
            this.final_file_request = this.s3url+'assets/project-output/'+resp.result[0].request_file;
          }
          
        }
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          alert(isError) ;
      });
  }

  ngOnInit() { 	

    this.member_id = localStorage.getItem('member_id');
    this.s3url = this.tablenames.s3url;
    this.thumb_url = this.tablenames.thumb_url;
    this.server_url = this.tablenames.server_url;
    
    this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });
    if(this.project_id!=''){
    //  this.allocateRecords();
    this.project_id  = this.httpService.decryptNew(this.project_id);
    this.projectDetails();
    this.getMessages();
  /* For payment */
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
      source_url: ['',],
      amount: ['', [Validators.required]]
    });
    this.stripeFinalTest = this.fb.group({
      name: ['', [Validators.required]],
      google_drive_link: ['', [Validators.required]],
      amount: ['', [Validators.required]]
    });
    this.acceptForm = this.fb.group({
      source_url: ['', [Validators.required]],
    });
    this.enquiryForm = this.fb.group({
      message: ['', [Validators.required]],
    });
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#CFD7E0'
                }
              }
            }
          });
          this.card.mount('#card-element');
          
        }
      });

    }
  }
  makePayment(this){
    //console.log(this);
    this.ngxService.start(); 
    this.httpService.video_payment(this).subscribe((resp: any) => {
      this.ngxService.stop(); 
      if(resp.result!='' ){
        $('#paymentModal').modal('hide');
        this.userService.showSuccess(resp.result.message);
        //this.ngxService.stop(); 
        this.router.navigate(['/job-list']); 
      }else if(resp.message!='')
        this.userService.showError(JSON.stringify(resp.message));
        this.ngxService.stop(); 
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

makeFinalPayment(this){
    console.log(this);

    if(this.project.status==1 || this.project.status==2)
    this.project_status=2;
    else
    this.project_status=this.project.status;
    
    this.ngxService.start(); 
    this.httpService.final_payment(this).subscribe((resp: any) => {
      this.ngxService.stop(); 
      if(resp.result!='' ){
        $('#finalpaymentModal').modal('hide');
        this.userService.showSuccess(resp.result.message);
        //this.ngxService.stop(); 
        this.router.navigate(['/job-list']); 
      }else if(resp.message!='')
        this.userService.showError(JSON.stringify(resp.message));
        this.ngxService.stop(); 
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

canceljob()
{
 //console.log(this);
    this.ngxService.start(); 
    this.httpService.cancel_job(this).subscribe((resp: any) => {
      this.ngxService.stop(); 
      if(resp.result!='' ){
       // $('#paymentModal').modal('hide');
        this.userService.showSuccess("Job Canceled");
        //this.ngxService.stop(); 
        this.router.navigate(['/job-list']); 
      }else if(resp.message!='')
        this.userService.showError(JSON.stringify(resp.message));
        this.ngxService.stop(); 
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
}
paymentPopup(){
    $('#paymentModal').modal('show');
  }
acceptPopup(){
    $('#acceptModal').modal('show');
}
finalPayment()
{
  $('#finalpaymentModal').modal('show');
}
enquiryPopup()
{
  $('#enquiryModal').modal('show');
}
enquiryjob()
{
  this.message=this.enquiryForm.get('message').value;
  let m = localStorage.getItem('member_id');
  if(typeof m=='undefined' || m==''){
      location.reload();
    }
    else if(this.message!='' && typeof this.message!='undefined'){
 //console.log(this);
    this.ngxService.start(); 
    this.httpService.enquiry_job(this).subscribe((resp: any) => {
      this.ngxService.stop(); 
      if(resp.result!='' ){
        $('#enquiryModal').modal('hide');
        this.userService.showSuccess("Message Sent");
        //this.ngxService.stop(); 
        //this.router.navigate(['/job-list']); 
        location.reload();
      }else if(resp.message!='')
        this.userService.showError(JSON.stringify(resp.message));
        this.ngxService.stop(); 
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }else{
      alert('Enter Source URL');
    }

}
acceptjob()
{
  this.source_url=this.acceptForm.get('source_url').value;
  let m = localStorage.getItem('member_id');
  if(typeof m=='undefined' || m==''){
      location.reload();
    }
    else if(this.source_url!='' && typeof this.source_url!='undefined'){
 //console.log(this);
    this.ngxService.start(); 
    this.httpService.accept_job(this).subscribe((resp: any) => {
      this.ngxService.stop(); 
      if(resp.result!='' ){
        $('#acceptModal').modal('hide');
        this.userService.showSuccess("Job Accepted");
        //this.ngxService.stop(); 
        this.router.navigate(['/job-list']); 
      }else if(resp.message!='')
        this.userService.showError(JSON.stringify(resp.message));
        this.ngxService.stop(); 
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }else{
      alert('Enter Source URL');
    }

}

buy() {

    const name = this.stripeTest.get('name').value;
    this.source_url=this.stripeTest.get('source_url').value;
    //const amount = this.stripeTest.get('amount').value;
    let m = localStorage.getItem('member_id');

    ////console.log(this.card.amount);

    if(typeof m=='undefined' || m==''){
      location.reload();
    }
    else if(name!='' && typeof name!='undefined'){
    this.stripeService
      .createToken(this.card, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          //console.log(result.token);
          this.card_id = result.token.card.id;
          this.token_id = result.token.id;
          
          this.ngxService.start(); 
          this.userService.showWarning("Payment in progress DONT refresh this page.");
          this.httpService.paymentStripe(this).subscribe((resp: any) => {
            //console.log(resp)
            //this.ngxService.stop(); 
            if(resp.result.result!='' && typeof resp.result.result!='undefined' ){
              //alert('Make payment');
              this.makePayment();
            }else if(resp.message!=''){
              this.ngxService.stop();
              if(resp.message.error.message!='' && typeof (resp.message.error.message)!='undefined' )
                this.userService.showError('Payment is failed. '+ (resp.message.error.message)+'. Please try again.');
              else
                this.userService.showError('Payment is failed. Please try again.');
            }
               
          }, (errorResponse: HttpErrorResponse) => {
              this.isError = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again'
              this.userService.showError(this.isError) ;
          });
          
        } else if (result.error) {
          // Error creating the token
          this.userService.showError(JSON.stringify(result.error.message));
        }
      });
    }else{
      alert('Enter name');
    }
    
  }
  buyNow() {

    const name = this.stripeFinalTest.get('name').value;
    this.google_drive_link = this.stripeFinalTest.get('google_drive_link').value;
    //const amount = this.stripeTest.get('amount').value;
    let m = localStorage.getItem('member_id');

    ////console.log(this.card.amount);

    if(typeof m=='undefined' || m==''){
      location.reload();
    }
    else if(name!='' && typeof name!='undefined'){
    this.stripeService
      .createToken(this.card, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          //console.log(result.token);
          this.card_id = result.token.card.id;
          this.token_id = result.token.id;
          
          this.ngxService.start(); 
          this.userService.showWarning("Payment in progress DONT refresh this page.");
          this.httpService.paymentStripe(this).subscribe((resp: any) => {
            //console.log(resp)
            //this.ngxService.stop(); 
            if(resp.result.result!='' && typeof resp.result.result!='undefined' ){
              //alert('Make payment');
              this.makeFinalPayment();
            }else if(resp.message!=''){
              this.ngxService.stop();
              if(resp.message.error.message!='' && typeof (resp.message.error.message)!='undefined' )
                this.userService.showError('Payment is failed. '+ (resp.message.error.message)+'. Please try again.');
              else
                this.userService.showError('Payment is failed. Please try again.');
            }
               
          }, (errorResponse: HttpErrorResponse) => {
              this.isError = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again'
              this.userService.showError(this.isError) ;
          });
          
        } else if (result.error) {
          // Error creating the token
          this.userService.showError(JSON.stringify(result.error.message));
        }
      });
    }else{
      alert('Enter name');
    }
    
  }
  getMessages(this){
    this.ngxService.start();
    
      //this.query = 'SELECT manager_name,manager_email,manager_mobile,project_title,payment_amount,payment_discount,payment_net_amount,payment_status,project_live_status,'+this.tablenames.project_payment+'.created_date,project_id,project_id,project_member_id FROM '+this.tablenames.project+' JOIN '+this.tablenames.manager+' ON manager_id = project_manager_id JOIN '+this.tablenames.project_payment+' ON payment_project_id = project_id WHERE 1=1  AND project_member_id = '+this.member_id+' AND project_live_status!=3 '; 
      this.httpService.enquirymessage(this)
        .subscribe((resp: any) => {
        this.member_records = resp.result;
        //console.log(this.member_records);
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

}
