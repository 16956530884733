import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { ToastrManager } from 'ng6-toastr-notifications';

import {ActivatedRoute} from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-mforgot',
  templateUrl: './mforgot.component.html',
  styleUrls: ['./mdashboard.component.scss']
})

export class MforgotComponent implements OnInit {
  member_email = '';
  query = '';
  server_url = '';
  member_id = '';
  new_password = '';
  confirm_password = '';
  existing_password = '';
  constructor(public toastr: ToastrManager,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,public userService:UserService,private ngxService: NgxUiLoaderService,private route:ActivatedRoute) { }
  
  passwordChange(){
    if(this.member_id!=''){
      let p = this.new_password;
      if(p.length<6)
        this.userService.showError('Password should be a combination of alphanumeric and special characters with 6 digit.');
      else if(p.length>30)
        this.userService.showError('Password more than 30 digit is not allowed ');
      else if(!/[^a-zA-Z0-9]/.test(p))
        this.userService.showError('Password should be a combination of alphanumeric and special character');
      else if(this.new_password=='')
        this.userService.showError('Enter password');
      else if(this.confirm_password=='')
        this.userService.showError('Enter confirm password');
      else if(this.existing_password==this.new_password)
        this.userService.showError('Old and new password is same please change it.');
      else if(this.new_password!=this.confirm_password)
        this.userService.showError('New password and confirm password is not matched.');
      else{
        
            this.query = 'UPDATE '+this.tablenames.member+' SET member_request_password = 2,member_password =  sha1("'+this.new_password+'") WHERE member_id = "'+this.member_id+'" '; 
            this.httpService.commonFetch2(this)
              .subscribe((resp: any) => {
              
              if(resp.result==''){
                this.userService.showError('You are not authorized person');
                this.router.navigate(['/index']);
              }
              else{
                this.userService.showSuccess('Password is changed successfully');
                this.router.navigate(['/login']);
              }
            }, (errorResponse: HttpErrorResponse) => {
                  let isError = errorResponse.error ? errorResponse.error.message
                        : 'Sorry, unable to complete your request, please refresh this page and try again'
                    this.userService.showError(isError) ;
            });
      }
    }else{
      this.userService.showError('You are not authorized person');
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() { 	
  this.member_id = '';

    this.route.params.subscribe(params => {
      this.member_id = params['member_id'];
    });

    console.log('MEMBER ID');
    console.log(this.httpService.decryptNew(this.member_id));

    if(this.member_id!=''){
      this.query = 'SELECT * FROM '+this.tablenames.member+' WHERE 1=1 AND   BINARY member_id = "'+this.httpService.decryptNew(this.member_id)+'" AND member_request_password = 1 '; 
      ////console.log(this.query);
      this.httpService.commonFetch2(this)
        .subscribe((resp: any) => {
        
        if(resp.result==''){

          this.userService.showError('You are not authorized person');
          this.router.navigate(['/login']);
        }
        else if(resp.result[0]!=''){
          this.existing_password = resp.result[0].member_password;
          this.member_id = resp.result[0].member_id;
        
          if(this.member_id==''){
            this.userService.showError('You are not authorized person');
            this.router.navigate(['/login']);
          }
        }
      }, (errorResponse: HttpErrorResponse) => {
            let isError = errorResponse.error ? errorResponse.error.message
                  : 'Sorry, unable to complete your request, please refresh this page and try again'
              this.userService.showError(isError) ;
      });
    }else{
      this.userService.showError('You are not authorized person');
      this.router.navigate(['/login']);
    }
    
  }

}
