import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders,HttpRequest,HttpEvent} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import {ActivatedRoute} from '@angular/router';

import {FileUploader} from "ng2-file-upload";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";

import { ToastrManager } from 'ng6-toastr-notifications';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import * as AWS from 'aws-sdk';

declare var TextDecoder;

import { Injectable } from '@angular/core';

import { VideoHours } from '../video-hours.pipe';

@Component({
  selector: 'app-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class ProjectComponent implements OnInit {
  filenames = new Array<filenameType>();
  
  records = '';
  pre_sign_response={};
  errorMsg = '';
  query = '';
  table = '';
  title = '';
  isError = '';
  project_id = '';
  member_id = '';
  no_images = '';
  gallery = '';
  server_url = '';
  load_process = '';
  gallery_table = '';
  primary_id = '';
  project = {
    project_id:'',
    project_title:'',
    
  };
  download_image = '';

  s3url = '';
  thumb_url = '';
  setting = {
    additional_video_length:'',
    additional_video_length_price:'',
    additional_picture:'',
    additional_picture_price:''
  };

  
  data_id  =   '';
  data_image   =   '';
  
  where_field  =   '';
  folder_name = '';
  step2_url = '';
  next_enable = '';
  qry = '';

  image_video_type = 0;
  video_min = 0;
  video_count = 0;
  video_size = 0;
  image_size = 0;
  image_count = 0;
  image = '';
  type_image = '';
  image_rename = '';
  image_ext = '';
  video_duration = 0;
  percentage_inc = 0;
  file_size = 0;
  // 1 GB - 1024 * 1024 * 1024
  uploadForm: FormGroup;

  /*
    isHTML5: true,allowedMimeType: ['image/jpeg','image/jpg','image/png','image/gif','video/mov','video/mp4','video/3gpp','video/mp4','video/mpeg','video/webm','video/x-flv','video/x-m4v','video/x-ms-wmv','video/x-msvideo'],
  */

  public uploader:FileUploader = new FileUploader({
    isHTML5: true,allowedMimeType: ['image/jpeg','image/jpg','image/png','image/gif','video/mov','video/mp4','video/quicktime','video/mp4'],
    maxFileSize: 1024*1024*1024*1024
  });

  constructor(private router: Router,public httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private route: ActivatedRoute,public userService:UserService,private fb: FormBuilder,private ngxService: NgxUiLoaderService) { }


  /* S3 BUCKET CHECK */
  fileEvent(fileInput: any) {
    const AWSService = AWS;
    const region = 'ap-southeast-1';
    const bucketName = 'editube';
    const IdentityPoolId = 'ap-southeast-1:490bb9e3-76e2-4fa0-b885-ae8a44bd1ba4';
    const file = fileInput.target.files[0];
  //Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });
  //adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName}
    });
  //I store this in a variable for retrieval later
    this.image = 'hello.jpg';
    s3.upload({ Key: this.image, Bucket: bucketName, Body: file, ACL: 'public-read'}, function (err, data) {
     if (err) {
       //console.log(err, 'there was an error uploading your file');
     }
   });
  }

  validateFile(file) {
    if(file!=''){
      var video = document.createElement('video');
      video.preload = 'metadata';
      var duration =0;
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        //console.log(video.duration);
        //if(video.duration!=0)
          //this.video_duration = video.duration;
      }

      video.src = URL.createObjectURL(file);
    }
  }
  
  directUploadS3(par_file,type_image,image_rename,j,file_size,obj,file_index,video_duration){
    
    this.type_image = type_image;
    
    this.image_rename = image_rename;

    video_duration = typeof video_duration!='undefined'?video_duration:'';

    
    this.httpService.getpresignedurls(this).subscribe(pre_sign =>{
      //console.log('AFTER PRESIGNED');
      
      var fileuploadurl = '';

      if(pre_sign!='' && typeof pre_sign!='undefined'){
        this.pre_sign_response = pre_sign;
        //console.log(this.pre_sign_response);
        fileuploadurl = this.pre_sign_response['urls']!='' && typeof this.pre_sign_response['urls']!='undefined'?this.pre_sign_response['urls']:'';
      }

      $('<div class="upload-row"><div class="upload-name"><p>'+image_rename+'</p></div><div class="upload-bar"><div class="upload-flow upload-flow-'+j+'" style="width: 0%"></div></div></div>').prependTo(".upload-process");

      this.httpService.uploadfileAWSS3(fileuploadurl, this.type_image, par_file).subscribe((event) => {
        //console.log(event);
        if(event!='' && typeof event!='undefined' ){
          var loaded = typeof event.loaded!='undefined' && event.loaded!=''?event.loaded:0;
          var total_size =  typeof event.total!='undefined' && event.total!=''?event.total:0;
          var t = (loaded/total_size) * 100;
          $('.upload-flow-'+j).css("width",t+'%');

          if(t==100){
            /* Completed */
            var final_images = image_rename+'~'+file_size+'~'+video_duration+',';
            
            this.no_images += final_images;

            this.eachS3Upload(obj,file_index);
            $('.upload-flow-'+j).parent().removeClass('upload-bar'); 
            $('.upload-flow-'+j).removeClass('upload-flow').html('<span class="upload-right pull-right"><i class="fa fa-check"></i></span>');            

            //this.ngxService.stop(); 
          }
          //console.log('LOADED:'+event.loaded);
          //console.log('TOTAL:'+event.total);
          
          /*
            if(event!='' &&  typeof event.ok!='undefined' && event.ok )
              alert('Uploaded');
          */
        }
      });

    });
    
  }

  eachS3Upload(obj,file_index){

    file_index = (typeof file_index=='undefined'?0:file_index);
    
    //if(file_index!=''){
      
      /*//console.log('FILE INDEX');
        //console.log(file_index);
        //console.log(this.no_images);
      */
      var check_exist = typeof obj.target.files[file_index]!='undefined' && obj.target.files[file_index]!=''?obj.target.files[file_index]:0;
      
      this.ngxService.start(); 

      if(check_exist!=0){

        $('.upload-process').show();

        var files = (obj.target.files[file_index].name);
        
        files = files.replace(/,/g, '-');
        
        files = files.replace(/~/g, '-');

        var sp = files.split('.');

        var image_ext = (files.split('.').pop().toLowerCase());

        if(image_ext =='mov' || image_ext == 'mp4' || image_ext == 'png' || image_ext == 'jpg' || image_ext == 'jpeg' || image_ext == 'gif' ){
          $('input[name=test_upload]').attr("disabled", 1);
          $('.next_disable').hide();
          $('.uploading-message').show();
          $('.container').attr('image-upload','yes');
          this.ons3Upload(obj,file_index);
        }
        else{
          this.ngxService.stop(); 
          this.userService.showWarning( files +' file not allow to upload, Allow only png,jpg,gif,jpeg,mp4,mov');
          $('input[name=test_upload]').removeAttr("disabled");
          $('input[name=test_upload]').val('');
          $('.container').attr('image-upload','no');
           this.steponeSubmit(0);
          //this.ons3Upload(obj,file_index+1);
        }  
      }else{
        this.ngxService.stop(); 
        $('.uploading-message').hide();
        $('.next_disable').show();
        $('input[name=test_upload]').removeAttr("disabled");
        $('.upload-process').html('');
        $('.upload-process').hide();
        $('.container').attr('image-upload','no');
        //console.log('Image data is going to update into server');
        this.steponeSubmit(0);
      }
    //}
  }

  ons3Upload(obj,file_index){

    this.ngxService.start(); 
    var i = 0;
    var j = 0;
    var final_images = '';
    
    //for(i=0;i<(obj.target.files).length;i++){

      var target_file = obj.target.files[file_index];

      var files = (target_file.name);

      files = files.replace(/,/g, '-');
      files = files.replace(/~/g, '-');

      var sp = files.split('.');
      
      var orginal_name = sp[0].replace(/\ /g, '-');
      
      var rand_number = Math.floor((Math.random() * 10 * 998745128965) + 1);

      var image_ext = (files.split('.').pop().toLowerCase());
      
      var image_rename = 'gallery_'+orginal_name+'-'+rand_number+'.'+image_ext;
      
      var file_size  =  Math.round(target_file.size);

      var type_image = target_file.type;

      var video_duration = 0;

      if(image_ext =='mov' || image_ext == 'mp4'){
        if(target_file!=''){
          var video = document.createElement('video');
          video.preload = 'metadata';
          var duration =0;
          video.src = URL.createObjectURL(target_file);

          video.onloadedmetadata = function() {
            window.URL.revokeObjectURL(video.src);
            video_duration = video.duration;
            //console.log('VIDEO DURATION');
            //console.log('IMAGE RENAME:'+image_rename);
            //console.log('FILE SIZE :'+file_size);
            //console.log('FILE TYPE :'+type_image);
            //console.log('VIDEO SEC :'+video_duration);
            //console.log('EXTENSION:'+image_ext);

            final_images =image_rename+'~'+file_size+'~'+video_duration+',';
            
          }

          //this.filenames.push({file_name: image_rename,file_size: file_size,file_min:video_duration});
          
          setTimeout(()=>{
            //image 
            //this.no_images += final_images;
            
            $('.file_names').html(this.no_images);
            this.directUploadS3(target_file,type_image,image_rename,file_index,file_size,obj,file_index+1,video_duration);
            //this.ons3Upload(obj,file_index+1);
          }, 5000);
        }
      }else{
        //this.filenames.push({file_name: image_rename,file_size: file_size,file_min:0});
        final_images = image_rename+'~'+file_size+',';
        
        //image
        //this.no_images += final_images;
        
        //$('.file_names').html(this.no_images);
        
        this.directUploadS3(target_file,type_image,image_rename,file_index,file_size,obj,file_index+1,video_duration);
        //this.ons3Upload(obj,file_index+1);
      }
      
    //}

  }

  ons3Upload_old(obj){
    var i = 0;
    var j = 0;
    var final_images = '';
    //console.log((obj.target.files).length);
    for(i=0;i<(obj.target.files).length;i++){

      var target_file = obj.target.files[j];

      var files = (target_file.name);

      var sp = files.split('.');
      
      var orginal_name = sp[0].replace(/\ /g, '-');
      
      var rand_number = Math.floor((Math.random() * 10 * 998745128965) + 1);

      var image_ext = (files.split('.').pop().toLowerCase());
      
      var image_rename = orginal_name+'-'+rand_number+'.'+image_ext;
      
      var file_size  =  Math.round(target_file.size);

      file_size = file_size /60;

      var type_image = target_file.type;

      var video_duration = 0;

      if(image_ext =='mov' || image_ext == 'mp4'){
        if(target_file!=''){
          var video = document.createElement('video');
          video.preload = 'metadata';
          var duration =0;
          
          video.onloadedmetadata = function() {
            window.URL.revokeObjectURL(video.src);
            video_duration = video.duration;
            //console.log('VIDEO DURATION');
            //console.log('IMAGE RENAME:'+image_rename);
            //console.log('FILE SIZE :'+file_size);
            //console.log('FILE TYPE :'+type_image);
            //console.log('VIDEO SEC :'+video_duration);
            //console.log('EXTENSION:'+image_ext);
            final_images +=image_rename+'~'+file_size+'~'+video_duration+',<br>';
            $('.file_names').html(final_images);
            
          }

          video.src = URL.createObjectURL(target_file);
          //setTimeout(()=>{    

            //this.directUploadS3(target_file,type_image,image_rename,j,file_size);
          //}, 1500);
          
        }
      }else{
        
        final_images += image_rename+'~'+file_size+',<br>';
        $('.file_names').html(final_images);
        //this.directUploadS3(target_file,type_image,image_rename,j,file_size);
      }
      j++;
    }
    this.no_images = final_images;
  }
  
  uploadFile(data: FormData) {
    return this.httpClient.post(this.tablenames.upload_gallery, data);
  }

  uploadFileS3(data: FormData): Observable<HttpEvent<{}>> {
    const req = new HttpRequest('POST', this.tablenames.s3upload, data);

    return this.httpClient.request(req);
    //return this.httpClient.request(this.tablenames.s3upload, data);
  }

  uploadFiles(obj){
    ////console.log($('input[name=files]')[0].file[0]);
    //console.log((<HTMLInputElement>$('input[name=test_upload]')[0]).files);

    var f =  (<HTMLInputElement>$('input[name=test_upload]')[0]).files;

    //console.log('CHECK LENGTH');
    //console.log(f.length);
    //console.log((<HTMLInputElement>$('input[name=test_upload]')[0]).files[0])
    if(f.length>0){
      var j=0;
      
      /* Check image extension */
      for(j=0;j<f.length;j++){
        var file_name = (<HTMLInputElement>$('input[name=test_upload]')[0]).files[j].name;

        var fileExtension = ['jpeg','jpg','png', 'gif','mov','mp4'];
        if ($.inArray(file_name.split('.').pop().toLowerCase(), fileExtension) == -1) 
        {
          this.userService.showError("Only formats are allowed : "+fileExtension.join(', '));
          return false;
        }
      }
      let data = new FormData();
      /* File upload 
      * Form Append with project and member id
      */
      
      for(j=0;j<f.length;j++){
        let file_name = (<HTMLInputElement>$('input[name=test_upload]')[0]).files[j];
        data.append('files[]', file_name);

      }
      //console.log(data);
      data.append('project_id',this.project.project_id);
      data.append('member_id',this.member_id);
      data.append('folder_name','project');
      
      this.uploadFileS3(data).subscribe((resp: any) => {
        if(resp.result=='success' ){
          //this.final_file = resp.filename;
          //$('#requestModal').modal('hide');
        }
        else if(resp.result=='')
          alert( resp.message);

      }, (errorResponse: HttpErrorResponse) => {
          
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          alert(isError) ;
      });
      
    
    }
      
  }

  imageVideoType(video_mins,video_counts,video_sizes){
    ////console.log('video_mins :'+video_mins);
    ////console.log('video_counts :'+video_counts);
    ////console.log('video_sizes :'+video_sizes);

    ////console.log(this.image_count);

    if(video_counts==1){
      
      this.image_count = this.image_count + 1;
    }else if(video_counts==2){
      this.video_min = this.video_min + (video_mins/60);
      this.video_count = this.video_count + 1;
    }

    this.video_size = this.video_size + (video_sizes / (this.tablenames.mb_size * this.tablenames.mb_size));
    
  }

  uploadSubmit(){
  
  //this.load_process = '1';
  this.no_images = '';
    //console.log('SUBMIT');
    //console.log(this.uploader);
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;

      //console.log(fileItem);
      /* 
        png,jpg,gif,jpeg,mp4,3gpp,mpeg,webm,x-flv,x-m4v,x-ms-wmv,x-msvideo,.mov
      */
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.jpeg|\.mp4|\.3gpp|\.mpeg|\.webm|\.x-flv|\.x-m4v|\.x-ms-wmv|\.x-msvideo|\.mov|\.JPG|\.JPEG|\.PNG|\.GIF|\.MP4|\.3GPP|\.MPEG|\.WEBM|\.X-FLV|\.X-M4V|\.X-MS-WMV|\.X-MSVIDEO|\.MOV)$/i;
      
      
      //console.log(fileItem.name);
      if(!allowedExtensions.exec(fileItem.name)){
          //console.log('INVALID');
          this.userService.showError('Please upload file having extensions png,jpg,gif,jpeg,mp4,3gpp,mpeg,webm,x-flv,x-m4v,x-ms-wmv,x-msvideo,.mov only.');
          this.checkOutProgress();
          this.ngxService.stop();
          setTimeout(()=>{    
            window.location.reload();
          }, 1000);
          return false;
      }else{
        this.checkProgress();
        //this.ngxService.start();
      }


      /*if(fileItem.size > (1024 * 1024 * 1024*1024*1024)){
        alert("Each File should be less than 10 MB of size.");
        return;
      }*/
    }

    let r = 0;

    let j = 0;
    let len = this.uploader.queue.length;
    for (j = 0; j < this.uploader.queue.length; j++) {
      
      //console.log('INSIDE');
      let data = new FormData();
      let fileItem = this.uploader.queue[j]._file;
      //var extension = fileItem.split('.').pop();
      ////console.log(extension);
      data.append('file', fileItem);
      data.append('fileSeq', 'seq'+j);
      data.append( 'member_id', this.member_id);
      data.append('project_id',this.httpService.encryptNew(this.project.project_id));
      ////console.log(this.uploadForm.controls.type.value);
      //console.log(data);
      this.uploadFile(data).subscribe((resp: any) => {
        //console.log(resp);
        r++;
        if(resp.result=='success' ){
          this.percentage_inc = (r/len) * 80
          this.no_images += resp.filename+',';
          //console.log('R:'+r+',length:'+len);
          //console.log(this.percentage_inc);
        }
        else if(resp.result=='error'){
          this.userService.showError(JSON.stringify(resp.message));
          this.checkOutProgress();
        }

        if(r==j){
          this.steponeSubmit(0);
          
          this.ngxService.stop(); 
        }
          
      }, (errorResponse: HttpErrorResponse) => {
        r++;
        if(r==j){
          //this.ngxService.stop(); 
          /* Update video and image minutes,size */
          this.httpService.deleteVideoImage(this).subscribe((resp: any) => {
            //console.log(resp);
            this.galleryRecords();
          }, (errorResponse: HttpErrorResponse) => {
              this.isError = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again'
              this.userService.showError(this.isError) ;
          });
        }
        ////console.log('eeee');
        //console.log(errorResponse);
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(errorResponse) ;
      });
    

      
    }

    this.uploader.clearQueue();
  }



  checkProgress(){
    $('.body-load').show();
    this.percentage_inc = 1;
    
    /*setInterval(()=>{    
      this.percentage_inc += 10;
    }, 1000);
    */
  }

  checkOutProgress(){
    this.percentage_inc = 100;
    setTimeout(()=>{    
      $('.body-load').hide();
    }, 1000);
    
  }
  

  deleteVideoImage(obj){

    //console.log(obj);
    //alert(obj.srcElement.attributes.data_image.value);
    
    /*this.data_id  =   obj.toElement.attributes.data_id.value;
    this.data_image   =   obj.toElement.attributes.data_image.value;
    this.table   =   obj.toElement.attributes.table.value;
    this.where_field  =   obj.toElement.attributes.where_id.value;
    */

    this.data_id  =   obj.srcElement.attributes.data_id.value;
    this.data_image   =   obj.srcElement.attributes.data_image.value;
    this.table   =   obj.srcElement.attributes.table.value;
    this.where_field  =   obj.srcElement.attributes.where_id.value;
    this.folder_name  =   obj.srcElement.attributes.folder_name.value;
    
    if(confirm('Are you sure want to delete?')){
        this.ngxService.start(); 
      this.qry = 'DELETE FROM '+this.table+' WHERE 1=1 AND  '+this.where_field+' = '+this.data_id;

      this.httpService.deleteVideoImage(this).subscribe((resp: any) => {
        //console.log(resp);
        this.galleryRecords();
        this.ngxService.stop(); 
      }, (errorResponse: HttpErrorResponse) => {
      this.ngxService.stop(); 
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(this.isError) ;
      });

    }else{
        alert('Cancel');
    }
  }

  /* Form submit */
  steponeSubmit(nstep){
    if($.trim(this.project.project_title)==''){
      this.userService.showError('Enter title') ;
    }else{
    //console.log('FORM INSIDE');
    this.ngxService.start(); 
    $('input[name=test_upload]').val('');
      this.httpService.stepOneUpdate(this).subscribe((resp: any) => {
        //console.log(resp);
        this.ngxService.stop(); 
        if(resp.result!='' ){
          //this.httpService.videoImageUpdate(this);
          this.galleryRecords();
          //console.log('GALLERY RECORDS');
          ////console.log(this.gallery);
          if(this.gallery!='' && nstep==1)
            this.userService.showSuccess('Step 1 completed successfully');
          
          this.checkOutProgress();
          this.next_enable = '1';
          this.no_images = '';
          if(nstep==1)
            this.router.navigate(['/step-two/'+this.project_id]);

        }else if(resp.result=='error')
          this.userService.showError(' file not received');
      }, (errorResponse: HttpErrorResponse) => {
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(this.isError) ;
      });
    }
  }

  totalSeconds(totalSeconds) {
    var hours   = Math.floor(totalSeconds / 3600);
    var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    var seconds = totalSeconds - (hours * 3600) - (minutes * 60);

    // round seconds
    seconds = Math.round(seconds * 100) / 100

    var result = (hours < 10 ? "0" + hours : hours);
        result += "-" + (minutes < 10 ? "0" + minutes : minutes);
        result += "-" + (seconds  < 10 ? "0" + seconds : seconds);
    return result;
  }

  galleryRecords(){
    this.ngxService.start();
    /* Query for gallery records */
    this.query = 'SELECT * FROM '+this.tablenames.project_gallery+' WHERE 1=1 AND gallery_member_id = '+localStorage.getItem('member_id')+' AND  gallery_project_id = '+this.httpService.decryptNew(this.project_id)+' AND (gallery_type = 1 OR gallery_type=2)';

    //console.log('COOOOOOOO');
    //console.log(this.query);

    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      this.gallery = resp.result;
      this.image_count =0;
      this.video_count = 0;
      this.video_size = 0;
      this.video_min = 0;

      var g_file = '';
      var g_file_size =0;
      var g_id = 0;
      var g_member_id = 0;
      var g_project_id = 0;
      var g_type = 0;
      var g_video_min = 0;
      /*
      for (let j = 0; j < (this.gallery).length; j++) {
        //console.log(this.gallery[j]);
        g_file = this.gallery[j].gallery_file;
        g_file_size = this.gallery[j].gallery_file_size;
        g_id = this.gallery[j].gallery_id;
        g_member_id = this.gallery[j].gallery_member_id;
        g_project_id = this.gallery[j].gallery_project_id;
        g_type = this.gallery[j].gallery_type;
        g_video_min = this.gallery[j].gallery_video_min;
        

      
        var t = '';
        if(g_type==1)
          t = 'Image';
        else if(g_type==2)
          t = 'Video';
        
        
        var s = (g_file_size) / (this.tablenames.mb_size * this.tablenames.mb_size);

        this.gallery_html +='<tr><td >'+g_file+' </td>'+
        '<td >'+t+'</td>'+
        '<td>'+s+'</td>'+

        +'</tr>';
        
      }
      */

      this.ngxService.stop(); 
    }, (errorResponse: HttpErrorResponse) => {
        //this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  settings(){
    this.ngxService.start(); 
    this.httpService.settings(this)
      .subscribe((resp: any) => {
      if(resp.result!=''){
        //console.log('SEEINGS');
        //console.log(resp);
        resp = resp.result.records[0];
        this.setting.additional_video_length = resp.additional_video_length;
        this.setting.additional_video_length_price = resp.additional_video_length_price;
        this.setting.additional_picture = resp.additional_picture;
        this.setting.additional_picture_price = resp.additional_picture_price;
      }
      this.ngxService.stop(); 
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  ngOnInit() { 

    

    this.s3url = this.tablenames.s3url;
    this.thumb_url = this.tablenames.thumb_url;


    this.video_min = 0;
    this.video_count = 0;
    this.video_size = 0;
    this.image_size = 0;
    this.image_count = 0;
    this.no_images = '';
    this.next_enable = '';
    this.primary_id  = 'gallery_id';
    this.gallery_table = this.tablenames.project_gallery
    this.load_process = '';
    this.server_url = this.tablenames.server_url;
    /* Upload Form */
    this.uploadForm = this.fb.group({
      project_title:[null,null],

      project_files: [null, null],
      type:  [null, Validators.compose([Validators.required])]
    });

    this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });

    this.settings();

    this.httpService.paymentDoneAlready(this);

    this.step2_url = '/#/step-two/'+this.project_id;
    
    this.table = this.tablenames.project;
    this.member_id = localStorage.getItem('member_id');


    this.httpService.checkUserExists(this.member_id).subscribe((resp: any) => {
      //console.log('CHECK EXISTSSSSSSSSSSSS');
      if(resp.result!=''  && typeof resp.result[0]['member_id']!='undefined'){
        //console.log('INSIDE');
        ////console.log(resp);
      }else{
        //console.log('LOGOUT PLZ');
        this.httpService.logoutUser();
        window.location.href='/#/index';
      }
    }, (errorResponse: HttpErrorResponse) => {
          
          let a = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(a) ;
    });

    this.query = 'SELECT * FROM '+this.table+' WHERE 1=1 AND project_id = '+this.httpService.decryptNew(this.project_id); 
    this.httpService.commonFetch2(this)
      .subscribe((resp: any) => {
      this.records = resp.result;
      //console.log('PROJECT DETAILS');
      //console.log(resp);
      if(resp.result!='' ){
        this.project.project_title = resp.result[0].project_title;
        this.project.project_id = resp.result[0].project_id;
      }else{
        window.location.href='/#/index';
      }
      
    }, (errorResponse: HttpErrorResponse) => {
          
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(this.isError) ;
    });

    this.galleryRecords();




    
  }
}

type filenameType = { file_name: string, file_size: number,file_min:number }; 
