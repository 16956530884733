import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import * as moment from 'moment';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { ToastrManager } from 'ng6-toastr-notifications';

import {ActivatedRoute} from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-editor-report',
  templateUrl: './editor-report.component.html',
  styleUrls: ['./editor-login.component.scss']
})

export class EditorReportComponent implements OnInit {
  editor_id = '';
  query = '';
  server_url = '';
  job_records = '';
  assign_id = '';
  job_no = 0;

  constructor(public toastr: ToastrManager,private router: Router,private httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private userService:UserService,private ngxService: NgxUiLoaderService,private route:ActivatedRoute) { }
  
  editorReport(this){
    this.ngxService.start();
      this.query = 'SELECT editor_id,editor_name,editor_email,(SELECT count(assign_id) FROM `et_project_assign_editor` WHERE 1=1 AND assign_editor_id =  '+this.editor_id+' ) as projects,(SELECT count(pr_id) FROM `et_project_submit_editor` WHERE 1=1 AND pr_status_by_pm =1 AND pr_editor_id = '+this.editor_id+' order by pr_project_id) as completed_project,(SELECT DISTINCT count(pr_project_id) as total FROM `et_project_submit_editor` WHERE 1=1 AND pr_status_by_pm =2 AND pr_editor_id = '+this.editor_id+' AND pr_status_by_pm = 2 AND pr_project_id NOT IN (SELECT DISTINCT pr_project_id FROM `et_project_submit_editor` WHERE 1=1 AND pr_editor_id = '+this.editor_id+' AND pr_status_by_pm =1  )) as inprogress_project,(SELECT count(request_id) FROM `et_request_member` WHERE `request_editor_id` = '+this.editor_id+') as free_edit_project,(SELECT count(request_project_id) FROM `et_request_pm_editor` WHERE `request_editor_id` = '+this.editor_id+' AND request_project_id NOT IN (SELECT request_project_id FROM et_request_pm_editor WHERE 1=1 AND request_editor_id='+this.editor_id+' AND `request_project_status_by_pm` =1 )) as free_inprogress,(SELECT count(request_project_id) FROM `et_request_pm_editor` WHERE `request_editor_id` = '+this.editor_id+' AND request_id IN (SELECT request_id FROM et_request_pm_editor WHERE 1=1 AND request_editor_id = '+this.editor_id+' AND `request_project_status_by_pm` =1 )) as free_completed,(SELECT count(assign_id) FROM et_project_assign_editor WHERE 1=1 AND assign_editor_id = '+this.editor_id+' AND assign_approval_by_editor =1  ) as approved_editor,(SELECT count(assign_id) as total FROM `et_project_assign_editor` WHERE 1=1 AND assign_approval_by_editor = 1  AND assign_editor_id = '+this.editor_id+' AND assign_project_id NOT IN (SELECT pr_project_id FROM et_project_submit_editor WHERE pr_editor_id = '+this.editor_id+'  )) as not_reply_by_editor FROM et_editor WHERE 1=1 AND editor_id = '+this.editor_id; 
      this.httpService.commonFetch2(this)
        .subscribe((resp: any) => {
        this.job_records = resp.result;
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
          this.ngxService.stop();
          let isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(isError) ;
      });
  }

  ngOnInit() { 	
    this.job_no = 0;
    this.assign_id = '';
    this.editor_id  = localStorage.getItem('editor_id');
    this.server_url = this.tablenames.server_url;
    if(this.editor_id!=''){
      this.editorReport();
    }



    
    
  }


}
