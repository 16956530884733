import {Injectable} from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';

@Injectable()

export class UserService {

    constructor(public toastr: ToastrManager) {
    }
                            
    addressValidation(event:any){
        if (event.charCode!=0) {
            var regex = new RegExp("[a-zA-Z0-9-*#@.\n\r ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                this.showWarning('Not allowed character');
                event.preventDefault();
                return false;
            }
        }
    }

    titleValidation(event:any){
        if (event.charCode!=0) {
            var regex = new RegExp("^[a-zA-Z0-9-_,/. ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                this.showWarning("Only alphanumeric and special characters like' .' ',' '_' '-' '/' allowed");
                event.preventDefault();
                return false;
            }
        }
    }

    nameNumberValidation(event:any){
        if (event.charCode!=0) {
            var regex = new RegExp("^[a-zA-Z0-9 ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                this.showWarning('Character and number only allowed');
                event.preventDefault();
                return false;
            }
        }
    }
    
    isEmail(email) {
        var regex = new RegExp("^[-@_.0-9a-zA-Z0-9 ]+$");
        return regex.test(email);
        
        /*var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);*/
    }

    passwordValidation(event: any) { // without type info
        //var values;
        ////console.log(event.target.value);
        if (event.charCode!=0) {
            var regex = new RegExp("^[*$#~|]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    }

    nameValidation(event: any) { // without type info
        //var values;
        ////console.log(event.target.value);
        if (event.charCode!=0) {
            var regex = new RegExp("^[a-zA-Z ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                this.showWarning('Numeric and special characters are not allowed');
                event.preventDefault();
                return false;
            }
        }
    }

    emailValidation(event: any) { 
        if (event.charCode!=0) {
            var regex = new RegExp("^[-@_.0-9a-zA-Z0-9 ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                this.showWarning('Special charactes are not allowed');
                event.preventDefault();
                return false;
            }
        }
    }

    numberValidation(event: any){
        if (event.charCode!=0) {
            var regex = new RegExp(/[^0-9 ]/g);
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (regex.test(key)) {
                this.showWarning('Numbers only allowed');
                event.preventDefault();
                return false;
            }
        }
    }
    
    nextStep(){
        this.showError('Please save your updated details');
    }
    showSuccess(message) {
        this.toastr.successToastr(message, 'Success!',{timeOut: 5000,maxShown:1});
    }

    showError(message) {
        //this.toastr.clear();
        this.toastr.errorToastr(message, 'Oops!',{timeOut: 5000,maxShown:1});
    }

    showWarning(message) {
        this.toastr.warningToastr(message, 'Alert!',{maxShown:1});
    }

    showInfo(message) {
        this.toastr.infoToastr(message, 'Info');
    }

    showCustom() {
        /*this.toastr.customToastr('<span style='color: green; font-size: 16px;text-align: center;'>Custom Toast</span>',null,{ enableHTML: true });
        */
    }

    showToast(position: any = 'top-left') {
        /*this.toastr.infoToastr('This is a toast.', 'Toast', {
            position: position
        });
        */
    }

    public showLoader(): void {
        //console.log('Show loader');
    }
    
    public hideLoader(): void {
        //console.log('Hide loader');
    }
    isLoggedIn(): boolean {
        const token = localStorage.getItem('admin_id');
        return token != null && token.trim().length > 0;
    }

    isLoggedInM(): boolean {
        const token = localStorage.getItem('member_id');
        //return true;
        return token != null && token.trim().length > 0;
    }

    isLoggedInE(): boolean {
        const token = localStorage.getItem('editor_id');
        //return true;
        return token != null && token.trim().length > 0;
    }

    logoutMUser(){
        var d = ($('.container').attr('image-upload'));
        if(d=='yes'){
            if(confirm('Files are uploading, Are you sure want to logout?')){
                localStorage.removeItem('member_id');
                localStorage.removeItem('type');
                localStorage.removeItem('admin_id');
                localStorage.removeItem('login_type');
                localStorage.removeItem('editor_id');
                
                window.location.href='/#/index';
            }
        }else{
            localStorage.removeItem('member_id');
            localStorage.removeItem('type');
            localStorage.removeItem('admin_id');
            localStorage.removeItem('login_type');
            localStorage.removeItem('editor_id');
            
            window.location.href='/#/index';
        }

        /*localStorage.removeItem('member_id');
        localStorage.removeItem('type');
        localStorage.removeItem('admin_id');
        localStorage.removeItem('login_type');
        localStorage.removeItem('editor_id');
        
        window.location.href='/#/index';
        */
    }

    logoutEUser(){
        localStorage.removeItem('editor_id');
        localStorage.removeItem('type');
        window.location.href='/#/index';
    }

    getMember(): string {
        let userInfo = localStorage.getItem('member_records');
        userInfo = userInfo !== '' ? JSON.parse(userInfo) : {};
        return (userInfo) ? userInfo['member_first_name']+' '+userInfo['member_last_name'] : '';
    }

    getLoginUser(): string {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo !== '' ? JSON.parse(userInfo) : {};
        return (userInfo) ? userInfo['firstName'] : '';
    }

    getLoginUserId(): number {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo !== '' ? JSON.parse(userInfo) : {};
        return (userInfo) ? userInfo['userID'] : '';
    }

    getLoginUserEmail(): number {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo !== '' ? JSON.parse(userInfo) : {};
        return (userInfo) ? userInfo['emailLogin'] : '';
    }

    getToken(): string {
        const token = localStorage.getItem('authToken');
        return (token != null && token.trim().length > 0) ? token : null;
    }

    logoutUser() {
        localStorage.removeItem('admin_id');
        localStorage.removeItem('admin');
    }

    
}
