import { Component } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { Tablename } from './app.tablename'; 

import { UserIdleService } from 'angular-user-idle';


@Component({
  selector: 'app-root',
  template: `<app-header></app-header> 
  			<ngx-ui-loader></ngx-ui-loader>
        <router-outlet></router-outlet> 
  			
        <app-footer></app-footer>
        
        `
})
export class AppComponent  {
  title = 'editube-front';
  server_url ='';
  constructor(private tablenames : Tablename,private userIdle: UserIdleService) {}

  /* Session expiry */
  stop() {
    this.userIdle.stopTimer();
  }
 
  stopWatching() {
    this.userIdle.stopWatching();
  }
 
  startWatching() {
    this.userIdle.startWatching();
  }
 
  restart() {
    this.userIdle.resetTimer();
  }

  ngOnInit() { 	
  	this.server_url = this.tablenames.server_url;
    
    this.userIdle.startWatching();
    
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => console.log(count));
    
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() =>{ //console.log('Time is up!');
        
        var m  = localStorage.getItem('member_id');
        if(m!='' && typeof m!='undefined'){
          localStorage.removeItem('member_id');
          localStorage.removeItem('type');
          localStorage.removeItem('admin_id');
          localStorage.removeItem('login_type');
          localStorage.removeItem('editor_id');
        //  alert("Your session is expiry, going to logout"); 
        }
        
        setTimeout(function(){ 
          var m  = localStorage.getItem('member_id');
          if(m!='' && typeof m!='undefined'){
            
            window.location.href='/#/index';
          }
        }, 1000);
          
      }
    );

  }


    
}
