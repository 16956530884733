import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NeedAuthGuard } from './needauthguard';
import { NeedAuthGuardEditor } from './needauthguardeditor';


import { IndexComponent } from './index/index.component';
import { LoginComponent } from './login/login.component';
import { HowitworksComponent } from './how-it-works/how-it-works.component';
import { VideosamplesComponent } from './video-samples/video-samples.component';
import { PricingComponent } from './pricing/pricing.component';
import { OurprocessComponent } from './our-process/our-process.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';
import { SupportComponent } from './support/support.component';
import { TermComponent } from './term/term.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ActivateMemberComponent } from './activate-member/activate.component';


/* Member */
import { MdashboardComponent } from './mdashboard/mdashboard.component';
import { MsettingsComponent } from './msettings/msettings.component';
import { ProjectComponent } from './new-project/new-project.component';
import { StepTwoComponent } from './new-project/step-two.component';
import { StepThreeComponent } from './new-project/step-three.component';
import { StepFourComponent } from './new-project/step-four.component';
import { StepFiveComponent } from './new-project/step-five.component';
import { PaymentComponent } from './new-project/payment.component';
import { MapproveComponent } from './mdashboard/mapprove.component';
import { MapproveViewComponent } from './mdashboard/mapprove-view.component';
import { McompletedComponent } from './mdashboard/mcompleted.component';
import { MforgotComponent } from './mdashboard/mforgot.component';
import { NewVideoComponent } from './new-video/new-video.component';
import { VideoStepTwoComponent } from './new-video/video-step-two.component';
import { VideoStepThreeComponent } from './new-video/video-step-three.component';
import { VideoFinalComponent } from './new-video/video-final.component';
import { JobListComponent } from './job-list/job-list.component';
import { JobListViewComponent } from './job-list/job-list-view.component';



/* Editor Account */
import { EditorLoginComponent } from './editor-login/editor-login.component';
import { EdashboardComponent } from './editor-login/edashboard.component';
import { EsettingsComponent } from './editor-login/esettings.component';
import { WaitingApproveComponent } from './editor-login/waiting-approve.component';
import { ApproveEditComponent } from './editor-login/approve-edit.component';
import { ApproveViewComponent } from './editor-login/approve-view.component';
import { ApproveCompletedComponent } from './editor-login/approve-completed.component';

import { EditorReportComponent } from './editor-login/editor-report.component';

const routes: Routes = [
	{path: '', component: IndexComponent},
	{path: 'index', component: IndexComponent},
	{path: 'login', component: LoginComponent},
	{path: 'how-it-works', component: HowitworksComponent},
	{path: 'video-samples', component: VideosamplesComponent},
	{path: 'pricing', component: PricingComponent},
	{path: 'our-process', component: OurprocessComponent},
	{path: 'faq', component: FaqComponent},
	{path: 'contact', component: ContactComponent},
	{path: 'support', component: SupportComponent},
	{path: 'terms-condition', component: TermComponent},
	{path: 'privacy-policy', component: PrivacyComponent},
	{path: 'activate-member/:mem_id', component: ActivateMemberComponent},
	
	/* Member */
	{path: 'mdashboard', component: MdashboardComponent, canActivate: [NeedAuthGuard]},
	{path: 'msettings', component: MsettingsComponent, canActivate: [NeedAuthGuard]},
	{path: 'new-project/:project_id', component: ProjectComponent, canActivate: [NeedAuthGuard]},

	{path: 'step-two/:project_id', component: StepTwoComponent, canActivate: [NeedAuthGuard]},

	{path: 'step-three/:project_id', component: StepThreeComponent, canActivate: [NeedAuthGuard]},

	{path: 'step-four/:project_id', component: StepFourComponent, canActivate: [NeedAuthGuard]},

	{path: 'step-five/:project_id', component: StepFiveComponent, canActivate: [NeedAuthGuard]},

	{path: 'payment/:project_id', component: PaymentComponent, canActivate: [NeedAuthGuard]},

	{path: 'mapprove', component: MapproveComponent, canActivate: [NeedAuthGuard]},

	{path: 'mapprove-view/:project_id', component: MapproveViewComponent, canActivate: [NeedAuthGuard]},

	{path: 'mcompleted', component: McompletedComponent, canActivate: [NeedAuthGuard]},

	{path: 'change-password/:member_id', component: MforgotComponent},

	{path: 'new-video', component: NewVideoComponent},
	{path: 'new-video/:project_id', component: NewVideoComponent},
	{path: 'video-step-two/:project_id', component: VideoStepTwoComponent, canActivate: [NeedAuthGuard]},
	{path: 'video-step-three/:project_id', component: VideoStepThreeComponent, canActivate: [NeedAuthGuard]},
	{path: 'video-final/:project_id', component: VideoFinalComponent, canActivate: [NeedAuthGuard]},
	{path: 'job-list', component: JobListComponent, canActivate: [NeedAuthGuard]},
	{path: 'job-list-view/:project_id', component: JobListViewComponent, canActivate: [NeedAuthGuard]},
	

		
	/* Editor */
	{path: 'editor', component: EditorLoginComponent },
	{path: 'esettings', component: EsettingsComponent , canActivate: [NeedAuthGuardEditor]},
	{path: 'waiting-approval', component: WaitingApproveComponent , canActivate: [NeedAuthGuardEditor]},
	{path: 'approval-edit', component: ApproveEditComponent , canActivate: [NeedAuthGuardEditor]},
	{path: 'approval-view/:assign_id', component: ApproveViewComponent , canActivate: [NeedAuthGuardEditor]},

	{path: 'approval-completed', component: ApproveCompletedComponent , canActivate: [NeedAuthGuardEditor]},
	{path: 'editor-report', component: EditorReportComponent , canActivate: [NeedAuthGuardEditor]},

	
	

	
	

	{path: 'edashboard', component: EdashboardComponent, canActivate: [NeedAuthGuardEditor]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
