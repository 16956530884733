import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FileSaverModule } from 'ngx-filesaver';

/* Authenticate */
import { NeedAuthGuard } from './needauthguard';

import { NeedAuthGuardEditor } from './needauthguardeditor';
/* Service calling */
import { HttpService } from './services/http.service';
import { UserService } from './services/user.service';

import {EncrDecrService} from './services/EncrDecrService.service';

/* Table Name */
import { Tablename } from './app.tablename'; 

/* Form validation */
import { MustMatchDirective } from './helpers/must-match.directive'

/* Notification Message */
import { ToastrModule } from 'ng6-toastr-notifications';

/* Loader */
import { LoaderService } from './loader';

/* Percentage loader */
import { NgCircleProgressModule } from 'ng-circle-progress';

import { S3UploaderModule } from 'ngx-s3-uploader';



//import { FileService } from 'fileservice';

/* Header and footer */
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { FileUploadModule } from 'ng2-file-upload';

import { IndexComponent } from './index/index.component';
import { LoginComponent } from './login/login.component';
import { HowitworksComponent } from './how-it-works/how-it-works.component';
import { VideosamplesComponent } from './video-samples/video-samples.component';
import { PricingComponent } from './pricing/pricing.component';
import { OurprocessComponent } from './our-process/our-process.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';
import { SupportComponent } from './support/support.component';

import { TermComponent } from './term/term.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ActivateMemberComponent } from './activate-member/activate.component';

/* Member */
import { MdashboardComponent } from './mdashboard/mdashboard.component';
import { MsettingsComponent } from './msettings/msettings.component';
import { ProjectComponent } from './new-project/new-project.component';
import { StepTwoComponent } from './new-project/step-two.component';
import { StepThreeComponent } from './new-project/step-three.component';
import { StepFourComponent } from './new-project/step-four.component';
import { StepFiveComponent } from './new-project/step-five.component';
import { PaymentComponent } from './new-project/payment.component';
import { MapproveComponent } from './mdashboard/mapprove.component';
import { MapproveViewComponent } from './mdashboard/mapprove-view.component';
import { McompletedComponent } from './mdashboard/mcompleted.component';
import { MforgotComponent } from './mdashboard/mforgot.component';


/* Editor Account */
import { EditorLoginComponent } from './editor-login/editor-login.component';
import { EdashboardComponent } from './editor-login/edashboard.component';
import { EsettingsComponent } from './editor-login/esettings.component';
import { WaitingApproveComponent } from './editor-login/waiting-approve.component';
import { ApproveEditComponent } from './editor-login/approve-edit.component';
import { ApproveViewComponent } from './editor-login/approve-view.component';
import { ApproveCompletedComponent } from './editor-login/approve-completed.component';
import { EditorReportComponent } from './editor-login/editor-report.component';

import { NgxStripeModule } from 'ngx-stripe';

/* For loading  */
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION , NgxUiLoaderRouterModule} from 'ngx-ui-loader';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
 
/* For audio */

import { VideoHours } from './video-hours.pipe';

/* Session expiry  */
import { UserIdleModule } from 'angular-user-idle';
import { DisablepasteDirective } from './disablepaste.directive';
import { NewVideoComponent } from './new-video/new-video.component';
import { VideoStepTwoComponent } from './new-video/video-step-two.component';
import { VideoStepThreeComponent } from './new-video/video-step-three.component';
import { VideoFinalComponent } from './new-video/video-final.component';
import { JobListComponent } from './job-list/job-list.component';
import { JobListViewComponent } from './job-list/job-list-view.component';



const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IndexComponent,
    LoginComponent,
    HowitworksComponent,
    VideosamplesComponent,
    PricingComponent,
    OurprocessComponent,
    FaqComponent,
    ContactComponent,
    SupportComponent,
    TermComponent,
    PrivacyComponent,
    MustMatchDirective,
    ActivateMemberComponent,
    MdashboardComponent,
    MsettingsComponent,
    ProjectComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    StepFiveComponent,
    PaymentComponent,
    EditorLoginComponent,
    EsettingsComponent,
    EdashboardComponent,
    WaitingApproveComponent,
    ApproveEditComponent,
    ApproveViewComponent,
    ApproveCompletedComponent,
    MapproveComponent,
    MapproveViewComponent,
    McompletedComponent,
    MforgotComponent,
    EditorReportComponent,
    PageNotFoundComponent,
    VideoHours,
    DisablepasteDirective,
    NewVideoComponent,
    VideoStepTwoComponent,
    VideoStepThreeComponent,
    VideoFinalComponent,
    JobListComponent,
    JobListViewComponent,
  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NgxStripeModule.forRoot('pk_test_cihmktI9a8UoLY3L0uPzJrcx'),
    NgCircleProgressModule.forRoot({
      // Percentage loader
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),

    S3UploaderModule.forRoot({
      region: 'ap-southeast-1',
      bucket: 'editube',
      credentials: { accessKeyId: '', secretAccessKey: '' },
    }),
    FileSaverModule,
    UserIdleModule.forRoot({idle: 1800, timeout: 10, ping: 120})
    
	//{idle: 1800, timeout: 10, ping: 120}
    
  ],
  providers: [
  	NeedAuthGuard,
    NeedAuthGuardEditor,
    HttpService,
    Tablename,
    UserService,
    LoaderService,
    EncrDecrService,
    
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
