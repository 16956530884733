import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class StepThreeComponent implements OnInit {
  records = '';
  errorMsg = '';
  query = '';
  table = '';
  title = '';
  isError = '';
  project_id = '';
  member_id = '';
  no_images = '';
  gallery = '';
  server_url = '';
  load_process = '';
  gallery_table = '';
  primary_id = '';
  project = {
    project_id:'',
    project_title:'',
    project_style:'',
    project_speed:'',
    project_music_selection:'',
    project_music_file:'',
    project_video_duration:'',
    project_opening_title:'',
    
    project_closing_title:'',
    project_other_text:'',
    project_opening_title_option:'',
    project_closing_title_option:'',
    project_other_text_option:'',
    project_additional:'',
  };
  data_id = '';
  style_records = '';
  speed_records = '';
  music_records = '';
  music_category = '';
  editube_music = '';
  editube_music_name = '';
  editube_music_file = ''; 
  existing_music = '';
  current_record = '';

  step1_url = '';
  step2_url = '';
  step3_url = '';
  step4_url = '';

  minutes = [3,4,5,6,7,8,9,10];
  check_image_exist ='';

  constructor(private router: Router,public httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private route: ActivatedRoute,public userService:UserService,private fb: FormBuilder,private ngxService: NgxUiLoaderService) { }

  /* Form submit */
  stepthreeSubmit(){

    var op_t = (this.project.project_opening_title).length;
    var op_c = (this.project.project_closing_title).length;
    var other_text = (this.project.project_other_text).length;
    var additional = (this.project.project_additional).length;

    //alert('OP TITLE'+op_t);

    if(this.project.project_video_duration=='')
      this.userService.showError('Select video duration');
    
    else if(this.project.project_opening_title_option=='')
      this.userService.showError('Select opening title');
    
    else if(this.project.project_opening_title_option=='1' && this.project.project_opening_title=='' )
      this.userService.showError('Enter opening title');

    else if(this.project.project_opening_title_option=='1' && this.project.project_opening_title!='' && op_t<2 )
      this.userService.showError('Opening title minimum 2 to maximum 50 characters allowed');

    else if(this.project.project_opening_title_option=='1' && op_t>50 )
      this.userService.showError('Opening title minimum 2 to maximum 50 characters allowed');
    
    else if(this.project.project_closing_title_option=='')
      this.userService.showError('Select closing title');  
    
    else if(this.project.project_closing_title_option=='1' && this.project.project_closing_title=='' )
      this.userService.showError('Enter closing title'); 

    else if(this.project.project_closing_title_option=='1' && this.project.project_closing_title!='' && op_c<2 )
      this.userService.showError('Closing title minimum 2 to maximum 50 characters allowed');

    else if(this.project.project_closing_title_option=='1' && this.project.project_closing_title!='' && op_c>50 )
      this.userService.showError('Closing title minimum 2 to maximum 50 characters allowed');   

    else if(this.project.project_other_text_option=='')
      this.userService.showError('Select text option');  
    
    else if(this.project.project_other_text_option=="1" && this.project.project_other_text=='' )
      this.userService.showError('Enter text option'); 

    else if(this.project.project_other_text_option=="1" && this.project.project_other_text!='' && other_text<2 )
      this.userService.showError('Other text minimum 2 to maximum 150 characters allowed');

    else if(this.project.project_other_text_option=="1" && this.project.project_other_text!='' && other_text>150 )
      this.userService.showError('Other text minimum 2 to maximum 150 characters allowed'); 

    else if(this.project.project_other_text!='' && additional<2 )
      this.userService.showError('Additional text minimum 2 to maximum 250 characters allowed'); 

    else if(this.project.project_other_text!='' && additional>250 )
      this.userService.showError('Additional text minimum 2 to maximum 250 characters allowed'); 

    else{
      //console.log('FORM INSIDE');
      this.ngxService.start(); 
      this.httpService.stepThreeUpdate(this).subscribe((resp: any) => {
        //console.log(resp);
        this.ngxService.stop(); 
        if(resp.result!='' ){
          //this.userService.showSuccess('Step 3 completed successfully');
          this.router.navigate(['/step-four/'+this.project_id]);
        }else if(resp.result=='error')
          this.userService.showError(' file not received');
      }, (errorResponse: HttpErrorResponse) => {
      this.ngxService.stop(); 
          this.isError = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
          this.userService.showError(this.isError) ;
      });
    }
  }

  stepOneCheck(){
    this.ngxService.start(); 
    this.httpService.stepOneCheck(this)
      .subscribe((resp: any) => {
      this.ngxService.stop(); 
      //console.log('PROJECT');
      //console.log(resp);
      if(resp.result.result1==''){
        window.location.href='/#/index';
      }

      /* 
      *   Checking condition for redirect step one page if it is null
      */
      if(resp.result.result1[0].project_title==''){
        this.userService.showError('Enter project title');
        this.router.navigate(['/new-project/'+this.project_id]);
      }
      else if(resp.result.result2[0].tot=='' || resp.result.result2[0].tot=='0' ){
        this.userService.showError('Upload your image or video files');
        this.router.navigate(['/new-project/'+this.project_id]);
      }else if(resp.result.result1[0].project_style=='' || resp.result.result1[0].project_style=='0'){
         this.userService.showError('Select style');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_space_speed=='' || resp.result.result1[0].project_space_speed=='0'){
         this.userService.showError('Select space/speed');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_music_selection=='' || resp.result.result1[0].project_music_selection=='0'){
         this.userService.showError('Select music ');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_music_selection!='' && resp.result.result1[0].project_music_selection=='2' && (resp.result.result3[0].audio_count=='' || resp.result.result3[0].audio_count=='0') ){
        this.userService.showError('Please upload your music ');
        this.router.navigate(['/step-two/'+this.project_id]);
      }else{
        //this.current_record = resp.result.result1[0];
        this.project.project_id = resp.result.result1[0].project_id;

        this.project.project_video_duration = resp.result.result1[0].project_video_duration;

        this.project.project_opening_title_option = resp.result.result1[0].project_opening_title_option;

        this.project.project_opening_title = resp.result.result1[0].project_opening_title;

        this.project.project_closing_title_option = resp.result.result1[0].project_closing_title_option;

        this.project.project_closing_title = resp.result.result1[0].project_closing_title;

        this.project.project_other_text_option = resp.result.result1[0].project_other_text_option;

        this.project.project_other_text = resp.result.result1[0].project_other_text;

        this.project.project_additional = resp.result.result1[0].project_additional;


      }
    }, (errorResponse: HttpErrorResponse) => {
    this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  ngOnInit() { 
    this.existing_music = '';
    this.primary_id  = 'gallery_id';
    this.gallery_table = this.tablenames.project_gallery
    this.load_process = '';
    this.server_url = this.tablenames.server_url;
    
    this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });

    this.httpService.paymentDoneAlready(this);
    
    this.step1_url = '/#/new-project/'+this.project_id;
    this.step2_url = '/#/step-two/'+this.project_id;
    this.step3_url = '/#/step-three/'+this.project_id;
    this.step4_url = '/#/step-four/'+this.project_id;
    
    this.table = this.tablenames.project;
    this.member_id = localStorage.getItem('member_id');
    
    /* MEMBER CHECK */
    this.httpService.checkUserExists(this.member_id).subscribe((resp: any) => {
      //console.log('CHECK EXISTSSSSSSSSSSSS');
      if(resp.result!=''  && typeof resp.result[0]['member_id']!='undefined'){
        //console.log('INSIDE');
        ////console.log(resp);
      }else{
        //console.log('LOGOUT PLZ');
        this.httpService.logoutUser();
        window.location.href='/#/index';
      }
    }, (errorResponse: HttpErrorResponse) => {
          
          let a = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(a) ;
    });
    
    this.stepOneCheck();

    
  }
}
