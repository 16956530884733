import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class StepFourComponent implements OnInit {
  records = '';
  errorMsg = '';
  query = '';
  table = '';
  title = '';
  isError = '';
  project_id = '';
  member_id = '';
  no_images = '';
  gallery = '';
  server_url = '';
  load_process = '';
  gallery_table = '';
  primary_id = '';
  project = {
    project_id:'',
    project_title:'',
    project_style:'',
    project_speed:'',
    project_style_name:'',
    project_speed_name:'',
    project_music_selection:'',
    project_music_file:'',
    project_video_duration:'',
    project_opening_title:'',
    
    project_closing_title:'',
    project_other_text:'',
    project_opening_title_option:'',
    project_closing_title_option:'',
    project_other_text_option:'',
    project_additional:'',
  };
  music_selection ='';
  data_id = '';
  style_records = '';
  speed_records = '';
  music_records = '';
  music_category = '';
  editube_music = '';
  editube_music_name = '';
  editube_music_file = ''; 
  existing_music = '';
  current_record = '';
  step1_url ='';
  step2_url ='';
  step3_url ='';
  step5_url = '';
  minutes = [1,2,3,4,5,6,7,8,9,10];
  check_image_exist ='';
  total_video  = '';
  total_image  = '';
  choosen_editube_music = '';
  gallery_audio = '';
  project_id_decrypt = '';
  gallery_all = '';
  total_min = 0;
  total_size = 0;
  current_min = 0;
  current_size = 0;
  total_video_min = '';
  s3url = '';
  constructor(private router: Router,public httpService: HttpService,private tablenames : Tablename,private httpClient: HttpClient,private route: ActivatedRoute,private userService:UserService,private fb: FormBuilder,private ngxService: NgxUiLoaderService) { }

  
  stepOneCheck(){
  this.ngxService.start(); 
    this.httpService.stepOneCheck(this)
      .subscribe((resp: any) => {

      if(resp.result.result1==''){
        window.location.href='/#/index';
      }
      this.ngxService.stop(); 
      /* 
      *   Checking condition for redirect step one page if it is null
      */
      if(resp.result.result1[0].project_title==''){
        this.userService.showError('Enter project title');
        this.router.navigate(['/new-project/'+this.project_id]);
      }
      else if(resp.result.result2[0].tot=='' || resp.result.result2[0].tot=='0' ){
        this.userService.showError('Upload your image or video files');
        this.router.navigate(['/new-project/'+this.project_id]);
      }else if(resp.result.result1[0].project_style=='' || resp.result.result1[0].project_style=='0'){
         this.userService.showError('Select style');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_space_speed=='' || resp.result.result1[0].project_space_speed=='0'){
         this.userService.showError('Select space/speed');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_music_selection=='' || resp.result.result1[0].project_music_selection=='0'){
         this.userService.showError('Select music ');
          this.router.navigate(['/step-two/'+this.project_id]);
      }else if(resp.result.result1[0].project_music_selection!='' && resp.result.result1[0].project_music_selection=='2' && (resp.result.result3[0].audio_count=='' || resp.result.result3[0].audio_count=='0') ){
        this.userService.showError('Please upload your music ');
        this.router.navigate(['/step-two/'+this.project_id]);
      }
      else if(resp.result.result1[0].project_video_duration=='' || resp.result.project_video_duration=='0' ){
        this.userService.showError('Select video duration');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.result1[0].project_opening_title_option==''){
        this.userService.showError('Select opening title');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.result1[0].project_opening_title_option=='1' && resp.result.result1[0].project_opening_title=='' ){
        this.userService.showError('Enter opening title');
        this.router.navigate(['/step-three/'+this.project_id]);
      }
      else if(resp.result.result1[0].project_closing_title_option==''){
        this.userService.showError('Select closing title');  
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.result1[0].project_closing_title_option=='1' && resp.result.result1[0].project_closing_title=='' ){
        this.userService.showError('Enter closing title');    
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.result1[0].project_other_text_option==''){
        this.userService.showError('Select text option'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      else if(resp.result.result1[0].project_other_text_option=="1" && resp.result.result1[0].project_other_text=='' ){
        this.userService.showError('Enter text option'); 
        this.router.navigate(['/step-three/'+this.project_id]); 
      }
      
      else{
        this.userService.showSuccess('Step 3 completed successfully');

        //this.current_record = resp.result.result1[0];
        this.project.project_id = resp.result.result1[0].project_id;
        this.project.project_title = resp.result.result1[0].project_title;
        this.project.project_style = resp.result.result1[0].project_style;

        this.project.project_speed_name = resp.result.result1[0].speed_name;
        this.project.project_style_name = resp.result.result1[0].style_name;


        this.project.project_video_duration = resp.result.result1[0].project_video_duration;
        this.project.project_opening_title = resp.result.result1[0].project_opening_title_option==1?resp.result.result1[0].project_opening_title:'No Thanks';

        this.project.project_other_text_option = resp.result.result1[0].project_other_text_option==1?resp.result.result1[0].project_other_text:'No Thanks';

        this.project.project_closing_title = resp.result.result1[0].project_closing_title_option==1?resp.result.result1[0].project_closing_title:'No Thanks';

        this.project.project_other_text = resp.result.result1[0].project_other_text_option==1?resp.result.result1[0].project_other_text:'No Thanks';


        //resp.result.result1[0].project_other_text;

        this.project.project_additional = resp.result.result1[0].project_additional;

        this.project.project_music_selection = resp.result.result1[0].project_music_selection;

        if(this.project.project_music_selection=='1')
          this.music_selection = 'Choose from EdiTube music';
        else if(this.project.project_music_selection=='2')
          this.music_selection = 'Using own Music';
        else if(this.project.project_music_selection=='3')
          this.music_selection = 'EdiTube chooses music';
        else if(this.project.project_music_selection=='4')
          this.music_selection = 'No music required';


        this.project.project_music_file = resp.result.result1[0].project_music_file;
      }
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  stepFourRecords(this){
  this.ngxService.start(); 
    this.httpService.stepFourRecords(this).subscribe((resp: any) => {
      //console.log(resp);
      this.ngxService.stop(); 
      if(resp.result!='' ){
        this.userService.showSuccess('Forms submitted successfully');
        this.router.navigate(['/step-four/'+this.project_id]);
      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  videoImage(this){
  this.ngxService.start(); 
    this.httpService.videoImage(this).subscribe((resp: any) => {
      //console.log(resp);
      this.ngxService.stop(); 
      if(resp.result!='' ){
        this.total_video = resp.result.total_video.length;
        this.total_image = resp.result.total_image.length;
        this.gallery_audio = resp.result.total_audio;
        this.gallery_all = resp.result.all_records;

        for (let j = 0; j < this.gallery_all.length; j++) {
        
          if(this.gallery_all[j].gallery_video_min!='' && this.gallery_all[j].gallery_video_min!=0)
            
            if(this.gallery_all[j].gallery_video_min!='' && this.gallery_all[j].gallery_video_min!=0)
            
            //alert(this.gallery_all[j].gallery_video_min);
            this.current_min =  Number(this.current_min) + Number(this.gallery_all[j].gallery_video_min);

            this.current_size = (this.current_size) +  (this.gallery_all[j].gallery_file_size);
        }

        if(this.total_min!='')
          this.total_min = this.current_min/60;

        if(this.current_size!='')
          this.total_size = (this.current_size/(this.tablenames.mb_size * this.tablenames.mb_size));
        
        if(this.current_min!=''){
          
          this.total_video_min = this.httpService.secondsToTime(this.current_min);
        }
      
      }else if(resp.result=='error')
        this.userService.showError(' file not received');
    }, (errorResponse: HttpErrorResponse) => {
        this.ngxService.stop(); 
        this.isError = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again'
        this.userService.showError(this.isError) ;
    });
  }

  

  
  ngOnInit() { 
    this.s3url = this.tablenames.s3url;
    this.existing_music = '';
    this.primary_id  = 'gallery_id';
    this.gallery_table = this.tablenames.project_gallery
    this.load_process = '';
    this.server_url = this.tablenames.server_url;
    
    this.route.params.subscribe(params => {
      this.project_id = params['project_id'];
    });
    
    this.httpService.paymentDoneAlready(this);
    
    this.step1_url = '/#/new-project/'+this.project_id;
    this.step2_url = '/#/step-two/'+this.project_id;
    this.step3_url = '/#/step-three/'+this.project_id;
    this.step5_url = '/#/step-five/'+this.project_id;
    this.table = this.tablenames.project;
    this.member_id = localStorage.getItem('member_id');
    
    this.project_id_decrypt = this.httpService.decryptNew(this.project_id);
    this.stepOneCheck();

    /* MEMBER CHECK */
    this.httpService.checkUserExists(this.member_id).subscribe((resp: any) => {
      //console.log('CHECK EXISTSSSSSSSSSSSS');
      if(resp.result!=''  && typeof resp.result[0]['member_id']!='undefined'){
        //console.log('INSIDE');
        ////console.log(resp);
      }else{
        //console.log('LOGOUT PLZ');
        this.httpService.logoutUser();
        window.location.href='/#/index';
      }
    }, (errorResponse: HttpErrorResponse) => {
          
          let a = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again'
            this.userService.showError(a) ;
    });
    

    this.videoImage();

  }
}
