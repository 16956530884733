import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpService} from '../services/http.service';
import {UserService} from '../services/user.service';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Tablename } from '../app.tablename'; 


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
	public loadershow;
	
    isError = '';
	new_video_title = '';
	s = '';
	member_image = '';
	editor_image = '';
	member_table = '';
	editor_table ='';
	records = '';
	server_url = '';
	table ='';
	query='';
	s3url = '';
    constructor( public router: Router,public httpService: HttpService,public tablenames : Tablename,public httpClient: HttpClient,public userService:UserService) {
    }



    /* New project creation */
    createNewProject(){
    	
    	if($.trim(this.new_video_title)!=''){
    		var l = (this.new_video_title).length;

    		if(l<2){
    			this.userService.showError('Video title character minimum 2 and maximum 50 allowed');
    		}else if(l>50){
    			this.userService.showError('Video title character minimum 2 and maximum 50 allowed');
    		}else{

	    		this.httpService.createNewProject(this)
		          .subscribe((resp: any) => {
		            //resp = resp.result;
		            //console.log(resp);
		            $('#tryit-popup').modal('hide');
		            if(resp.result=='' ){
		              this.userService.showError(resp.message);
		            }else if(resp.result!=''){
		            	//insertId
		            	//window.location.href = '/#new-project/'+this.httpService.encryptNew(resp.result.insertId);

		            	this.s = ''+resp.result.insertId+'';
		            	
		            	window.location.href = '/#/new-project/'+this.httpService.encryptNew(this.s);

		            	//this.router.navigate(['/new-project/'+this.httpService.encryptNew(resp.result.insertId)]);
		            	//console.log(resp);
		              	this.userService.showSuccess('Title saved successfully');
		            }
		            
		        }, (errorResponse: HttpErrorResponse) => {
		              this.isError = errorResponse.error ? errorResponse.error.message
		                  : 'Sorry, unable to complete your request, please refresh this page and try again';
		              this.userService.showError(this.isError);
		        });
		    }
    	}else{
    		alert('Enter video title');
    	}
  	}

  	ngOnInit() {
  		this.s3url = this.tablenames.s3url;
       	let type = (localStorage.getItem('type'));
       	this.member_table = this.tablenames.member;
		this.editor_table =this.tablenames.editor;
		this.server_url = this.tablenames.server_url;
       	if(type=='member'){
       		let member_id = (localStorage.getItem('member_id'));
       		
       		this.query = 'SELECT * FROM '+this.member_table+' WHERE 1=1 AND member_id = '+member_id; 
       	}else if(type=='editor'){
       		let editor_id = (localStorage.getItem('editor_id'));
       		this.query = 'SELECT * FROM '+this.editor_table+' WHERE 1=1 AND editor_id = '+editor_id; 
       	}

       	if(type!='' && typeof type!='undefined'){
       		this.httpService.commonFetch(this)
		      .subscribe((resp: any) => {
		      //console.log(resp);
		      this.records = resp.result[0];
		      
		      	if(type=='member'){
		      		this.member_image = resp.result[0].member_image;
		      	}else if(type=='editor'){
		      		this.editor_image = resp.result[0].editor_image;
		      	}

		    }, (errorResponse: HttpErrorResponse) => {
		          
		          let a = errorResponse.error ? errorResponse.error.message
		                : 'Sorry, unable to complete your request, please refresh this page and try again'
		            this.userService.showError(a) ;
		    });
       	}
    }

    

    
}
